import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER, Version } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TcCoreModule, TcApiConfig, TcAppReducers, TcMetaReducers } from '@tc/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { MatButtonModule, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { entityConfig } from './entity-metadata';
import { NgrxDataModule } from 'ngrx-data';
import { F02LoginPageComponent } from './pages/F02-login-page/F02-login-page.component';
import { AccountModule } from './modules/account/account.module';
import { F03HomePageComponent } from './pages/F03-home-page/F03-home-page.component';
import { F29ListDocumentsComponent } from './pages/F29-list-documents/F29-list-documents.component';
import { BASE_PATH } from 'src/generated/api/stockmemoriss/variables';
import { F01CreateAccountComponent } from './pages/F01-create-account/F01-create-account.component';
import { F02ForgetPasswordPageComponent } from './pages/F02-forget-password-page/F02-forget-password-page.component';
import { F02PasswordExpiredPageComponent } from './pages/F02-password-expired-page/F02-password-expired-page.component';
import { F28CGUAcceptComponent } from './pages/F28-CGU-accept/F28-CGU-accept.component';
import { F34PaymentComponent } from './pages/F34-payment/F34-payment.component';
import { AuthConfig, OAuthStorage } from 'angular-oauth2-oidc';
import { ServiceWorkerModule } from '@angular/service-worker';
import { F01EmailVerficationComponent } from './pages/F01-email-verification/F01-email-verification.component';
import { F01ConfirmEmailComponent } from './pages/F01-confirm-email/F01-confirm-email.component';
import { F04ManageAccountComponent } from './pages/F04-manage-account/F04-manage-account.component';
import { F09FileViewerComponent } from './pages/F09-file-viewer/F09-file-viewer.component';
import { F07SimpleSearchComponent } from './pages/F07-simple-search/F07-simple-search.component';
import { FileViewerModule } from './modules/file-viewer/file-viewer.module';
import { F30ContactTeamComponent } from './pages/F30-contact-team/F30-contact-team.component';
import { F30ContactSupportComponent } from './pages/F30-contact-support/F30-contact-support.component';
import { ContactModule } from './modules/contact/contact.module';
import { F05SetHeirAccountComponent } from './pages/F05-set-heir-account/F05-set-heir-account.component';
import { StoreModule, ActionReducer, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { ContentModule } from './modules/content/content.module';
import { CguModule } from './modules/cgu/cgu.module';
import { SharedModule } from './modules/shared/shared.module';
import { F17BoxEmailAddressesComponent } from './pages/F17-box-email-addresses/F17-box-email-addresses.component';
import { clearState } from './modules/core/store/clear-state.reducer';
import { CoachingModule } from './modules/coaching/coaching.module';
import { MasterPageComponent } from './pages/master-page/master-page.component';
import { F32FaqComponent } from './pages/F32-faq/F32-faq.component';
import { FaqModule } from './modules/faq/faq.module';
import { F13MoveFolderOrFileComponent } from './pages/F13-move-folder-or-file/F13-move-folder-or-file.component';
import { UpdateService } from './services/update.service';
import { JournalModule } from './modules/journal/journal.module';
import { F18JournalComponent } from './pages/F18-journal/F18-journal.component';
import { F19JournalModificationsComponent } from './pages/F19-journal-modifications/F19-journal-modifications.component';
import { AppDateAdapter, APP_DATE_FORMATS } from './modules/core/format-datpicker';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ShareInterceptor } from './modules/core/interceptors/share.interceptor';
import { I18NResolver } from './modules/core/resolvers/i18n.resolver';
import { PartnerProvider } from './modules/core/providers/partner-provider';
import { F37ExpiredFilesComponent } from './pages/F37-expired-files/F37-expired-files.component';


import { F39CertificateComponent } from './pages/F39-certificate/F39-certificate.component';
import { VersionService } from './services/version.service';
export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [
      'account',
      'content',
      'journal'
    ],
    rehydrate: true
  })(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [...TcMetaReducers, clearState, localStorageSyncReducer];

export function getApiBasePath(): string {
  const apiBasePath = require('../assets/config.json').API_BASE_PATH;
  return apiBasePath;
}

export const authConfig: AuthConfig = {
  issuer: require('../assets/config.json').AUTH_URL,
  clientId: 'ro.client',
  dummyClientSecret: 'secret',
  scope: 'offline_access',
  // sessionChecksEnabled: true,
  showDebugInformation: true,
  clearHashAfterLogin: false,
  oidc: false
};

const apiConfig: TcApiConfig = {
  toastErrorExceptions: [
    { url: 'account/successions', method: 'GET', status: 404 },
    { url: 'account/validate-restitution-word', method: 'GET', status: 404 },
    { url: 'content/shares', method: 'GET', status: 404 },
    { url: 'content/get-expired-files', method: 'GET', status: 404 }
  ],
  customToastErrors: [
    { url: 'account/restitution-challenge', method: 'GET', status: 404, toastType: 'warning', translateKey: 'restitution-password-not-set' }
  ]
};

// pages
const pages = [
  MasterPageComponent,
  F02LoginPageComponent,
  F03HomePageComponent,
  F29ListDocumentsComponent,
  F01CreateAccountComponent,
  F02ForgetPasswordPageComponent,
  F02PasswordExpiredPageComponent,
  F28CGUAcceptComponent,
  F34PaymentComponent,
  F01EmailVerficationComponent,
  F01ConfirmEmailComponent,
  F04ManageAccountComponent,
  F09FileViewerComponent,
  F07SimpleSearchComponent,
  F30ContactTeamComponent,
  F30ContactSupportComponent,
  F05SetHeirAccountComponent,
  F17BoxEmailAddressesComponent,
  F32FaqComponent,
  F13MoveFolderOrFileComponent,
  F18JournalComponent,
  F19JournalModificationsComponent,
  F19JournalModificationsComponent,
  F39CertificateComponent,
  F37ExpiredFilesComponent
];

// modules
const modules = [
  // modules/account
  AccountModule,
  // mocules/content
  ContentModule,
  // modules/cgu
  CguModule,
  // modules/file-viewer
  FileViewerModule,
  // modules/contact
  ContactModule,
  // modules/shared
  SharedModule,
  // coaching mode
  CoachingModule,
  // faq
  FaqModule,
  JournalModule
];

export function partnerProviderFactory(provider: PartnerProvider) {
  return () => provider.load();
}

@NgModule({
  declarations: [
    ...pages,
    AppComponent
  ],
  imports: [
    ...modules,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TcCoreModule,
    MatButtonModule,

    StoreModule.forRoot(TcAppReducers, { metaReducers }),

    // environment.production
    //   ? []
    //   : StoreDevtoolsModule.instrument({
    //     name: 'MyStockMemoriss'
    //   }),

    StoreDevtoolsModule.instrument(),
    NgrxDataModule.forRoot(entityConfig),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),

  ],
  providers: [
    I18NResolver,
    PartnerProvider,
    { provide: APP_INITIALIZER, useFactory: partnerProviderFactory, deps: [PartnerProvider], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ShareInterceptor, multi: true },
    { provide: BASE_PATH, useValue: getApiBasePath() },
    { provide: TcApiConfig, useValue: apiConfig },
    { provide: AuthConfig, useValue: authConfig },
    { provide: OAuthStorage, useValue: localStorage },
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    UpdateService,
    VersionService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
