import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, flatMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.state';
import { getContentState } from 'src/app/modules/content/store/selectors/share.selectors';
import { Utils } from '../utils';
import {map} from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { RouterService } from './../../../services/router.service';
import {LoadUserSuccess} from './../../account/store/account.actions';
import { TcTranslateService, TcNotificationService, Logout } from '@tc/core';

@Injectable()
export class ShareInterceptor implements HttpInterceptor {
    constructor(private store: Store<AppState>,
        public translateService: TcTranslateService,
        private notificationService: TcNotificationService,
        private routerService: RouterService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        return this.store.select(getContentState).pipe(
            first(),
            flatMap(contentState => {

                let newReq = req;

                if (contentState.sharedId && contentState.sharedPassword) {

                    newReq = req.clone({
                        headers: req.headers.set('X-SHARE-KEY', Utils.getShareKeyAuth(contentState.sharedId, contentState.sharedPassword))
                    });

                }

                // return next.handle(newReq);
                
                return next.handle(newReq).pipe(
                    catchError((error: HttpErrorResponse) => {
                    let errorMsg = '';
                    if(error.status == 403 && error.error == 'password-expired'){  
                        this.store.dispatch(new Logout());
                        this.notificationService.error(this.translateService.instant('account-detail.password-expired'));
                        this.routerService.goToLoginExpiredPage();
                    }
                    if(error.status == 403 && error.error == 'password-not-different'){
                        this.store.dispatch(new Logout());
                        this.notificationService.error(this.translateService.instant('account-detail.password-not-different'));
                        this.routerService.goToLoginExpiredPage();
                    }
                    if(error.status == 403 && error.error == 'wrong-password'){
                        this.store.dispatch(new Logout());
                        this.notificationService.error(this.translateService.instant('account-detail.wrong-password'));
                        this.routerService.goToLoginExpiredPage();
                    }
                    if(error.status == 403 && error.error == 'account_blocked'){
                        this.store.dispatch(new Logout());
                        this.notificationService.error(this.translateService.instant('account-detail.account_blocked'));
                    }
                    errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
                    console.log(errorMsg);
                    return throwError(errorMsg);
                  }));

            }),
        );
    }
}
