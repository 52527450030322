import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RouterService } from './router.service';
import { AbstractFile, FileType, SimpleFile, FilesAndFoldersModel } from 'src/generated/api/stockmemoriss';
import { EditFolder } from '../modules/content/store/actions/folder.actions';
import { EditFile } from '../modules/content/store/actions/file.actions';
import { RestoreOrphans } from '../modules/content/store/actions/content.actions';
import { Location } from '@angular/common';
import { TcService } from '@tc/core';
import { ContentState } from '../modules/content/store/content.state';

@Injectable({
    providedIn: 'root'
})
export class MoveService extends TcService {

    constructor(
        private store: Store<ContentState>,
        private routerService: RouterService,
        private location: Location) {
        super();
    }

    moveEntity() {
        this.routerService.goToMovePage();
    }

    moveEntityDone(entity: AbstractFile) {

        if (entity.type === FileType.Folder) {
            this.store.dispatch(new EditFolder({ folder: entity, type: 'move-folder' }));
        }

        if (entity.type === FileType.File) {
            this.store.dispatch(new EditFile({ file: entity as SimpleFile, type: 'move-file' }));
        }

    }


    moveOrphans() {
        this.routerService.goToMovePage();
    }

    moveOrphansDone(newParentId: number, model: FilesAndFoldersModel) {
        this.store.dispatch(new RestoreOrphans({ newParentId, model }));
    }


    moveCancel() {
        this.location.back();
    }

}
