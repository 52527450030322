import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';
import { Folder, SimpleFile } from 'src/generated/api/stockmemoriss';
import { TcDumbComponent, TcListComponent, TcMenuComponent, TcMenuItem } from '@tc/core';
import { MSMAbstractFilesOnlyList } from 'src/app/modules/core/interfaces/msm-abstract-files-only-list';
import { Utils } from 'src/app/modules/core/utils';

@Component({
  selector: 'msm-navigate-files-only',
  templateUrl: './msm-navigate-files-only.component.html',
  styleUrls: ['./msm-navigate-files-only.component.scss']
})
export class MsmNavigateFilesOnlyComponent extends TcDumbComponent implements MSMAbstractFilesOnlyList, OnInit {

  @Input()
  hasSelectColumn = false;

  @Input()
  menuItems: TcMenuItem[];

  @Input()
  files$: Observable<SimpleFile[]>;

  @Output()
  selectFile: EventEmitter<SimpleFile> = new EventEmitter();

  @Output()
  menuFile: EventEmitter<any> = new EventEmitter();

  constructor() {
    super();
  }

  fileList: TcListComponent;
  @ViewChild('fileList', { static: true }) set appFileList(values: TcListComponent) {
    this.fileList = values;
  }
  fileRowMenu: TcMenuComponent;
  @ViewChild('fileRowMenu', { static: true }) set appFileRowMenu(value: TcMenuComponent) {
    this.fileRowMenu = value;
  }

  @ViewChild('colNameTemplate', { static: true }) colNameTemplate: TemplateRef<any>;

  ngOnInit() {

    window.scrollTo(0, 0);

    this.fileList.rows$ = this.files$;
    this.fileList.hasActionsLabel = false;
    this.fileList.isPaged = false;
    this.fileList.isFiltrable = false;
    this.fileList.columns = [
      {
        propertyName: 'name',
        visible: true,
        filtrable: false,
        htmlTemplate: this.colNameTemplate,
      }
    ];

    this.fileRowMenu.items = this.menuItems;

    this.fileRowMenu.onMenuItemClick = (item: TcMenuItem, row: Folder) => {
      this.menuFile.emit({ item, row });
    };

  }

  onSelectFile(file: SimpleFile) {
    if (file) {
      this.selectFile.emit(file);
    }
  }

  getFileImage(fileName: string): string {
    return '/assets/images/' + Utils.getFileExtension(fileName) + '.svg';
  }

}
