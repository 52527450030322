import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ContentService, Share, Folder, SimpleFile, User } from 'src/generated/api/stockmemoriss';
import { TcNotificationService, TcTranslateService, TcConfigService, TcAuthenticationService, TcService } from '@tc/core';
import { RouterService } from './router.service';
import { MatDialog } from '@angular/material';
import { SharedPasswordDialogComponent } from '../modules/content/components/dumb/shared-password-dialog/shared-password-dialog.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Utils } from '../modules/core/utils';
import { OpenSharedFolder, OpenSharedFile } from '../modules/content/store/actions/share.actions';
import { RouteOptions } from '../modules/core/enums/route-options';
import { Router } from '@angular/router';
import { ContentState } from '../modules/content/store/content.state';

@Injectable({
    providedIn: 'root'
})
export class ShareService extends TcService {

    constructor(
        private notificationService: TcNotificationService,
        private translateService: TcTranslateService,
        private routerService: RouterService,
        private router: Router,
        private contentService: ContentService,
        private store: Store<ContentState>,
        private dialog: MatDialog,
        private httpClient: HttpClient,
        private configService: TcConfigService,
        private authService: TcAuthenticationService) {
        super();
    }


    openSharedFolderOrFile(sharedUrl: string, sharedId: string, currentUser: User) {

        // if user is not authenticated => go to login page with return url = current url
        if (!this.authService.isAuthenticated()) {
            this.routerService.goToLoginPageWithReturnUrl(sharedUrl);
        } else {

            const authUserId = this.authService.getAuthUserId();

            if (currentUser && currentUser.id + '' === authUserId) {

                // if user didn't accept yet CGU validation => go to cgu page with shared url = current url
                if (!currentUser.dateAcceptationCGU) {
                    this.routerService.goToCguPageWithSharedUrl(sharedUrl);
                }
            } else {
                this.routerService.goToLoginPageWithReturnUrl(sharedUrl);
            }

            this.getSharedInfo(sharedId);

        }
    }

    viewSharedFolder(url: string) {
        this.routerService.goToPage(RouteOptions.ListDocuments, { queryParams: { sharedUrl: url } });
    }

    viewSharedFile(file: SimpleFile, url: string) {

        const extension = Utils.getFileExtension(file.name);

        if (Utils.isAllowedFileExtension(extension)) {
            this.routerService.goToPage(RouteOptions.FileViewer, { queryParams: { sharedUrl: url } });
        } else {
            this.notificationService.warning(this.translateService.instant('select-file.restriction-message'));
        }
    }

    getSharedInfo(id: string) {

        const url = this.router.url;

        let hasError = true;

        // TO DO
        this.contentService.getShare(id).subscribe(
            share => {

                hasError = false;

                // sharable link without password
                if (!share.passwordRequired) {

                    if (share.fileId) {
                        // TO DO
                        this.contentService.getFile(share.fileId).subscribe(file => {
                            this.openSharedFile(file, share, url);
                        });
                    }

                    if (share.folderId) {
                        this.openSharedFolder(share, url);
                    }
                } else {
                    this.openPasswordDialog(share, url);
                }

            },
            (error) => {

            },
            () => {
                if (hasError) {
                    this.showErrorAndGoToLogin();
                }
            });
    }

    private showErrorAndGoToLogin() {
        this.notificationService.error(this.translateService.instant('shared.expired-or-error'));
        this.routerService.goToLoginPage();
    }


    private openPasswordDialog(share: Share, url: string) {

        const dialog = this.dialog.open(SharedPasswordDialogComponent, {
            disableClose: true
        });

        dialog.afterClosed().subscribe(result => {

            if (result) {

                if (typeof result === 'string') {
                    if (result === 'cancel') {
                        this.routerService.goToLoginPage();
                    }
                } else {
                    const password = result.password;

                    if (share.fileId) {

                        const getFileUrl = (this.configService.config as any).API_BASE_PATH + '/content/files/' + share.fileId;

                        let hasError = true;

                        this.httpClient.get<SimpleFile>(getFileUrl,
                            {
                                headers: this.setRequestHeaders(share.id, password)
                            }).subscribe(
                                file => {
                                    hasError = false;
                                    this.openSharedFile(file, share, url, password);
                                },
                                (error) => {
                                },
                                () => {
                                    if (hasError) {
                                        this.notificationService.error(this.translateService.instant('shared.wrong-password'));
                                        this.openPasswordDialog(share, url);
                                    }
                                }
                            );
                    }

                    if (share.folderId) {

                        const getFolderUrl = (this.configService.config as any).API_BASE_PATH + '/content/folders/' + share.folderId;

                        let hasError = true;

                        this.httpClient.get<Folder>(getFolderUrl,
                            {
                                headers: this.setRequestHeaders(share.id, password)
                            }).subscribe(
                                res => {
                                    hasError = false;
                                    this.openSharedFolder(share, url, password);
                                },
                                (error) => {
                                },
                                () => {
                                    if (hasError) {
                                        this.notificationService.error(this.translateService.instant('shared.wrong-password'));
                                        this.openPasswordDialog(share, url);
                                    }
                                }
                            );

                    }

                }
            }
        });

    }

    private openSharedFolder(share: Share, sharedUrl: string, sharedPassword?: string) {
        this.store.dispatch(new OpenSharedFolder({ share, sharedUrl, sharedPassword }));
    }

    private openSharedFile(file: SimpleFile, share: Share, sharedUrl: string, sharedPassword?: string) {
        this.store.dispatch(new OpenSharedFile({ file, share, sharedUrl, sharedPassword }));
    }

    private setRequestHeaders(shareId: string, password: string): HttpHeaders {

        let headers: HttpHeaders = new HttpHeaders();

        const token = `Bearer ${this.authService.getAccessToken()}`;
        headers = headers.set('Authorization', token);

        headers = headers.set('X-SHARE-KEY', Utils.getShareKeyAuth(shareId, password));

        return headers;

    }
}
