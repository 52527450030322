import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ContentState } from '../content.state';

export const getContentState = createFeatureSelector<ContentState>(
    'content'
);

export const isSharedMode = createSelector(
    getContentState,
    (state: ContentState) => state.isSharedMode
);

export const getSharedFolderId = createSelector(
    getContentState,
    (state: ContentState) => state.sharedFolderId
);

// export const getSharedAuth = createSelector(
//     getContentState,
//     (state: ContentState) => return {}
// );
