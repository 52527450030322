import { Injectable } from '@angular/core';
import { TcMenuItem, TcNotificationService, TcTranslateService, TcService } from '@tc/core';
import { Box, ContentService } from 'src/generated/api/stockmemoriss';
import { BoxDetailComponent } from '../modules/content/components/smart/box-detail/box-detail.component';
import { MatDialog } from '@angular/material';
import { DeleteBox, ChangeBoxEmailAddress } from '../modules/content/store/actions/box.actions';
import { Store } from '@ngrx/store';
import { SendFolder, ShareFolder } from '../modules/content/store/actions/folder.actions';
import { LoadJournal } from '../modules/journal/store/journal.actions';
import { RouterService } from './router.service';
import { BoxMenuOptions } from '../modules/core/enums/box-menu-options';
import { DeleteAllFoldersAndFiles, DeleteFoldersAndFiles, RestoreSelectedRows } from '../modules/content/store/actions/content.actions';
import { ContentState } from '../modules/content/store/content.state';

@Injectable({
    providedIn: 'root'
})
export class BoxMenuService extends TcService {

    constructor(
        private store: Store<ContentState>,
        private contentService: ContentService,
        private notificationService: TcNotificationService,
        private translateService: TcTranslateService,
        private routerService: RouterService,
        private dialog: MatDialog
    ) {
        super();
    }

    public getMenuItems(isBin: boolean = false): TcMenuItem[] {

        return isBin
            ? [
                {
                    name: BoxMenuOptions.BinDeleteAll,
                },
                {
                    name: BoxMenuOptions.BinDeleteSelected,
                },
                {
                    name: BoxMenuOptions.BinRestore,
                },

            ] : [
                {
                    name: BoxMenuOptions.Edit,
                },
                {
                    name: BoxMenuOptions.RequestDelete,
                },
                {
                    name: BoxMenuOptions.BoxEmail,
                },
                {
                    name: BoxMenuOptions.Send,
                },
                {
                    name: BoxMenuOptions.Share
                },
                {
                    name: BoxMenuOptions.Journal
                }
            ];
    }

    onMenuBox(menuItem: TcMenuItem, box: Box) {

        if (menuItem.name === BoxMenuOptions.Edit) {
            this.dialog.open(BoxDetailComponent, {
                data: box
            });
        }

        if (menuItem.name === BoxMenuOptions.RequestDelete) {
            // TO DO find a better way
            this.contentService.getFolder(box.id).subscribe(folder => {
                if ((folder.files && folder.files.length > 0) || (folder.folders && folder.folders.length > 0)) {
                    this.notificationService.error(this.translateService.instant('box-detail.delete.error'));
                } else {
                    this.store.dispatch(new DeleteBox(box.id));
                }
            });
        }

        if (menuItem.name === BoxMenuOptions.BoxEmail) {
            this.store.dispatch(new ChangeBoxEmailAddress(box));
        }

        if (menuItem.name === BoxMenuOptions.Send) {
            this.store.dispatch(new SendFolder(box.id));
        }

        if (menuItem.name === BoxMenuOptions.Share) {
            this.store.dispatch(new ShareFolder(box.id));
        }

        // box journal
        if (menuItem.name === BoxMenuOptions.Journal) {
            this.store.dispatch(new LoadJournal({ folderId: box.id, fileId: null }));
            this.routerService.goToJournalFolderOrFilePage();
        }

        if (menuItem.name === BoxMenuOptions.BinRestore) {
            this.store.dispatch(new RestoreSelectedRows());
        }

        if (menuItem.name === BoxMenuOptions.BinDeleteAll) {
            this.store.dispatch(new DeleteAllFoldersAndFiles());
        }

        if (menuItem.name === BoxMenuOptions.BinDeleteSelected) {
            this.store.dispatch(new DeleteFoldersAndFiles());
        }

    }
}
