import { Component, OnInit } from '@angular/core';
import { TcSmartComponent } from '@tc/core';
import { RouteOptions } from 'src/app/modules/core/enums/route-options';
import { RouterService } from 'src/app/services/router.service';

@Component({
  selector: 'payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent extends TcSmartComponent implements OnInit {

  constructor(private routerService: RouterService) {
    super();
  }

  ngOnInit() {
  }

  validateAndPay() {
    this.routerService.goToPage(RouteOptions.CGUAccept);
  }

}
