import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ContentService, Box } from 'src/generated/api/stockmemoriss';
import { Observable } from 'rxjs';
import { TcAction, TcNotificationService, TcTranslateService } from '@tc/core';
import { mergeMap, map } from 'rxjs/operators';
import {
    LoadBoxes, BoxActionTypes, LoadBoxesSuccess, AddBox, AddBoxSuccess, DeleteBoxSuccess, DeleteBox,
    EditBox, EditBoxSuccess, SelectBox, ChangeBoxEmailAddress
} from '../actions/box.actions';
import { MSMContentService } from 'src/app/services/msm-content.service';

@Injectable()
export class BoxEffects {

    constructor(
        private actions$: Actions,
        private contentService: ContentService,
        private msmContentService: MSMContentService
    ) { }

    @Effect()
    loadBoxes$: Observable<TcAction> = this.actions$.pipe(
        ofType<LoadBoxes>(
            BoxActionTypes.LOAD_BOXES
        ),
        mergeMap((action: LoadBoxes) =>
            this.contentService.listBoxes().pipe(
                map(
                    (items: Box[]) =>
                        new LoadBoxesSuccess(items)
                )
            )
        )
    );

    @Effect()
    addBox$: Observable<TcAction> = this.actions$.pipe(
        ofType<AddBox>(
            BoxActionTypes.ADD_BOX
        ),
        mergeMap((action: AddBox) =>

            this.contentService.postBox(action.payload).pipe(
                map(
                    (box: Box) =>
                        new AddBoxSuccess(box)
                )
            )
        )
    );

    @Effect({ dispatch: false })
    addBoxSuccess = this.actions$.pipe(
        ofType<AddBoxSuccess>(BoxActionTypes.ADD_BOX_SUCCESS),
        map(() => {
            this.msmContentService.addBoxSuccess();
        })
    );

    @Effect({ dispatch: false })
    deleteBoxSuccess = this.actions$.pipe(
        ofType<DeleteBoxSuccess>(BoxActionTypes.DELETE_BOX_SUCCESS),
        map(() => {
            this.msmContentService.deleteBoxSuccess();
        })
    );

    @Effect()
    deleteBox$: Observable<TcAction> = this.actions$.pipe(
        ofType<DeleteBox>(
            BoxActionTypes.DELETE_BOX
        ),
        mergeMap((action: DeleteBox) =>
            this.contentService.deleteBox(action.payload).pipe(
                map(
                    () =>
                        new DeleteBoxSuccess(action.payload)
                )
            )
        )
    );


    @Effect()
    editBox$: Observable<TcAction> = this.actions$.pipe(
        ofType<EditBox>(
            BoxActionTypes.EDIT_BOX
        ),
        mergeMap((action: EditBox) =>
            this.contentService.putBox(action.payload.id, action.payload).pipe(
                map(
                    (box: Box) =>
                        new EditBoxSuccess(box)
                )
            )
        )
    );

    @Effect({ dispatch: false })
    editBoxSuccess = this.actions$.pipe(
        ofType<EditBoxSuccess>(BoxActionTypes.EDIT_BOX_SUCCESS),
        map(() => {
            this.msmContentService.editBoxSuccess();
        })
    );

    @Effect({ dispatch: false })
    selectBox = this.actions$.pipe(
        ofType<SelectBox>(BoxActionTypes.SELECT_BOX),
        map(() => {
            this.msmContentService.selectBox();
        })
    );

    @Effect({ dispatch: false })
    changeBoxEmailAddress = this.actions$.pipe(
        ofType<ChangeBoxEmailAddress>(BoxActionTypes.CHANGE_BOX_EMAIL_ADDRESS),
        map(() => {
            this.msmContentService.changeBoxEmailAddress();
        })
    );


}
