import { Component, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { MatInput } from '@angular/material';

@Component({
  selector: 'msm-datepicker',
  templateUrl: './datepicker.component.html',
})
// todo: move this to @tc/core
export class DatepickerComponent extends FieldType {
  @ViewChild(MatInput, { static: true }) formFieldControl: MatInput;
}
