import { createSelector, createFeatureSelector } from '@ngrx/store';
import { ContentState } from '../content.state';

const getContentState = createFeatureSelector<ContentState>(
    'content'
);
export const getBoxes = createSelector(
    getContentState,
    (state: ContentState) => {
        return state.boxes.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
    }
);

export const getBoxesExceptBin = createSelector(
    getContentState,
    (state: ContentState) => {
        return state.boxes.filter(b => !b.isBin).sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
    }
);

export const getSelectedBox = createSelector(
    getContentState,
    (state: ContentState) => state.selectedBox
);

export const getSingleDisplay = createSelector(
    getContentState,
    (state: ContentState) => state.singleDisplay
);

export const getBinBoxId = createSelector(
    getContentState,
    (state: ContentState) => {
        const binBox = state.boxes.find(b => b.isBin);
        return binBox ? binBox.id : null;
    }
);

