import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { TcListComponent, TcDumbComponent, TcMenuItem } from '@tc/core';
import { TemplateRef } from '@angular/core';
import { Box } from 'src/generated/api/stockmemoriss';
import { Observable } from 'rxjs';


@Component({
  selector: 'msm-box-list',
  templateUrl: './msm-box-list.component.html',
  styleUrls: ['./msm-box-list.component.scss']
})
export class MsmBoxListComponent extends TcDumbComponent implements OnInit {

  @Input()
  boxes$: Observable<Box[]>;

  @Input()
  menuItems: TcMenuItem[] = [];

  @Input()
  hasMenu = true;

  @Output()
  selectBox: EventEmitter<Box> = new EventEmitter();

  @Output()
  menuBox: EventEmitter<any> = new EventEmitter();

  constructor() {
    super();
  }

  boxList: TcListComponent;
  @ViewChild('boxList', { static: true }) set appGenericList1(values: TcListComponent) {
    this.boxList = values;
  }

  @ViewChild('colNameTemplate', { static: true }) colNameTemplate: TemplateRef<any>;


  ngOnInit() {

    this.boxList.rows$ = this.boxes$;

    this.boxList.hasActionsLabel = false;
    this.boxList.isPaged = false;
    this.boxList.isFiltrable = false;

    this.boxList.columns = [
      {
        propertyName: 'name',
        visible: true,
        filtrable: false,
        htmlTemplate: this.colNameTemplate
      },
    ];
  }

  onItemClick(box: Box) {
    this.selectBox.emit(box);
  }

  onMenuBox(event: any) {
    this.menuBox.emit({ menuItem: event.item, box: event.row });
  }
}
