import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Observable, Subscribable, Subscription } from 'rxjs';
import { AccountState } from '../../../../account/store/account.reducer';
import { Store, select } from '@ngrx/store';
import { User, CoachingService } from 'src/generated/api/stockmemoriss';
import { getAccountUser } from '../../../../account/store/account.selectors';
import { CoachingModalComponent } from '../../dumb/coaching-modal/coaching-modal.component';
import { TcSmartComponent } from '@tc/core';
import { VersionService } from 'src/app/services/version.service';

@Component({
  selector: 'coaching-icon',
  templateUrl: './coaching-icon.component.html',
  styleUrls: ['./coaching-icon.component.scss']
})
export class CoachingIconComponent extends TcSmartComponent implements OnInit, OnDestroy {

  currentUser$: Observable<User>;
  text = '';
  coachingSubscription: Subscription;

  @Input() key: string;

  constructor(
    private store: Store<AccountState>,
    private coachingService: CoachingService,
    private dialog: MatDialog,
    private versionService: VersionService) {
    super();
  }

  ngOnInit() {
    this.currentUser$ = this.store.pipe(select(getAccountUser));
    this.text = '';
    if (this.key !== '') {
      console.log('coachingKey: ' + this.key);
      // TO DO move service call from here
      this.coachingSubscription = this.coachingService.getCoaching(this.key).subscribe(result => {
        this.text = result.text;
        if (this.key === 'f05-set-heir-account') {
          this.text += ' - v' + this.versionService.getVersion();
        }
      });
    }
  }

  openCoachingModal() {
    if (this.text && this.text !== '') {
      this.dialog.open(CoachingModalComponent,
        {
          position: { left: '3%', bottom: '5%' },
          panelClass: 'coaching-modal',
          data: this.text
        });
    }
  }

  ngOnDestroy(): void {
    if (this.coachingSubscription) {
      this.coachingSubscription.unsubscribe();
    }
  }

}
