import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { TcFormComponent, TcTranslateService, TcNotificationService } from '@tc/core';
import { Store, select } from '@ngrx/store';
import { User } from 'src/generated/api/stockmemoriss';
import { AddUser } from 'src/app/modules/account/store/account.actions';
import { AccountState } from '../../../store/account.reducer';
import { Utils } from 'src/app/modules/core/utils';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { getAccountUser } from '../../../store/account.selectors';

@Component({
  selector: 'sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent extends TcFormComponent<User> implements OnInit, OnDestroy {

  sharedUrl: string;

  currentUserSubscription: Subscription;

  constructor(
    private router: Router,
    private store: Store<AccountState>,
    private notificationService: TcNotificationService,
    private route: ActivatedRoute,
    translate: TcTranslateService,
    element: ElementRef) {

    super(translate, element);

    this.fields = [
      {
        key: 'civility',
        type: 'radio',
        templateOptions: {
          options: [
            {
              value: 'M',
              key: 'M.'
            },
            {
              value: 'Mme',
              key: 'Mme'
            },
          ],
          required: true
        },
      },
      {
        key: 'lastName',
        type: 'input',
        templateOptions: {
          type: 'text',
          required: true
        },
      },
      {
        key: 'firstName',
        type: 'input',
        templateOptions: {
          type: 'text',
          required: true
        },
      },
      {
        key: 'username',
        type: 'input',
        templateOptions: {
          type: 'email',
          required: true
        },
      },
      {
        key: 'password',
        type: 'input',
        templateOptions: {
          type: 'password',
          required: true
        }
      },
      {
        key: 'confirmPassword',
        type: 'input',
        templateOptions: {
          type: 'password',
          required: true,
        },
        validators: {
          fieldMatch: {
            expression: (control) => control.value === this.model.password,
            message: this.translate.instant('sign-up.validations.confirmPasswordMessage')
          },
        },
      }
    ];
  }
  ngOnInit() {

    super.ngOnInit();

    const sharedUrl = this.route.snapshot.queryParams.sharedUrl;

    if (sharedUrl) {
      this.sharedUrl = sharedUrl;
    }

    this.currentUserSubscription = this.store.pipe(select(getAccountUser)).subscribe(user => {
      if (user) {
        this.model.civility = user.civility;
        this.model.firstName = user.firstName;
        this.model.lastName = user.lastName;
        this.model.username = user.username;
      }
    });

  }

  ngOnDestroy() {
    if (this.currentUserSubscription) {
      this.currentUserSubscription.unsubscribe();
    }
  }

  submit(model: User) {
    if (this.form.valid && this.validatePassword(model.password) && this.validateEmail(model.username)) {

      model.coachingMode = User.CoachingModeEnum.Debutant;

      // if sharedUrl exists => lecteur user, else: normal user
      model.role = this.sharedUrl ? User.RoleEnum.Lecteur : User.RoleEnum.Normal;

      delete (model as any).confirmPassword;

      model.emailConfirmation = false;

      this.store.dispatch(new AddUser({ user: model, sharedUrl: this.sharedUrl }));
    }
  }

  validatePassword(password: string): boolean {
    if (!Utils.isValidPassword(password)) {
      this.notificationService.error(this.translate.instant('account-detail.password-format-error'));
      return false;
    }
    return true;
  }

  validateEmail(email: string): boolean {
    if (!Utils.isValidEmail(email)) {
      this.notificationService.error(this.translate.instant('account-detail.email-format-error'));
      return false;
    }
    return true;
  }


}
