import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TcCoreModule } from '@tc/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { faqReducer } from './store/faq.reducer';
import { FaqEffects } from './store/faq.effects';
import { FAQComponent } from './components/smart/faq/faq.component';

const components = [
  FAQComponent
];


@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    TcCoreModule,
    StoreModule.forFeature('faq', faqReducer),
    EffectsModule.forFeature([FaqEffects]),
  ],
  exports: [
    ...components
  ],

})
export class FaqModule { }
