/**
 * StockMemoriss API
 * StockMemoriss API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface User { 
    id: number;
    /**
     * username = email
     */
    username: string;
    password?: string;
    firstName: string;
    lastName: string;
    role: User.RoleEnum;
    civility?: User.CivilityEnum;
    language?: string;
    coachingMode: User.CoachingModeEnum;
    dateAcceptationCGU?: string;
    emailConfirmation: boolean;
    /**
     * Minimum 8 words separated by space, words of minumum 5 letters
     */
    passwordRestitution?: string;
    passwordRestitutionUse?: boolean;
    msmPaiement?: boolean;
    defaultBoxId?: number;
    telephone?: string;
    address?: string;
}
export namespace User {
    export type RoleEnum = 'normal' | 'lecteur';
    export const RoleEnum = {
        Normal: 'normal' as RoleEnum,
        Lecteur: 'lecteur' as RoleEnum
    };
    export type CivilityEnum = 'M.' | 'Mme';
    export const CivilityEnum = {
        M: 'M.' as CivilityEnum,
        Mme: 'Mme' as CivilityEnum
    };
    export type CoachingModeEnum = 'expert' | 'debutant' | 'habitue';
    export const CoachingModeEnum = {
        Expert: 'expert' as CoachingModeEnum,
        Debutant: 'debutant' as CoachingModeEnum,
        Habitue: 'habitue' as CoachingModeEnum
    };
}

