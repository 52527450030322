import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { SupportConfirmationComponent } from '../modules/contact/components/dumb/support-confirmation/support-confirmation.component';
import { TcService } from '@tc/core';

@Injectable({
    providedIn: 'root'
})
export class MSMContactService extends TcService {

    constructor(
        public dialog: MatDialog,
    ) {
        super();
    }

    showModalAfterSendEmailWithSuccess() {
        this.dialog.open(SupportConfirmationComponent, {});
    }
}
