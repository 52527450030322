import { Injectable } from '@angular/core';
import { RouterService } from './router.service';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material';
import { ConfirmCertificateDialogComponent } from '../modules/content/components/dumb/confirm-certificate-dialog/confirm-certificate-dialog.component';
import { TcService } from '@tc/core';

@Injectable({
    providedIn: 'root'
})
export class CertificateService extends TcService {
    constructor(
        private dialog: MatDialog,
        private routerService: RouterService,
        private location: Location
    ) {
        super();
    }

    selectFilesForCertificate() {
        this.routerService.goToSelectFilesForCertificatePage();
    }

    selectFilesForCertificateCancel() {
        this.location.back();
    }

    selectFilesForCertificateDone() {
        this.location.back();
    }

    payCertificate() {
        this.routerService.goToCertificatePaymentPage();
    }

    generateCertificateSucces() {
        this.dialog.open(ConfirmCertificateDialogComponent);
        this.routerService.goToHomePage();
    }
}
