import { Component, OnInit } from '@angular/core';
import { TcPage } from '@tc/core';

@Component({
  selector: 'F01-create-account',
  templateUrl: './F01-create-account.component.html',
  styleUrls: ['./F01-create-account.component.scss']
})
export class F01CreateAccountComponent extends TcPage implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
  }

}
