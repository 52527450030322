import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoachingIconComponent } from './components/smart/coaching-icon/coaching-icon.component';
import { MatTooltipModule } from '@angular/material';
import { TcCoreModule } from '@tc/core';
import { CoachingModalComponent } from './components/dumb/coaching-modal/coaching-modal.component';

const components = [
  CoachingIconComponent,
  CoachingModalComponent
];

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    CommonModule,
    MatTooltipModule,
    TcCoreModule
  ],
  exports: [
    ...components
  ],
  entryComponents: [
    CoachingModalComponent
  ]
})
export class CoachingModule { }
