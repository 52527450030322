import { Component, OnInit, ViewChild, OnDestroy, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ManageFolderComponent } from '../manage-folder/manage-folder.component';
import { SimpleFile, User, Box } from 'src/generated/api/stockmemoriss';
import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { TcUploadFileComponent, TcSmartComponent, TcConfigService, } from '@tc/core';
import { AddFileSuccess, AddScannedImage } from '../../../store/actions/file.actions';
import { getSelectedFolderId } from '../../../store/selectors/folder.selectors';
import { getAccountUser } from 'src/app/modules/account/store/account.selectors';
import { isSharedMode } from '../../../store/selectors/share.selectors';
import { getSelectedBox } from '../../../store/selectors/box.selectors';
import { ContentState } from '../../../store/content.state';


@Component({
  selector: 'list-documents-footer-component',
  templateUrl: './list-documents-footer.component.html',
  styleUrls: ['./list-documents-footer.component.scss']
})
export class ListDocumentsFooterComponent extends TcSmartComponent implements OnInit, OnDestroy {

  isSharedMode$: Observable<boolean>;

  parentFolderId: number;

  selectedFolderId$: Observable<number>;
  selectedFolderIdSubscription: Subscription;

  selectedBox$: Observable<Box>;

  cameraUploadSubscription: Subscription;

  user: User;
  userSubscription: Subscription;

  tcUploadFile: TcUploadFileComponent;
  @ViewChild('uploadFile', { static: true }) set msmTcUploadFile(value: TcUploadFileComponent) {
    this.tcUploadFile = value;
  }

  @ViewChild('fileInput', { static: false }) fileInput;

  constructor(
    public dialog: MatDialog,
    private store: Store<ContentState>,
    private configService: TcConfigService) {
    super();
  }

  ngOnInit() {

    this.isSharedMode$ = this.store.pipe(select(isSharedMode));

    this.selectedBox$ = this.store.pipe(select(getSelectedBox));

    this.selectedFolderId$ = this.store.pipe(select(getSelectedFolderId));
    this.selectedFolderIdSubscription = this.selectedFolderId$.subscribe(selectedFolderId => {
      if (selectedFolderId) {
        this.parentFolderId = selectedFolderId;
      }
    });


    this.tcUploadFile.onFileUploaded = (file: SimpleFile) => {
      if (this.parentFolderId) {
        this.store.dispatch(new AddFileSuccess(file));
      }
    };

    this.userSubscription = this.store.pipe(select(getAccountUser)).subscribe(user => {
      if (user) {
        this.user = user;
      }
    });

  }

  ngOnDestroy() {

    if (this.selectedFolderIdSubscription) {
      this.selectedFolderIdSubscription.unsubscribe();
    }

    if (this.cameraUploadSubscription) {
      this.cameraUploadSubscription.unsubscribe();
    }

    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }

  }

  onAddFolder() {
    this.dialog.open(ManageFolderComponent, { data: { parentFolderId: this.parentFolderId } });
  }

  onAddFile() {
    if (this.parentFolderId) {
      const uploadUrl = (this.configService.config as any).API_BASE_PATH + '/content/files?parentId=' + this.parentFolderId;
      this.tcUploadFile.uploadFile(uploadUrl);
    }
  }

  onScanFile() {
    this.fileInput.nativeElement.value = '';
    this.fileInput.nativeElement.click();
  }

  onScannedFileSelected() {
    const file = this.fileInput.nativeElement.files[0];
    if (file && this.parentFolderId) {
      this.store.dispatch(new AddScannedImage({ image: file, parentFolderId: this.parentFolderId }));
    }
  }

}
