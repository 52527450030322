import { Component, OnInit, Optional, Inject, OnDestroy } from '@angular/core';
import { AccountService } from 'src/generated/api/stockmemoriss';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { TcAuthenticationService, TcSmartComponent } from '@tc/core';
import { BoxDetailComponent } from '../box-detail/box-detail.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'restitution-password-dialog',
  templateUrl: './restitution-password-dialog.component.html',
  styleUrls: ['./restitution-password-dialog.component.scss']
})
export class RestitutionPasswordDialogComponent extends TcSmartComponent implements OnInit, OnDestroy {

  selectedWord = '';
  targetWordPosition: number;
  words: any;
  validateUserRestitutionChallengeSubscription: Subscription;
  getUserRestitutionChallengeSubscription: Subscription;

  constructor(
    private authenticationService: TcAuthenticationService,
    private accountService: AccountService,
    private dialogRef: MatDialogRef<BoxDetailComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    super();
  }

  ngOnInit() {
    if (this.data) {
      this.words = this.data.model.words;
      this.targetWordPosition = this.data.model.targetWordPosition;
    }

  }

  onWordClick(word: string) {
    this.selectedWord = word;

  }

  onValidate() {
    const authUserId = this.authenticationService.getAuthUserId();
    let resultOk = false;
    if (authUserId) {
      this.accountService.validateUserRestitutionChallenge(authUserId, this.targetWordPosition, this.selectedWord)
        .subscribe(
          (result) => {
            resultOk = true;
            this.dialogRef.close('ok');
          },
          (error) => {
          },
          () => {
            if (!resultOk) {
              this.accountService.getUserRestitutionChallenge(authUserId).subscribe(result => {

                this.selectedWord = '';
                this.words = result.words;
                this.targetWordPosition = result.targetWordPosition;

              });
            }
          });
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    if (this.validateUserRestitutionChallengeSubscription) {
      this.validateUserRestitutionChallengeSubscription.unsubscribe();
    }

    if (this.getUserRestitutionChallengeSubscription) {
      this.getUserRestitutionChallengeSubscription.unsubscribe();
    }
  }

}
