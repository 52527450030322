
import { ContentActions, ContentActionTypes } from '../actions/content.actions';
import { initialContentState, ContentState } from '../content.state';

export function commonReducer(
    state = initialContentState,
    action: ContentActions
): ContentState {
    switch (action.type) {
        case ContentActionTypes.SELECT_ROWS: {

            const selectedRows = state.selectedRows;
            selectedRows.push(...action.payload);

            return {
                ...state,
                selectedRows
            };
        }

        case ContentActionTypes.DESELECT_ROWS: {

            const selectedIds = action.payload.map(f => f.id);

            const selectedRows = state.selectedRows.filter(value => !selectedIds.includes(value.id));

            return {
                ...state,
                selectedRows
            };
        }

        case ContentActionTypes.DESELECT_ALL_ROWS: {

            return {
                ...state,
                selectedRows: []
            };
        }

        /**
         * this action is used when delete files or folders from bin box or when delete expired files from expired files screen
         */
        case ContentActionTypes.REQUEST_FINAL_DELETE_CONFIRMATION_SUCCESS: {
            return {
                ...state,
                selectedRows: []
            };
        }

        case ContentActionTypes.RESTORE_FILES_AND_FOLDERS_SUCCESS: {

            let selectedRows = action.payload.selectedRows;

            const orphanFileIds = action.payload.result.files;
            const orphanFolderIds = action.payload.result.folders;

            // selected ids (files and folders)
            let ids = selectedRows.map(f => f.id);

            if (orphanFileIds) {
                // keep only ids of files that are not orphans
                ids = ids.filter(id => !orphanFileIds.includes(id));
            }

            if (orphanFolderIds) {
                // keep only ids of folders that are not orphans
                ids = ids.filter(id => !orphanFolderIds.includes(id));
            }

            // remove selected files that are are not orphans
            const files = state.files.filter(value => !ids.includes(value.id));

            // remove selected folders that are are not orphans
            const folders = state.folders.filter(value => !ids.includes(value.id));


            // keep in selected rows only orphans
            selectedRows = selectedRows.filter(s => !ids.includes(s.id));

            return {
                ...state,
                files,
                folders,
                selectedRows
            };
        }
        default: {
            return state;
        }
    }
}
