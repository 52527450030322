
import { ExpiredFilesActionTypes, ExpiredFilesActions } from '../actions/expired-files.actions';
import { initialContentState, ContentState } from '../content.state';

export function expiredFilesReducer(
    state = initialContentState,
    action: ExpiredFilesActions
): ContentState {
    switch (action.type) {
        case ExpiredFilesActionTypes.LOAD_EXPIRED_FILES_SUCCESS: {
            return {
                ...state,
                expiredFiles: action.payload,
                selectedRows: []
            };
        }

        case ExpiredFilesActionTypes.REQUEST_EXTEND_EXPIRE_PERIOD_CONFIRMATION_SUCCESS: {

            const selectedIds = action.payload.selectedRows.map(f => f.id);

            // remove selected files from expired files list
            // const expiredFiles = state.expiredFiles.filter(value => !selectedIds.includes(value.id));

            return {
                ...state,
                // expiredFiles,
                // reset selected rows
                selectedRows: []
            };
        }
        default: {
            return state;
        }
    }
}
