import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { Box, AbstractFile, Folder, FilesAndFoldersModel, FileType } from 'src/generated/api/stockmemoriss';
import { getBoxes, getBoxesExceptBin } from '../../../store/selectors/box.selectors';
import { BreadCrumbItem } from 'src/app/modules/shared/models/breadcrumb-item';
import { getEntityToMove, getEntitiesForMoving, getMoveToEntity, getMoveNavigation, getOrphansToMove } from '../../../store/selectors/move.selectors';
import { LoadFoldersForMoving, LoadBoxesForMoving, MoveEntityDone, MoveCancel, MoveOrphansDone } from '../../../store/actions/move.actions';
import { TcTranslateService, TcNotificationService, TcSmartComponent } from '@tc/core';
import { MatDialog } from '@angular/material';
import { ManageFolderComponent } from '../manage-folder/manage-folder.component';
import { ContentState } from '../../../store/content.state';

@Component({
  selector: 'move-folder-or-file',
  templateUrl: './move-folder-or-file.component.html',
  styleUrls: ['./move-folder-or-file.component.scss']
})
export class MoveFolderOrFileComponent extends TcSmartComponent implements OnInit, OnDestroy {

  // list of boxes
  boxes$: Observable<Box[]>;

  // entity(folder or file) that should be moved
  entityToMove: AbstractFile;
  entityToMove$: Observable<AbstractFile>;
  entityToMoveSubscription: Subscription;

  // orphans that should be moved
  orphansToMove: AbstractFile[];
  orphansToMove$: Observable<AbstractFile[]>;
  orphansToMoveSubscription: Subscription;

  // available list of boxes or folders in which entity can be moved
  entitiesForMoving$: Observable<Folder[]>;

  // entity (folder or box) in which folder or file will be moved
  moveToEntity: AbstractFile;
  moveToEntity$: Observable<AbstractFile>;
  moveToEntitySubscription: Subscription;

  // breadcrumb navigation items
  navigationItems: BreadCrumbItem[] = [];
  navigationItemsSubscription: Subscription;

  parentFolderId: number;

  constructor(
    private store: Store<ContentState>,
    private notification: TcNotificationService,
    private translate: TcTranslateService,
    public dialog: MatDialog
  ) {
    super();
  }

  ngOnInit() {

    // when move page is init => there will be displayed the list of boxes (from store)
    this.boxes$ = this.store.pipe(select(getBoxesExceptBin));

    // get entity to move from store
    this.entityToMove$ = this.store.pipe(select(getEntityToMove));
    this.entityToMoveSubscription = this.entityToMove$.subscribe(entity => {
      if (entity) {
        this.entityToMove = entity;
      }
    });

    // get orphans to move from store
    this.orphansToMove$ = this.store.pipe(select(getOrphansToMove));
    this.orphansToMoveSubscription = this.orphansToMove$.subscribe(orphans => {
      if (orphans) {
        this.orphansToMove = orphans;
      }
    });

    // get list of boxes or folders in which entity can be moved
    this.entitiesForMoving$ = this.store.pipe(select(getEntitiesForMoving));

    this.moveToEntity$ = this.store.pipe(select(getMoveToEntity));
    this.moveToEntitySubscription = this.moveToEntity$.subscribe(entity => {
      if (entity) {
        this.moveToEntity = entity;
        this.parentFolderId = entity.id;
      }
    });

    // breadcrumb navigation items
    this.navigationItemsSubscription = this.store.pipe(select(getMoveNavigation)).subscribe(items => {
      // if there are no navigation items => in screen is displayed a box content => we need to add manually "My boxes" menu
      if (items && items.length === 0) {
        const boxesName = this.translate.instant('move.boxes');
        items.unshift({ id: 0, name: boxesName, type: 'boxes', order: 0 });
      }
      this.navigationItems = items;
    });

  }

  ngOnDestroy() {

    if (this.entityToMoveSubscription) {
      this.entityToMoveSubscription.unsubscribe();
    }

    if (this.orphansToMoveSubscription) {
      this.orphansToMoveSubscription.unsubscribe();
    }

    if (this.moveToEntitySubscription) {
      this.moveToEntitySubscription.unsubscribe();
    }

    if (this.navigationItemsSubscription) {
      this.navigationItemsSubscription.unsubscribe();
    }

  }

  // after select a box => load all folders for that box
  onSelectBox(box: Box) {
    if (box) {
      this.store.dispatch(new LoadFoldersForMoving(box.id));
    }
  }

  // after select a folder => load all folders for that folder
  onSelectFolder(folder: Folder) {
    if (folder) {
      this.store.dispatch(new LoadFoldersForMoving(folder.id));
    }
  }

  // add new folder
  onAddFolder() {
    this.dialog.open(ManageFolderComponent, { data: { parentFolderId: this.parentFolderId } });
  }

  // click on breadcrumb navigation item
  // if (item.id !== 0) => item is a folder => load all folders for that folder
  // if (item.id == 0) => item is "My boxes" => display list of boxes
  onSelectNavigationItem(item: BreadCrumbItem) {
    if (item) {
      if (item.id !== 0) {
        this.store.dispatch(new LoadFoldersForMoving(item.id));
      } else {
        this.store.dispatch(new LoadBoxesForMoving());
      }
    }
  }

  // cancel move
  cancel() {
    this.store.dispatch(new MoveCancel());
  }

  // done
  // if moveToEntity is null => user must select a box or a folder
  done() {
    if ((this.entityToMove || this.orphansToMove.length > 0) && this.moveToEntity) {

      if (this.entityToMove) {
        // change parent id
        this.entityToMove.parentId = this.moveToEntity.id;
        this.store.dispatch(new MoveEntityDone(this.entityToMove));

      } else if (this.orphansToMove.length > 0) {

        const model: FilesAndFoldersModel = {
          files: this.orphansToMove.filter(f => f.type === FileType.File).map(f => f.id),
          folders: this.orphansToMove.filter(f => f.type === FileType.Folder).map(f => f.id),
        };

        this.store.dispatch(new MoveOrphansDone({ newParentId: this.moveToEntity.id, model }));
      }

    } else {
      this.notification.warning(this.translate.instant('move.warning'));
    }
  }

}
