import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { TcMenuItem, TcSmartComponent } from '@tc/core';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/modules/core/store/app.state';
import { GenericMenuComponent } from 'src/app/modules/shared/components/dumb/generic-menu/generic-menu.component';
import { getSingleDisplay } from '../../../store/selectors/box.selectors';
import { getAccountUser } from 'src/app/modules/account/store/account.selectors';
import { User } from 'src/generated/api/stockmemoriss';
import { RouteOptions } from 'src/app/modules/core/enums/route-options';
import { HomePageMenuService } from 'src/app/services/home-page-menu.service';
import { RouterService } from 'src/app/services/router.service';

@Component({
  selector: 'home-page-menu-component',
  templateUrl: './home-page-menu.component.html',
  styleUrls: ['./home-page-menu.component.scss']
})
export class HomePageMenuComponent extends TcSmartComponent implements OnInit, OnDestroy {

  scrollOffset = 0;
  currentUser: User;
  private singleDisplaySubscription: Subscription;
  private currentUserSubscription: Subscription;

  genericMenu: GenericMenuComponent;
  @ViewChild('genericMenu', { static: true }) set appGenericMenu(value: GenericMenuComponent) {
    this.genericMenu = value;
  }

  constructor(
    private routerService: RouterService,
    private store: Store<AppState>,
    private homePageMenuService: HomePageMenuService) {
    super();
  }

  scrollEvent = (event: any): void => {
    if (event.srcElement && event.srcElement.activeElement && event.srcElement.activeElement.nodeName === 'BODY') {
      this.scrollOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    }
  }

  ngOnInit() {

    window.addEventListener('scroll', this.scrollEvent, true);

    this.genericMenu.menuName = 'home-page-menu';
    this.genericMenu.menuItems = this.homePageMenuService.getMenuItems();

    this.singleDisplaySubscription = this.store.pipe(select(getSingleDisplay)).subscribe(singleDisplay => {

      const itemDisplayIndex = this.genericMenu.menuItems.findIndex(i => i.name === 'display-option');
      if (itemDisplayIndex >= 0) {
        this.genericMenu.menuItems[itemDisplayIndex].isChecked = singleDisplay;
      }

    });
    this.currentUserSubscription = this.store.pipe(select(getAccountUser)).subscribe(user => {
      if (user) {

        this.currentUser = user;

        const itemDisplayIndex = this.genericMenu.menuItems.findIndex(m => m.name === 'coaching-mode');
        if (itemDisplayIndex >= 0) {
          this.genericMenu.menuItems[itemDisplayIndex].isChecked = user.coachingMode === 'debutant';
        }

      }

    });

  }

  onMenuItemClick = (item: TcMenuItem) => {

    this.homePageMenuService.onMenuItem(item, this.currentUser);
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scrollEvent, true);

    if (this.singleDisplaySubscription) {
      this.singleDisplaySubscription.unsubscribe();
    }

    if (this.currentUserSubscription) {
      this.currentUserSubscription.unsubscribe();
    }

  }

  goToSearch() {
    this.routerService.goToPage(RouteOptions.Search);
  }
}
