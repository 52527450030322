import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'F37-expired-files',
  templateUrl: './F37-expired-files.component.html',
  styleUrls: ['./F37-expired-files.component.scss']
})
export class F37ExpiredFilesComponent implements OnInit {

  title: string;

  constructor() { }

  ngOnInit() {
    this.title = 'Documents périmés';
  }

}
