import { Component, OnInit, ElementRef, Optional, Inject, OnDestroy } from '@angular/core';
import { TcFormComponent, TcTranslateService, TcNotificationService } from '@tc/core';
import { User } from 'src/generated/api/stockmemoriss';
import { Store, select } from '@ngrx/store';
import { AccountState } from 'src/app/modules/account/store/account.reducer';
import { Router } from '@angular/router';
import { EditUser } from 'src/app/modules/account/store/account.actions';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Observable, Subscription } from 'rxjs';
import { getAccountUser } from 'src/app/modules/account/store/account.selectors';

@Component({
  selector: 'password-restitution-use',
  templateUrl: './password-restitution-use.component.html',
  styleUrls: ['./password-restitution-use.component.scss']
})
// pop-up component (EDIT Restitution password)
export class PasswordRestitutionUseComponent extends TcFormComponent<User> implements OnInit, OnDestroy {

  user: User;
  user$: Observable<User>;
  userSubscription: Subscription;

  constructor(
    private store: Store<AccountState>,
    public translateService: TcTranslateService,
    private notificationService: TcNotificationService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: User,
    public dialogRef: MatDialogRef<PasswordRestitutionUseComponent>,
    element: ElementRef) {

    super(translateService, element);

    this.fields = [
      {
        key: 'passwordRestitutionUse',
        type: 'checkbox',
        templateOptions: {
          required: true,
        }
      },
    ];
  }
  ngOnInit() {
    this.userSubscription = this.store.pipe(select(getAccountUser)).subscribe(user => {
      if (user) {
        this.user = user;
      }
    });
    if (this.data) {
      this.model = Object.assign({}, this.data);
    }
    super.ngOnInit();
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  submit(model: User) {
    delete (model as any).confirmPasswordRestitutionUse;
    this.store.dispatch(new EditUser({ user: model, actionType: 'password-restitution-use' }));
    this.dialogRef.close(model);
  }

  onClose() {
    this.dialogRef.close();
  }

}
