import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'select-color',
  templateUrl: './select-color.component.html',
  styleUrls: ['./select-color.component.scss']
})
// todo: move this to @tc/core
export class SelectColorComponent extends FieldType {
}
