import { AuthActionTypes } from '@tc/core';

export function clearState(reducer) {
    return (state, action) => {

        if (action.type === AuthActionTypes.Logout) {
            for (const key in state) {
                if (key !== 'settings') {
                    state[key] = undefined;
                }
            }
        }
        return reducer(state, action);
    };
}

