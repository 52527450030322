import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TcSmartComponent, TcListComponent } from '@tc/core';
import { SelectFilesForCertificate, DeleteFileFromCertificateFiles, PayCertificate } from '../../../store/actions/certificate.actions';
import { getFilesForCertificate } from '../../../store/selectors/content.selectors';
import { SimpleFile, AbstractFile } from 'src/generated/api/stockmemoriss';
import { Observable } from 'rxjs';
import { ContentState } from '../../../store/content.state';

@Component({
  selector: 'certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss']
})
export class CertificateComponent extends TcSmartComponent implements OnInit {

  filesForCertificate$: Observable<AbstractFile[]>;

  constructor(
    private store: Store<ContentState>
  ) {
    super();
  }

  certificateFilesList: TcListComponent;
  @ViewChild('certificateFilesList', { static: true }) set setCertificateFilesList(values: TcListComponent) {
    this.certificateFilesList = values;
  }

  @ViewChild('colNameTemplate', { static: true }) colNameTemplate: TemplateRef<any>;

  ngOnInit() {

    this.filesForCertificate$ = this.store.pipe(select(getFilesForCertificate));

    this.certificateFilesList.rows$ = this.filesForCertificate$;
    this.certificateFilesList.hasActionsLabel = false;
    this.certificateFilesList.isPaged = false;
    this.certificateFilesList.isFiltrable = false;
    this.certificateFilesList.columns = [
      {
        propertyName: 'name',
        visible: true,
        filtrable: false,
        htmlTemplate: this.colNameTemplate,
      }
    ];

    this.certificateFilesList.rowActions = [
      {
        actionName: 'delete',
        visible: true,
        hasText: false,
        icon: 'delete'
      }
    ];

    this.certificateFilesList.onDeleteAction = (file: AbstractFile) => {
      if (file) {
        this.store.dispatch(new DeleteFileFromCertificateFiles(file));
      }
    };

  }

  add() {
    this.store.dispatch(new SelectFilesForCertificate());
  }

  onSelectFile(file: SimpleFile) {

  }

  validate() {
    this.store.dispatch(new PayCertificate());
  }

}
