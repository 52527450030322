import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TcPage } from '@tc/core';

@Component({
  selector: 'F01-confirm-email',
  templateUrl: './F01-confirm-email.component.html',
  styleUrls: ['./F01-confirm-email.component.scss']
})
export class F01ConfirmEmailComponent extends TcPage implements OnInit {

  code: string;

  constructor(public route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    const routeParams = this.route.snapshot.queryParams;
    this.code = routeParams.code;
  }
}



