import { Component, OnInit } from '@angular/core';
import { TcTranslateService, TcPage } from '@tc/core';

@Component({
  selector: 'F19-journal-modifications',
  templateUrl: './F19-journal-modifications.component.html',
  styleUrls: ['./F19-journal-modifications.component.scss']
})
export class F19JournalModificationsComponent extends TcPage implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
  }

}
