import { TcService, TcNotificationService, TcTranslateService, Logout } from '@tc/core';
import { Injectable } from '@angular/core';
import { ExpiredFile } from 'src/generated/api/stockmemoriss';
import { Store } from '@ngrx/store';
import { RequestFinalDeleteConfirmation } from '../modules/content/store/actions/content.actions';
import { RequestExtendExpirePeriodConfirmation } from '../modules/content/store/actions/expired-files.actions';
import { RouterService } from './router.service';
import { ContentState } from '../modules/content/store/content.state';

@Injectable({
    providedIn: 'root'
})
export class ExpiredFilesService extends TcService {

    constructor(
        private store: Store<ContentState>,
        private notificationService: TcNotificationService,
        private translateService: TcTranslateService,
        private routerService: RouterService) {
        super();
    }

    private showNoItemsWarning() {
        this.notificationService.warning(this.translateService.instant('expired-files.no-items.warning'));
    }

    deleteSelectedExpiredFiles(selectedExpiredFiles: ExpiredFile[]) {
        if (selectedExpiredFiles.length === 0) {
            this.showNoItemsWarning();
        } else {
            this.store.dispatch(new RequestFinalDeleteConfirmation({ folders: null, files: selectedExpiredFiles.map(i => i.id), removeAll: false }));
        }
    }

    extendSelectedExpiredFiles(selectedExpiredFiles: ExpiredFile[]) {
        if (selectedExpiredFiles.length === 0) {
            this.showNoItemsWarning();
        } else {
            this.store.dispatch(new RequestExtendExpirePeriodConfirmation(selectedExpiredFiles.map(i => i.id)));
        }
    }

    requestExtendExpirePeriodConfirmationSuccess() {
        this.notificationService.success(this.translateService.instant('expired-files.extend-confirmation'));
    }

    extendExpirePeriodSuccess(items: number) {
        this.routerService.goToHomePage();
        this.notificationService.success(this.translateService.instant('expired-files.extend-expire-period-success', { items }));

    }

    extendExpirePeriodError() {
        this.store.dispatch(new Logout());
    }

}
