import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { TcService } from '@tc/core';
import { RouteOptions } from '../modules/core/enums/route-options';


@Injectable({
    providedIn: 'root'
})
export class RouterService extends TcService {

    constructor(private router: Router) {
        super();
    }

    goToPage(route: RouteOptions, extras?: NavigationExtras) {
        this.router.navigate(['/' + route], extras);
    }

    goToHomePage() {
        this.router.navigate(['/']);
    }

    goToUrl(url: string) {
        this.router.navigate([url]);
    }

    goToLoginPage() {
        this.goToPage(RouteOptions.Login);
    }


    goToLoginExpiredPage() {
        this.goToPage(RouteOptions.PasswordExpired);
    }

    goToLoginPageWithReturnUrl(returnUrl: string) {
        this.goToPage(RouteOptions.Login, { queryParams: { returnUrl } });
    }


    goToSignUpPage() {
        this.goToPage(RouteOptions.SignUp);
    }

    goToSignUpPageWithSharedUrl(sharedUrl: string) {
        this.goToPage(RouteOptions.SignUp, { queryParams: { sharedUrl } });
    }

    goToForgetPasswordPage() {
        this.goToPage(RouteOptions.ForgetPassword);
    }

    goToForgetPasswordPageWithSharedUrl(sharedUrl: string) {
        this.goToPage(RouteOptions.ForgetPassword, { queryParams: { sharedUrl } });
    }

    goToPasswordExpiredPage() {
        this.goToPage(RouteOptions.PasswordExpired);
    }

    goToPasswordExpiredPageWithSharedUrl(sharedUrl: string) {
        this.goToPage(RouteOptions.PasswordExpired, { queryParams: { sharedUrl } });
    }


    goToCguPage() {
        this.goToPage(RouteOptions.CGUAccept);
    }

    goToCguPageWithSharedUrl(sharedUrl: string) {
        this.goToPage(RouteOptions.CGUAccept, { queryParams: { sharedUrl } });
    }



    goToPaymentPage() {
        this.goToPage(RouteOptions.Payment);
    }


    goToEmailVerificationPage() {
        this.goToPage(RouteOptions.EmailVerification);
    }

    goToEmailVerificationPageWithSharedUrl(sharedUrl: string) {
        this.goToPage(RouteOptions.EmailVerification, { queryParams: { sharedUrl } });
    }

    goToJournalGeneralPage() {
        this.goToPage(RouteOptions.JournalModifications);
    }


    goToJournalFolderOrFilePage() {
        this.goToPage(RouteOptions.JournalModifications);
    }

    goToMovePage() {
        this.goToPage(RouteOptions.Move);
    }

    goToSelectFilesForCertificatePage() {
        this.goToPage(RouteOptions.SelectFilesForCertificate);
    }

    goToCertificatePaymentPage() {
        this.goToPage(RouteOptions.CertificatePayment);
    }

}
