import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AbstractFile, SimpleFile, FileType } from 'src/generated/api/stockmemoriss';
import { Utils } from 'src/app/modules/core/utils';
import { TcDumbComponent } from '@tc/core';

@Component({
  selector: 'msm-navigate-row',
  templateUrl: './msm-navigate-row.component.html',
  styleUrls: ['./msm-navigate-row.component.scss']
})
export class MsmNavigateRowComponent extends TcDumbComponent implements OnInit {

  @Input()
  row: AbstractFile;

  @Input()
  nameWidth = '60vw';

  @Output()
  select: EventEmitter<AbstractFile> = new EventEmitter();

  constructor() {
    super();
  }

  ngOnInit() {
  }

  onSelect(row: AbstractFile) {
    if (row) {
      this.select.emit(row);
    }
  }

  getFileImage(file: SimpleFile): string {
    if (file.type === FileType.Folder) {
      return '/assets/images/folder.svg';
    } else {
      return '/assets/images/' + Utils.getFileExtension(file.name) + '.svg';
    }

  }

}
