import { Component, OnInit } from '@angular/core';
import { TcDumbComponent } from '@tc/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'confirm-certificate-dialog',
  templateUrl: './confirm-certificate-dialog.component.html',
  styleUrls: ['./confirm-certificate-dialog.component.scss']
})
export class ConfirmCertificateDialogComponent extends TcDumbComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ConfirmCertificateDialogComponent>, ) {
    super();
  }

  ngOnInit() {
  }

  onClose() {
    this.dialogRef.close();
  }

}
