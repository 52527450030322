import { Component, OnInit, ElementRef, OnDestroy, NgZone } from '@angular/core';
import { Subscription } from 'rxjs';
import { TcPage, Logout, TcTranslateService, TcNotificationService } from '@tc/core';
import { AppState } from 'src/app/modules/core/store/app.state';
import { Store, select } from '@ngrx/store';
import { getAccountUser } from 'src/app/modules/account/store/account.selectors';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/generated/api/stockmemoriss';
import { RouteOptions } from 'src/app/modules/core/enums/route-options';

@Component({
  selector: 'master-page',
  templateUrl: './master-page.component.html',
  styleUrls: ['./master-page.component.scss']
})
export class MasterPageComponent extends TcPage implements OnInit, OnDestroy {

  coachingKey = '';

  footerVisible = true;
  scrollOffset = 0;

  currentUserSubscription: Subscription;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private elementRef: ElementRef,
    private notificationService: TcNotificationService,
    private translateService: TcTranslateService) {
    super();
  }

  ngOnInit() {

    window.addEventListener('scroll', this.scrollEvent, true);
    this.coachingKey = this.elementRef.nativeElement.lastChild.localName;

    /**
     * check if current user is lecteur. a lecteur user can navigate only to list-documents and file-viewer
     */
    this.currentUserSubscription = this.store.pipe(select(getAccountUser)).subscribe(currentUser => {
      if (currentUser) {
        if (currentUser.role === User.RoleEnum.Lecteur) {
          const url = this.router.url;
          if (url && !(url.includes(RouteOptions.ListDocuments) || url.includes(RouteOptions.FileViewer))) {
            this.store.dispatch(new Logout());
          }
        }
      }
    });
  }

  ngOnDestroy() {

    window.removeEventListener('scroll', this.scrollEvent, true);

    if (this.currentUserSubscription) {
      this.currentUserSubscription.unsubscribe();
    }

  }

  scrollEvent = (event: any): void => {

    let offset = 0;

    // this is necessary for journal page, because the scroll is only on the list container, not on the entire screen
    if (event.srcElement.className === 'tc-list-container') {
      offset = event.srcElement.scrollTop;
    } else if (event.srcElement && event.srcElement.activeElement && event.srcElement.activeElement.nodeName === 'BODY') {
      offset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    }

    if (offset > this.scrollOffset) {
      this.footerVisible = false;
    } else {
      this.footerVisible = true;
    }
    this.scrollOffset = offset <= 0 ? 0 : offset;
  }

}
