import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ContentState } from '../content.state';

const getContentState = createFeatureSelector<ContentState>(
    'content'
);

export const getEntityToMove = createSelector(
    getContentState,
    (state: ContentState) => state.move.entityToMove
);

export const getEntitiesForMoving = createSelector(
    getContentState,
    (state: ContentState) => state.move.entitiesForMoving
);

export const getMoveToEntity = createSelector(
    getContentState,
    (state: ContentState) => state.move.moveToEntity
);

export const getMoveNavigation = createSelector(
    getContentState,
    (state: ContentState) => state.move.navigation
);

export const getOrphansToMove = createSelector(
    getContentState,
    (state: ContentState) => state.move.orphans
);
