import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Faq } from 'src/generated/api/stockmemoriss';
import { Store, select } from '@ngrx/store';
import { FaqState } from '../../../store/faq.reducer';
import { LoadFaq } from '../../../store/faq.actions';
import { getFaq } from '../../../store/faq.selectors';
import { TcSmartComponent } from '@tc/core';

@Component({
  selector: 'faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FAQComponent extends TcSmartComponent implements OnInit {

  faq$: Observable<Faq[]>;

  constructor(private store: Store<FaqState>) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(new LoadFaq());
    this.faq$ = this.store.pipe(select(getFaq));
  }

}
