export enum HomePageMenuOptions {
    Account = 'account',
    Params = 'params',
    Journal = 'journal',
    Certificate = 'certificate',
    ExpiredFiles = 'expired-files',
    ContactSupport = 'contact-support',
    Remarque = 'remarque',
    CoachingMode = 'coaching-mode',
    Faq = 'faq',
    DisplayOption = 'display-option',
    Logout = 'logout'
}
