import { createSelector, createFeatureSelector } from '@ngrx/store';
import { FaqState } from './faq.reducer';

const getFaqState = createFeatureSelector<FaqState>(
    'faq'
);
export const getFaq = createSelector(
    getFaqState,
    (state: FaqState) => {
        return state.faq;
    }
);
