import { Component, OnInit } from '@angular/core';
import { TcSmartComponent } from '@tc/core';
import { Store } from '@ngrx/store';
import { GenerateCertificate } from '../../../store/actions/certificate.actions';
import { ContentState } from '../../../store/content.state';

@Component({
  selector: 'certificate-payment',
  templateUrl: './certificate-payment.component.html',
  styleUrls: ['./certificate-payment.component.scss']
})
export class CertificatePaymentComponent extends TcSmartComponent implements OnInit {

  constructor(private store: Store<ContentState>) {
    super();
  }

  ngOnInit() {
  }

  validateAndPay() {
    this.store.dispatch(new GenerateCertificate());
  }

}
