import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TcAuthenticationService, TcSmartComponent } from '@tc/core';
import { RouterService } from 'src/app/services/router.service';
import { DownloadLink } from '../../../store/actions/content.actions';
import { ContentState } from '../../../store/content.state';

@Component({
  selector: 'download-link',
  templateUrl: './download-link.component.html',
  styleUrls: ['./download-link.component.scss']
})
export class DownloadLinkComponent extends TcSmartComponent implements OnInit {

  constructor(
    public activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store<ContentState>,
    private authService: TcAuthenticationService,
    private routerService: RouterService) {
    super();
  }

  ngOnInit() {
    if (!this.authService.isAuthenticated()) {
      this.routerService.goToLoginPageWithReturnUrl(this.router.url);
    } else {
      const id = this.activatedRoute.snapshot.paramMap.get('id');
      if (id) {
        this.store.dispatch(new DownloadLink(id));
      } else {
        this.routerService.goToHomePage();
      }
    }
  }

}
