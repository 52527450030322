import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ContentState } from '../content.state';

const getContentState = createFeatureSelector<ContentState>(
    'content'
);
export const getSelectFoldersAndFilesBoxes = createSelector(
    getContentState,
    (state: ContentState) => {
        return state.selectFoldersAndFiles ? state.selectFoldersAndFiles.boxes.filter(b => !b.isBin).sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0)) : [];
    }
);

export const getSelectFoldersAndFilesFolders = createSelector(
    getContentState,
    (state: ContentState) => state.selectFoldersAndFiles ? state.selectFoldersAndFiles.folders : []
);

export const getSelectFoldersAndFilesFiles = createSelector(
    getContentState,
    (state: ContentState) => state.selectFoldersAndFiles ? state.selectFoldersAndFiles.files : []
);

export const getSelectFoldersAndFilesSelectedFolderId = createSelector(
    getContentState,
    (state: ContentState) => state.selectFoldersAndFiles ? state.selectFoldersAndFiles.selectedFolderId : undefined
);

export const getSelectFoldersAndFilesNavigation = createSelector(
    getContentState,
    (state: ContentState) => state.selectFoldersAndFiles ? state.selectFoldersAndFiles.navigation : []
);

export const getSelectFoldersAndFilesSelectedRows = createSelector(
    getContentState,
    (state: ContentState) => state.selectFoldersAndFiles ? state.selectFoldersAndFiles.selectedRows : []
);
