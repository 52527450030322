import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { TcFormComponent, TcTranslateService, TcNotificationService } from '@tc/core';
import { Store, select } from '@ngrx/store';
import { PasswordExpired } from 'src/app/modules/account/store/account.actions';
import { User } from 'src/generated/api/stockmemoriss';
import { AccountState } from '../../../store/account.reducer';
import { Utils } from 'src/app/modules/core/utils';
import { Router, ActivatedRoute } from '@angular/router';
import { RouterService } from 'src/app/services/router.service';
import { Subscription } from 'rxjs';
import { PasswordExpiredModel } from 'src/generated/api/stockmemoriss/model/passwordExpired';

@Component({
  selector: 'password-expired',
  templateUrl: 'password-expired.component.html',
  styleUrls: ['password-expired.component.scss']
})
export class PasswordExpiredComponent extends TcFormComponent<PasswordExpiredModel> implements OnInit {

  sharedUrl: string;

  passwordExpired: PasswordExpiredModel;
  

  constructor(
    private router: Router,
    private store: Store<AccountState>,
    private notificationService: TcNotificationService,
    private route: ActivatedRoute,
    private routerService: RouterService,
    translate: TcTranslateService,
    element: ElementRef) {

    super(translate, element);

    this.fields = [
      {
        key: 'username',
        type: 'input',
        templateOptions: {
          type: 'email',
          required: true
        },
      }, 
      {
        key: 'password',
        type: 'input',
        templateOptions: {
          type: 'password',
          required: true
        },
      },
      {
        key: 'newPassword',
        type: 'input',
        templateOptions: {
          type: 'password',
          required: true
        }
      },
      {
        key: 'confirmNewPassword',
        type: 'input',
        templateOptions: {
          type: 'password',
          required: true,
        },
        validators: {
          fieldMatch: {
            expression: (control) => control.value === this.model.newPassword,
            message: this.translate.instant('sign-up.validations.confirmPasswordMessage')
          },
        },
      }
    ];
  }
  ngOnInit() {

    super.ngOnInit();

    const sharedUrl = this.route.snapshot.queryParams.sharedUrl;

    if (sharedUrl) {
      this.sharedUrl = sharedUrl;
    }

  }

  submit(passwordExpired) {
    if (this.form.valid && this.validateEmail(passwordExpired.username) && this.validatePassword(passwordExpired.newPassword)) {
      this.store.dispatch(new PasswordExpired({ email: passwordExpired.username, motdepasseold: passwordExpired.password, motdepasse: passwordExpired.newPassword, sharedUrl: this.sharedUrl }));
    }
  }

  validatePassword(password: string): boolean {
    if (!Utils.isValidPassword(password)) {
      this.notificationService.error(this.translate.instant('account-detail.password-format-error'));
      return false;
    }
    return true;
  }

  validateEmail(email: string): boolean {
    if (!Utils.isValidEmail(email)) {
      this.notificationService.error(this.translate.instant('account-detail.email-format-error'));
      return false;
    }
    return true;
  }
}
