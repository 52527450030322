import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BreadCrumbItem } from '../../../models/breadcrumb-item';
import { TcDumbComponent } from '@tc/core';

@Component({
  selector: 'breadcrumb-header',
  templateUrl: './breadcrumb-header.component.html',
  styleUrls: ['./breadcrumb-header.component.scss']
})
export class BreadcrumbHeaderComponent extends TcDumbComponent implements OnInit {

  @Input()
  items: BreadCrumbItem[] = [];

  @Output()
  selectItem: EventEmitter<BreadCrumbItem> = new EventEmitter();

  constructor() {
    super();
   }

  ngOnInit() {
  }

  select(item: BreadCrumbItem) {
    this.selectItem.emit(item);
  }

}
