import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/modules/core/store/app.state';
import { SimpleFile } from 'src/generated/api/stockmemoriss';
import { Subscription } from 'rxjs';
import { TcConfigService, TcAuthenticationService, TcSmartComponent, TcSpinnerService } from '@tc/core';
import { Utils } from 'src/app/modules/core/utils';
import { FileExtension } from 'src/app/modules/core/file-extension';
import { PDFDocumentProxy, PDFProgressData } from 'ng2-pdf-viewer';
import { getContentState } from 'src/app/modules/content/store/selectors/share.selectors';
import { ContentState } from 'src/app/modules/content/store/content.state';

@Component({
  selector: 'file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss']
})
export class FileViewerComponent extends TcSmartComponent implements OnInit, OnDestroy {

  selectedFile: SimpleFile = null;
  selectedFileSubscription: Subscription;

  pdfSrc = '';
  imgSrc = '';

  constructor(
    private store: Store<AppState>,
    private configService: TcConfigService,
    private authService: TcAuthenticationService,
    private spinnerService: TcSpinnerService) {
    super();
  }

  ngOnInit() {

    this.selectedFileSubscription = this.store.pipe(select(getContentState)).subscribe(contentState => {

      const selectedFile = contentState.selectedFile;

      if (selectedFile) {
        const extension = Utils.getFileExtension(selectedFile.name);

        if (extension === FileExtension.pdf) {
          this.pdfSrc = this.getUrl(contentState, (this.configService.config as any).API_BASE_PATH + '/content/download-file/' + selectedFile.id);
        }

        if (extension === FileExtension.doc) {
          this.pdfSrc = this.getUrl(contentState, (this.configService.config as any).API_BASE_PATH + '/content/download-pdf/' + selectedFile.id);
        }

        if (extension === FileExtension.jpg || extension === FileExtension.png) {
          this.imgSrc = this.getUrl(contentState, (this.configService.config as any).API_BASE_PATH + '/content/download-file/' + selectedFile.id);
        }

        if (this.pdfSrc !== '') {
          this.spinnerService.showSpinner('load-pdf');
        }

      }

    });
  }

  ngOnDestroy() {

    this.pdfSrc = '';
    this.imgSrc = '';

    this.spinnerService.hideSpinner('load-pdf');

    if (this.selectedFileSubscription) {
      this.selectedFileSubscription.unsubscribe();
    }

  }

  afterPDFLoad(pdf: PDFDocumentProxy) {
    this.spinnerService.hideSpinner('load-pdf');
  }

  onError(error: any) {
    this.spinnerService.hideSpinner('load-pdf');
  }

  onProgress(progressData: PDFProgressData) {
  }

  private getUrl(contentState: ContentState, url: string): string {

    url += '?token=' + this.authService.getAccessToken();

    if (contentState.sharedId && contentState.sharedPassword) {
      url += '&X-SHARE-KEY=' + encodeURIComponent(Utils.getShareKeyAuth(contentState.sharedId, contentState.sharedPassword));
    }

    return url;

  }

}
