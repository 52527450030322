export enum RouteOptions {
    Login = 'login',
    SignUp = 'sign-up',
    ForgetPassword = 'forget-password',
    PasswordExpired = 'password-expired',
    Payment = 'payment',
    EmailVerification = 'email-verification',
    ConfirmEmail = 'confirm-email',
    CGUAccept = 'cgu-accept',
    ShareFile = 'shared-file/:id',
    ShareFolder = 'shared-folder/:id',
    ListDocuments = 'list-documents',
    FileViewer = 'file-viewer',
    MyAccount = 'my-account',
    Search = 'search',
    ContactTeam = 'contact-team',
    ContactSupport = 'contact-support',
    Settings = 'settings',
    BoxAddress = 'box-address',
    FAQ = 'faq',
    Move = 'move',
    Journal = 'journal',
    JournalModifications = 'journal-modifications',
    ConfirmDelete = 'confirm-delete',
    Certificate = 'certificate',
    SelectFilesForCertificate = 'select-files-for-certificate',
    CertificatePayment = 'certificate-payment',
    ExpiredFiles = 'expired-files',
    ConfirmProlongation = 'confirm-prolongation',
    DownloadLink = 'download-link/:id'
}
