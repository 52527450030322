import { Component, OnInit } from '@angular/core';
import { TcPage } from '@tc/core';

@Component({
  selector: 'F01-email-verification',
  templateUrl: './F01-email-verification.component.html',
  styleUrls: ['./F01-email-verification.component.scss']
})
export class F01EmailVerficationComponent extends TcPage implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
  }

}
