import { Component, OnInit, OnDestroy } from '@angular/core';
import { TcPage } from '@tc/core';

@Component({
  selector: 'F29-list-documents',
  templateUrl: './F29-list-documents.component.html',
  styleUrls: ['./F29-list-documents.component.scss']
})
export class F29ListDocumentsComponent extends TcPage implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {

  }
}
