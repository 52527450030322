import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FinalDelete } from '../../../store/actions/content.actions';
import { TcAuthenticationService, TcSmartComponent } from '@tc/core';
import { RouterService } from 'src/app/services/router.service';
import { ContentState } from '../../../store/content.state';

@Component({
  selector: 'confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss']
})
export class ConfirmDeleteComponent extends TcSmartComponent implements OnInit {

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private store: Store<ContentState>,
    private authService: TcAuthenticationService,
    private routerService: RouterService) {
    super();
  }

  ngOnInit() {

    if (!this.authService.isAuthenticated()) {
      this.routerService.goToLoginPageWithReturnUrl(this.router.url);
    } else {
      const routeParams = this.route.snapshot.queryParams;
      const code = routeParams.code;

      if (code) {
        this.store.dispatch(new FinalDelete(code));
      } else {
        this.routerService.goToHomePage();
      }
    }

  }

}
