import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TcTranslateService } from '@tc/core';
import { Observable } from 'rxjs';

@Injectable()
export class I18NResolver implements Resolve<any[]> {

    constructor(private translateService: TcTranslateService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any[]> {
        return this.translateService.get('cgu');
    }

}
