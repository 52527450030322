import { Injectable } from '@angular/core';
import { TcMenuItem, TcService } from '@tc/core';
import { SimpleFile } from 'src/generated/api/stockmemoriss';
import { Store } from '@ngrx/store';
import { LoadJournal } from '../modules/journal/store/journal.actions';
import { RouterService } from './router.service';
import { DownloadFile, ShareFile, RequestDeleteFile } from '../modules/content/store/actions/file.actions';
import { ArchiveAttestation, ControleAttestation } from '../modules/content/store/actions/content.actions';
import { MoveEntity } from '../modules/content/store/actions/move.actions';
import { FileMenuOptions } from '../modules/core/enums/file-menu-options';
import { ContentState } from '../modules/content/store/content.state';

@Injectable({
    providedIn: 'root'
})
export class FileMenuService extends TcService {

    constructor(
        private store: Store<ContentState>,
        private routerService: RouterService
    ) {
        super();
    }

    public getMenuItems(): TcMenuItem[] {

        return [
            {
                name: FileMenuOptions.RequestDelete,
            },
            {
                name: FileMenuOptions.Download,
            },
            {
                name: FileMenuOptions.Move,
            },
            {
                name: FileMenuOptions.Share,
            },
            {
                name: FileMenuOptions.Journal,
            },
            {
                name: FileMenuOptions.ArchiveAttestation,
            },
            {
                name: FileMenuOptions.ControleAttestation,
            }
        ];
    }

    onMenuFile(menuItem: TcMenuItem, file: SimpleFile) {

        // downlaod file
        if (menuItem.name === FileMenuOptions.Download) {
            this.store.dispatch(new DownloadFile(file.id));
        }

        // move file
        if (menuItem.name === FileMenuOptions.Move) {
            this.store.dispatch(new MoveEntity(file));
        }

        // share file
        if (menuItem.name === FileMenuOptions.Share) {
            this.store.dispatch(new ShareFile(file));
        }

        // journal file
        if (menuItem.name === FileMenuOptions.Journal) {
            this.store.dispatch(new LoadJournal({ folderId: null, fileId: file.id }));
            this.routerService.goToJournalFolderOrFilePage();
        }

        // demander la destruction
        if (menuItem.name === FileMenuOptions.RequestDelete) {
            this.store.dispatch(new RequestDeleteFile(file));
        }

        // Attestation d'archivage
        if (menuItem.name === FileMenuOptions.ArchiveAttestation) {
            this.store.dispatch(new ArchiveAttestation(file.id));
        }

        // Attestation de controle
        if (menuItem.name === FileMenuOptions.ControleAttestation) {
            this.store.dispatch(new ControleAttestation(file.id));
        }

    }
}
