
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { ContentState } from '../content.state';

const getContentState = createFeatureSelector<ContentState>(
    'content'
);

export const getExpiredFiles = createSelector(
    getContentState,
    (state: ContentState) => state.expiredFiles
);
