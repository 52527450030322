import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { TcDumbComponent } from '@tc/core';

@Component({
  selector: 'upload-resize-dialog',
  templateUrl: './upload-resize-dialog.component.html',
  styleUrls: ['./upload-resize-dialog.component.scss']
})
export class UploadResizeDialogComponent extends TcDumbComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<UploadResizeDialogComponent>,
  ) {
    super();
  }

  ngOnInit() {
  }

  cancel() {
    this.dialogRef.close('no');
  }
  resize() {
    this.dialogRef.close('yes');
  }

  onClose() {
    this.dialogRef.close('no');
  }

}
