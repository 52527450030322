/**
 * StockMemoriss API
 * StockMemoriss API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Box } from '../model/box';
import { Certificate } from '../model/certificate';
import { ExpiredFile } from '../model/expiredFile';
import { FilesAndFoldersModel } from '../model/filesAndFoldersModel';
import { Folder } from '../model/folder';
import { InlineResponse200 } from '../model/inlineResponse200';
import { Journal } from '../model/journal';
import { Share } from '../model/share';
import { SimpleFile } from '../model/simpleFile';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class ContentService {

    protected basePath = 'https://virtserver.swaggerhub.com/TanaCorp/StockMemoriss/1.0.0';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * deletes a box
     * deletes a box 
     * @param id id of box
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBox(id: number, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public deleteBox(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public deleteBox(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public deleteBox(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteBox.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<string>(`${this.configuration.basePath}/content/boxes/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deletes a folder
     * deletes a folder 
     * @param id id of folder
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteFolder(id: number, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public deleteFolder(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public deleteFolder(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public deleteFolder(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteFolder.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<string>(`${this.configuration.basePath}/content/folders/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns file content
     * TODO 
     * @param id id of file to read
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadFile(id: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public downloadFile(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public downloadFile(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public downloadFile(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling downloadFile.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // authentication (shareKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["X-SHARE-KEY"]) {
            headers = headers.set('X-SHARE-KEY', this.configuration.apiKeys["X-SHARE-KEY"]);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.configuration.basePath}/content/download-file/${encodeURIComponent(String(id))}`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns doc or docx file converted to pdf content
     * TODO 
     * @param id id of file to read
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadPdf(id: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public downloadPdf(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public downloadPdf(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public downloadPdf(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling downloadPdf.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // authentication (shareKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["X-SHARE-KEY"]) {
            headers = headers.set('X-SHARE-KEY', this.configuration.apiKeys["X-SHARE-KEY"]);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/pdf',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.configuration.basePath}/content/download-pdf/${encodeURIComponent(String(id))}`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * download file
     * - download file located by id from table temp_fichiers - if expiration is &gt; now() return 404 NotFound 
     * @param id id of file to download
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadTemporaryFile(id: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public downloadTemporaryFile(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public downloadTemporaryFile(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public downloadTemporaryFile(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling downloadTemporaryFile.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/octet-stream',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.configuration.basePath}/content/download-link/${encodeURIComponent(String(id))}`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * delete files and folders from Bin
     * - decrypts code parameter into {folders, files, expiration, userId} using     openssl_decrypt(code, \&#39;AES256\&#39;, config.my_stock_memoriss.ssl_key, 0,  base64_decode(config.my_stock_memoriss.ssl_salt)) - return 400 if one of:     - decrypt failed or is not json     - expiration passed - return 403 if userId is not current user - delete files and folders with all children identified by parameters \&#39;files\&#39; and \&#39;folders\&#39; - exception: Bin folder is not deleted, only his content - return number de files deleted 
     * @param code generated code from request-final-delete-confirmation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public finalDelete(code: string, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public finalDelete(code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public finalDelete(code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public finalDelete(code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling finalDelete.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (code !== undefined && code !== null) {
            queryParameters = queryParameters.set('code', <any>code);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<number>(`${this.configuration.basePath}/content/final-delete`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns a new temporary email address for box
     * adds a new email in table service_emails   - id_service &#x3D; box.id   - expiration_date &#x3D; now() + temporary_email_expiration_heures (parameter from config)   - email &#x3D; generated as {trigrame}.{random}@{email_domain} where     - trigrame &#x3D; first character from user\&#39;s firstName + first character from user\&#39;s lastName + last character from user\&#39;s lastName     - random &#x3D; two random letters + three random digits     - email_domain &#x3D; parameter from config 
     * @param id id of box
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generateTemporaryEmail(id: number, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public generateTemporaryEmail(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public generateTemporaryEmail(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public generateTemporaryEmail(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling generateTemporaryEmail.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse200>(`${this.configuration.basePath}/content/generate-box-temporary-email/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns a box by id
     * retreives a box by id the list of root services (from service table where id_service_parent &#x3D; service_default) folders and files are not filled 
     * @param id id of box
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBox(id: number, observe?: 'body', reportProgress?: boolean): Observable<Box>;
    public getBox(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Box>>;
    public getBox(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Box>>;
    public getBox(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getBox.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Box>(`${this.configuration.basePath}/content/boxes/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns all expired files
     * returns all expired files:   sm_archives.durre_archivage &#x3D;&#x3D; &amp;&amp; (sm_archives.date_depot.addYears(sm_archives.duree_archivage) &lt; now()) id &#x3D; sm_archives.id name &#x3D; sm_archives.nom expiration &#x3D; sm_archives.date_depot.addYears(sm_archives.duree_archivage) box &#x3D; parent box name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getExpiredFiles(observe?: 'body', reportProgress?: boolean): Observable<Array<ExpiredFile>>;
    public getExpiredFiles(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ExpiredFile>>>;
    public getExpiredFiles(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ExpiredFile>>>;
    public getExpiredFiles(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ExpiredFile>>(`${this.configuration.basePath}/content/get-expired-files`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns file detail based on id
     * TODO 
     * @param id id of file to read
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFile(id: number, observe?: 'body', reportProgress?: boolean): Observable<SimpleFile>;
    public getFile(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SimpleFile>>;
    public getFile(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SimpleFile>>;
    public getFile(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getFile.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SimpleFile>(`${this.configuration.basePath}/content/files/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns list of files
     * TODO 
     * @param query text query for simple search use the existing simple search implementation (archives/rechercher)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFiles(query?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SimpleFile>>;
    public getFiles(query?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SimpleFile>>>;
    public getFiles(query?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SimpleFile>>>;
    public getFiles(query?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SimpleFile>>(`${this.configuration.basePath}/content/files`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns folder detail based on id
     * retreives item from service table based on id subFolders array is filled from service table where id_service_parent &#x3D; id files array is filled  from sm_archive table where id_service &#x3D; id 
     * @param id id of folder to read
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFolder(id: number, observe?: 'body', reportProgress?: boolean): Observable<Folder>;
    public getFolder(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Folder>>;
    public getFolder(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Folder>>;
    public getFolder(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getFolder.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // authentication (shareKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["X-SHARE-KEY"]) {
            headers = headers.set('X-SHARE-KEY', this.configuration.apiKeys["X-SHARE-KEY"]);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Folder>(`${this.configuration.basePath}/content/folders/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns list of folders
     * retreives items from service table based on userId subFolders array is filled from service table where id_service_parent &#x3D; id files array is filled  from sm_archive table where id_service &#x3D; id 
     * @param userId id of user to read folders
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFolders(userId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Folder>>;
    public getFolders(userId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Folder>>>;
    public getFolders(userId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Folder>>>;
    public getFolders(userId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Folder>>(`${this.configuration.basePath}/content/folders`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns journal entries
     * with parameters defined in config {journalFilesLimit}, {defaultPaginationLimit}  1. if fileId is present returns CF journal ordered by timestamp (horodate) for idu of fileId, folderId is ignored 2. if folderId is present and fileId is not present:   - find first {journalFilesLimit} files in the folder and all subfolders of folder   - for each file extract the CF journal , union extracted journals and order them by horodate 3. if no folderId or fileId present, apply case 2. for root service of the user. 4. apply offset (default &#x3D; 0 if not present) and {defaultPaginationLimit} on the result 
     * @param folderId id of folder/box to get journal entries
     * @param fileId id of file to get journal entries
     * @param offset Offset from which start returned results
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getJournal(folderId?: number, fileId?: number, offset?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Journal>>;
    public getJournal(folderId?: number, fileId?: number, offset?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Journal>>>;
    public getJournal(folderId?: number, fileId?: number, offset?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Journal>>>;
    public getJournal(folderId?: number, fileId?: number, offset?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (folderId !== undefined && folderId !== null) {
            queryParameters = queryParameters.set('folderId', <any>folderId);
        }
        if (fileId !== undefined && fileId !== null) {
            queryParameters = queryParameters.set('fileId', <any>fileId);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Journal>>(`${this.configuration.basePath}/content/journal`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * gets a share by id
     * - reads a share from table archives_partages and returns   - id &#x3D; archives_partages.id   - id_file &#x3D; archives_partages.id_archive   - id_folder &#x3D; archives_partages.id_service   - passwordRequired &#x3D; (archives_partages.motdepasse!&#x3D;null) - returns 404-NotFound if id does not exist in table or archives_partages.expiration &lt; now() 
     * @param id id of share to read
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getShare(id: string, observe?: 'body', reportProgress?: boolean): Observable<Share>;
    public getShare(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Share>>;
    public getShare(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Share>>;
    public getShare(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getShare.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Share>(`${this.configuration.basePath}/content/shares/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns boxes for current Utilisateur
     * retreives the list of root services (from service table where id_service_parent &#x3D; null) folders and files are not filled 
     * @param userId id of user to read folders
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listBoxes(userId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Box>>;
    public listBoxes(userId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Box>>>;
    public listBoxes(userId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Box>>>;
    public listBoxes(userId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Box>>(`${this.configuration.basePath}/content/boxes`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * create a box
     * - create a box - generate a email in table service_emails with:   - id_service &#x3D; box.id   - expiration_date &#x3D; null   - email &#x3D; generated as {trigrame}.{random}@{email_domain} where     - trigrame &#x3D; first character from user\&#39;s firstName + first character from user\&#39;s lastName + last character from user\&#39;s lastName     - random &#x3D; two random letters + three random digits     - email_domain &#x3D; parameter from config 
     * @param box 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postBox(box?: Box, observe?: 'body', reportProgress?: boolean): Observable<Box>;
    public postBox(box?: Box, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Box>>;
    public postBox(box?: Box, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Box>>;
    public postBox(box?: Box, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Box>(`${this.configuration.basePath}/content/boxes`,
            box,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * create a Certificate
     * create a Certificate for current User 
     * @param certificate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postCertificates(certificate?: Certificate, observe?: 'body', reportProgress?: boolean): Observable<Certificate>;
    public postCertificates(certificate?: Certificate, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Certificate>>;
    public postCertificates(certificate?: Certificate, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Certificate>>;
    public postCertificates(certificate?: Certificate, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Certificate>(`${this.configuration.basePath}/content/certificates`,
            certificate,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * uploads a file
     * uploads a file 
     * @param parentId id of folder to place the file (in db sm_archive.id_service &#x3D; parentId )
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postFile(parentId: number, body?: Blob, observe?: 'body', reportProgress?: boolean): Observable<SimpleFile>;
    public postFile(parentId: number, body?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SimpleFile>>;
    public postFile(parentId: number, body?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SimpleFile>>;
    public postFile(parentId: number, body?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (parentId === null || parentId === undefined) {
            throw new Error('Required parameter parentId was null or undefined when calling postFile.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (parentId !== undefined && parentId !== null) {
            queryParameters = queryParameters.set('parentId', <any>parentId);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/octet-stream'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SimpleFile>(`${this.configuration.basePath}/content/files`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * create a folder
     * create a folder 
     * @param folder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postFolder(folder?: Folder, observe?: 'body', reportProgress?: boolean): Observable<Folder>;
    public postFolder(folder?: Folder, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Folder>>;
    public postFolder(folder?: Folder, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Folder>>;
    public postFolder(folder?: Folder, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Folder>(`${this.configuration.basePath}/content/folders`,
            folder,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * prolongation of files
     * - decrypts code parameter into {files, expiration, userId} using     openssl_decrypt(code, \&#39;AES256\&#39;, config.my_stock_memoriss.ssl_key, 0,  base64_decode(config.my_stock_memoriss.ssl_salt)) - return 400 if one of:     - decrypt failed or is not json     - expiration passed - return 403 if userId is not current user - increase sm_archive.duree_arghivage with service.delai_conservation, where service is the box where the file is stored 
     * @param code generated code from request-prolongation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public prolongation(code: string, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public prolongation(code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public prolongation(code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public prolongation(code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling prolongation.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (code !== undefined && code !== null) {
            queryParameters = queryParameters.set('code', <any>code);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<number>(`${this.configuration.basePath}/content/prolongation`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * create or update a box
     * create or update a box 
     * @param id id of box
     * @param box 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putBox(id: number, box?: Box, observe?: 'body', reportProgress?: boolean): Observable<Box>;
    public putBox(id: number, box?: Box, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Box>>;
    public putBox(id: number, box?: Box, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Box>>;
    public putBox(id: number, box?: Box, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling putBox.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Box>(`${this.configuration.basePath}/content/boxes/${encodeURIComponent(String(id))}`,
            box,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update a file
     * create or update a file 
     * @param id id of file
     * @param simpleFile 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putFile(id: number, simpleFile?: SimpleFile, observe?: 'body', reportProgress?: boolean): Observable<SimpleFile>;
    public putFile(id: number, simpleFile?: SimpleFile, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SimpleFile>>;
    public putFile(id: number, simpleFile?: SimpleFile, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SimpleFile>>;
    public putFile(id: number, simpleFile?: SimpleFile, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling putFile.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<SimpleFile>(`${this.configuration.basePath}/content/files/${encodeURIComponent(String(id))}`,
            simpleFile,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * create or update a folder
     * create or update a folder 
     * @param id id of folder
     * @param folder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putFolder(id: number, folder?: Folder, observe?: 'body', reportProgress?: boolean): Observable<Folder>;
    public putFolder(id: number, folder?: Folder, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Folder>>;
    public putFolder(id: number, folder?: Folder, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Folder>>;
    public putFolder(id: number, folder?: Folder, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling putFolder.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Folder>(`${this.configuration.basePath}/content/folders/${encodeURIComponent(String(id))}`,
            folder,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * send an email with a link to confirm folder and files removal from Bin
     * - sends a confirmation email to [email address &#x3D; username] containing number of files to delete from Bin and an link to confirm deletion:      [msmsite.name]/confirm-delete?code&#x3D;openssl_encrypt({folders, files, expiration, userId}, \&#39;AES256\&#39;, config.my_stock_memoriss.ssl_key, 0,  base64_decode(config.my_stock_memoriss.ssl_salt)) - email template read from marque_config.mail_confirmation_suppresion 
     * @param folders ids of folders to delete
     * @param files ids of files to delete
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public requestFinalDeleteConfirmation(folders?: Array<number>, files?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<object>;
    public requestFinalDeleteConfirmation(folders?: Array<number>, files?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<object>>;
    public requestFinalDeleteConfirmation(folders?: Array<number>, files?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<object>>;
    public requestFinalDeleteConfirmation(folders?: Array<number>, files?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (folders) {
            queryParameters = queryParameters.set('folders', folders.join(COLLECTION_FORMATS['csv']));
        }
        if (files) {
            queryParameters = queryParameters.set('files', files.join(COLLECTION_FORMATS['csv']));
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<object>(`${this.configuration.basePath}/content/request-final-delete-confirmation`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * send an email with a link to confirm prolongation of files
     * - sends a confirmation email to [email address &#x3D; username] containing files list to apply prolongation and an link to confirm prolongation:      [msmsite.name]/confirm-prolongation?code&#x3D;openssl_encrypt({files, expiration, userId}, \&#39;AES256\&#39;, config.my_stock_memoriss.ssl_key, 0,  base64_decode(config.my_stock_memoriss.ssl_salt)) - email template read from marque_config.mail_prolongation 
     * @param files ids of files for prolongation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public requestProlongation(files?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<object>;
    public requestProlongation(files?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<object>>;
    public requestProlongation(files?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<object>>;
    public requestProlongation(files?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (files) {
            queryParameters = queryParameters.set('files', files.join(COLLECTION_FORMATS['csv']));
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<object>(`${this.configuration.basePath}/content/request-prolongation`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * restore files and folders from Bin to previous folder or to a specified folder
     * - returns a list with of files and folders that cannot be moved - a file cannot be moved/restored if any of destination folder of parents of destination does not exist   or is Bin folder - partents are checked until utilisateur.service_default 
     * @param newFolderId the id of destination folder, if not specified previous folder is used
     * @param filesAndFoldersModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public restoreFilesAndFolders(newFolderId?: number, filesAndFoldersModel?: FilesAndFoldersModel, observe?: 'body', reportProgress?: boolean): Observable<FilesAndFoldersModel>;
    public restoreFilesAndFolders(newFolderId?: number, filesAndFoldersModel?: FilesAndFoldersModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FilesAndFoldersModel>>;
    public restoreFilesAndFolders(newFolderId?: number, filesAndFoldersModel?: FilesAndFoldersModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FilesAndFoldersModel>>;
    public restoreFilesAndFolders(newFolderId?: number, filesAndFoldersModel?: FilesAndFoldersModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (newFolderId !== undefined && newFolderId !== null) {
            queryParameters = queryParameters.set('newFolderId', <any>newFolderId);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<FilesAndFoldersModel>(`${this.configuration.basePath}/content/restore-files-and-folders`,
            filesAndFoldersModel,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * send an email with a link to download folder as zip
     * - create a temporary zip archive with all contained sub folders and files  - store in table temp_fichiers (new table)   - id &#x3D; guid (autogenerated)   - id_utilisateur &#x3D; current utilisateur.id   - location &#x3D; full filename path   - expiration &#x3D; now() + email_link_expiration_heures ( email_link_expiration_heures &#x3D; parameter from config ) - generate a link based on {server}/content/download-link/{id} where {id} &#x3D; temp_fichiers.id - send link by email to users\&#39;s email (utilisateur.identifiant) 
     * @param id id of folder to read
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendFolderByEmail(id: number, observe?: 'body', reportProgress?: boolean): Observable<object>;
    public sendFolderByEmail(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<object>>;
    public sendFolderByEmail(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<object>>;
    public sendFolderByEmail(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling sendFolderByEmail.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<object>(`${this.configuration.basePath}/content/send-folder-by-email/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * sends a link to a shared file by email
     * 
     * @param id id of file to share
     * @param duration duration of share in days
     * @param password share\&#39;s password
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shareFileByEmail(id: number, duration?: number, password?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public shareFileByEmail(id: number, duration?: number, password?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public shareFileByEmail(id: number, duration?: number, password?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public shareFileByEmail(id: number, duration?: number, password?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling shareFileByEmail.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (duration !== undefined && duration !== null) {
            queryParameters = queryParameters.set('duration', <any>duration);
        }
        if (password !== undefined && password !== null) {
            queryParameters = queryParameters.set('password', <any>password);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.configuration.basePath}/content/share-file-by-email/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns a link to a shared file
     * 
     * @param id id of file to share
     * @param duration duration of share in days
     * @param password share\&#39;s password
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shareFileByLink(id: number, duration?: number, password?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public shareFileByLink(id: number, duration?: number, password?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public shareFileByLink(id: number, duration?: number, password?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public shareFileByLink(id: number, duration?: number, password?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling shareFileByLink.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (duration !== undefined && duration !== null) {
            queryParameters = queryParameters.set('duration', <any>duration);
        }
        if (password !== undefined && password !== null) {
            queryParameters = queryParameters.set('password', <any>password);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.configuration.basePath}/content/share-file-by-link/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * sends a link to a shared folder by email
     * - save the share in table archives_partages    - password is stored as salt(10)+MD5(salt+password) where salt is random string (10)  - returns an formated email body with link formated as: {msmsite}/shared-folder/{archives_partages.id} 
     * @param id id of folder to share
     * @param duration duration of share in days
     * @param password share\&#39;s password
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shareFolderByEmail(id: number, duration?: number, password?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public shareFolderByEmail(id: number, duration?: number, password?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public shareFolderByEmail(id: number, duration?: number, password?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public shareFolderByEmail(id: number, duration?: number, password?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling shareFolderByEmail.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (duration !== undefined && duration !== null) {
            queryParameters = queryParameters.set('duration', <any>duration);
        }
        if (password !== undefined && password !== null) {
            queryParameters = queryParameters.set('password', <any>password);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.configuration.basePath}/content/share-folder-by-email/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns a link a sharefd folder
     * - save the share in table archives_partages   - password is stored as salt(10)+MD5(salt+password) where salt is random string (10) - returns the link formated as: {msmsite}/shared-folder/{archives_partages.id} 
     * @param id id of folder to share
     * @param duration duration of share in days
     * @param password share\&#39;s password
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shareFolderByLink(id: number, duration?: number, password?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public shareFolderByLink(id: number, duration?: number, password?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public shareFolderByLink(id: number, duration?: number, password?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public shareFolderByLink(id: number, duration?: number, password?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling shareFolderByLink.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (duration !== undefined && duration !== null) {
            queryParameters = queryParameters.set('duration', <any>duration);
        }
        if (password !== undefined && password !== null) {
            queryParameters = queryParameters.set('password', <any>password);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.configuration.basePath}/content/share-folder-by-link/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    

    /**
     * returns archive attestation
     * TODO 
     * @param id id of file to read
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
     public archiveAttestation(id: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
     public archiveAttestation(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
     public archiveAttestation(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
     public archiveAttestation(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
         if (id === null || id === undefined) {
             throw new Error('Required parameter id was null or undefined when calling downloadFile.');
         }
 
         let headers = this.defaultHeaders;
 
         // authentication (bearerAuth) required
         if (this.configuration.accessToken) {
             const accessToken = typeof this.configuration.accessToken === 'function'
                 ? this.configuration.accessToken()
                 : this.configuration.accessToken;
             headers = headers.set('Authorization', 'Bearer ' + accessToken);
         }
         // authentication (shareKeyAuth) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["X-SHARE-KEY"]) {
             headers = headers.set('X-SHARE-KEY', this.configuration.apiKeys["X-SHARE-KEY"]);
         }
 
         // to determine the Accept header
         const httpHeaderAccepts: string[] = [
             'application/octet-stream'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected !== undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.get(`${this.configuration.basePath}/content/archive-attestation/${encodeURIComponent(String(id))}`,
             {
                 responseType: "blob",
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }


     

    /**
     * returns controle attestation
     * TODO 
     * @param id id of file to read
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public controleAttestation(id: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public controleAttestation(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public controleAttestation(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public controleAttestation(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling downloadFile.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // authentication (shareKeyAuth) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["X-SHARE-KEY"]) {
            headers = headers.set('X-SHARE-KEY', this.configuration.apiKeys["X-SHARE-KEY"]);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.configuration.basePath}/content/controle-attestation/${encodeURIComponent(String(id))}`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
