import { Injectable } from '@angular/core';
import { PartnerConfig, PartnerService } from 'src/generated/api/stockmemoriss';

@Injectable()
export class PartnerProvider {

    private partnerConfig: PartnerConfig = null;

    constructor(private partnerService: PartnerService) {

    }

    public getPartnerConfig(): PartnerConfig {
        return this.partnerConfig;
    }

    load() {
        return new Promise((resolve, reject) => {
            this.partnerService.getPartnerConfig().subscribe(response => {
                this.partnerConfig = response;
                resolve(true);
            });
        });
    }
}
