import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { TcDumbComponent, TcMenuComponent, TcMenuItem } from '@tc/core';
import { Box } from 'src/generated/api/stockmemoriss';

@Component({
  selector: 'msm-box-item',
  templateUrl: './msm-box-item.component.html',
  styleUrls: ['./msm-box-item.component.scss']
})
export class MsmBoxItemComponent extends TcDumbComponent implements OnInit {

  @Input()
  imageWidth: string;

  @Input()
  textWidth: string;

  @Input()
  boxColor: string;

  @Input()
  boxIcon: string;

  @Input()
  boxName: string;

  @Input()
  isBin: boolean;

  @Input()
  box: Box;

  @Input()
  menuItems: TcMenuItem[] = [];

  @Output()
  itemSelect: EventEmitter<Box> = new EventEmitter();

  @Input()
  hasMenu = true;

  @Output()
  menuBox: EventEmitter<any> = new EventEmitter();

  boxMenu: TcMenuComponent;
  @ViewChild('boxMenu', { static: true }) set appBoxMenu(value: TcMenuComponent) {
    this.boxMenu = value;
  }
  constructor() {
    super();
  }

  ngOnInit() {

    this.boxMenu.items = this.menuItems;

    this.boxMenu.onMenuItemClick = (item: TcMenuItem, row: Box) => {
      this.menuBox.emit({ item, row });
    };
  }

  onItemSelect(box: Box) {
    if (box) {
      this.itemSelect.emit(box);
    }
  }

}
