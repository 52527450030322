
import { CertificateActions, CertificateActionTypes } from '../actions/certificate.actions';
import { initialContentState, ContentState } from '../content.state';

export function certificateReducer(
    state = initialContentState,
    action: CertificateActions
): ContentState {
    switch (action.type) {
        case CertificateActionTypes.SELECT_FILES_FOR_CERTIFICATE: {

            const selectFoldersAndFiles = state.selectFoldersAndFiles;

            selectFoldersAndFiles.selectedFolderId = null;
            selectFoldersAndFiles.folders = null;
            selectFoldersAndFiles.files = null;
            selectFoldersAndFiles.navigation = null;
            selectFoldersAndFiles.selectedRows = [];

            return {
                ...state,
                folders: [],
                files: [],
                selectedBox: null,
                selectedFolder: null,
                selectedFolderId: null,
                selectFoldersAndFiles
            };
        }

        case CertificateActionTypes.SELECT_FILES_FOR_CERTIFICATE_DONE: {

            return {
                ...state,
                filesForCertificate: state.filesForCertificate.concat([...state.selectFoldersAndFiles.selectedRows])
            };
        }

        case CertificateActionTypes.DELETE_FILE_FROM_CERTIFICATE_FILES: {

            return {
                ...state,
                filesForCertificate: state.filesForCertificate.filter(c => c.id !== action.payload.id)
            };
        }
        default: {
            return state;
        }
    }
}

