export type FileExtension = 'any' | 'pdf' | 'doc' | 'jpg' | 'png' | 'html' | 'xls';

export const FileExtension = {
    any: 'any' as FileExtension,
    pdf: 'pdf' as FileExtension,
    doc: 'doc' as FileExtension,
    jpg: 'jpg' as FileExtension,
    png: 'png' as FileExtension,
    html: 'html' as FileExtension,
    xls: 'xls' as FileExtension,
};
