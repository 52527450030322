import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/modules/core/store/app.state';
import { ContentService } from 'src/generated/api/stockmemoriss';
import { ExpiredFilesService } from 'src/app/services/expired-files.service';
import { Observable } from 'rxjs';
import { TcAction } from '@tc/core';
import {
    LoadExpiredFiles, LoadExpiredFilesSuccess, ExpiredFilesActionTypes,
    DeleteSelectedExpiredFiles, ExtendsSelectedExpiredFiles,
    RequestExtendExpirePeriodConfirmationSuccess, RequestExtendExpirePeriodConfirmation, ExtendExpirePeriod, ExtendExpirePeriodSuccess
} from '../actions/expired-files.actions';
import { mergeMap, map, withLatestFrom, switchMap, finalize } from 'rxjs/operators';
import { getSelectedRows } from '../selectors/content.selectors';

@Injectable()
export class ExpiredFilesEffects {
    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private contentService: ContentService,
        private expiredFilesService: ExpiredFilesService
    ) { }

    /**
     * Effect for load expired files
     */
    @Effect()
    loadExpiredFiles$: Observable<TcAction> = this.actions$.pipe(
        ofType<LoadExpiredFiles>(
            ExpiredFilesActionTypes.LOAD_EXPIRED_FILES
        ),
        mergeMap((action: LoadExpiredFiles) =>
            this.contentService.getExpiredFiles().pipe(
                map(
                    (items: any) =>
                        new LoadExpiredFilesSuccess(items)
                )
            )
        )
    );

    @Effect({ dispatch: false })
    deleteSelectedExpiredFiles = this.actions$.pipe(
        ofType<DeleteSelectedExpiredFiles>(ExpiredFilesActionTypes.DELETE_SELECTED_EXPIRED_FILES),
        withLatestFrom(this.store.pipe(select(getSelectedRows))),
        map(([action, selectedRows]) => {
            this.expiredFilesService.deleteSelectedExpiredFiles(selectedRows);
        })
    );

    @Effect({ dispatch: false })
    extendSelectedExpiredFiles = this.actions$.pipe(
        ofType<ExtendsSelectedExpiredFiles>(ExpiredFilesActionTypes.EXTEND_SELECTED_EXPIRED_FILES),
        withLatestFrom(this.store.pipe(select(getSelectedRows))),
        map(([action, selectedRows]) => {
            this.expiredFilesService.extendSelectedExpiredFiles(selectedRows);
        })
    );

    @Effect()
    requestExtendExpirePeriodConfirmation = this.actions$.pipe(
        ofType<RequestExtendExpirePeriodConfirmation>(ExpiredFilesActionTypes.REQUEST_EXTEND_EXPIRE_PERIOD_CONFIRMATION),
        withLatestFrom(this.store.pipe(select(getSelectedRows))),
        mergeMap(([action, selectedRows]) =>
            this.contentService.requestProlongation(action.payload).pipe(
                map(() =>
                    new RequestExtendExpirePeriodConfirmationSuccess({ selectedRows })
                )
            )
        )
    );

    @Effect({ dispatch: false })
    requestExtendExpirePeriodConfirmationSuccess = this.actions$.pipe(
        ofType<RequestExtendExpirePeriodConfirmationSuccess>(ExpiredFilesActionTypes.REQUEST_EXTEND_EXPIRE_PERIOD_CONFIRMATION_SUCCESS),
        map(() => {
            this.expiredFilesService.requestExtendExpirePeriodConfirmationSuccess();
        })
    );

    @Effect()
    extendExpirePeriod = this.actions$.pipe(
        ofType<ExtendExpirePeriod>(ExpiredFilesActionTypes.EXTEND_EXPIRE_PERIOD),
        switchMap((action: ExtendExpirePeriod) => {
            let error = true;
            return this.contentService.prolongation(action.payload).pipe(
                map((items: number) => {
                    error = false;
                    return new ExtendExpirePeriodSuccess(items);
                }),
                finalize(() => {
                    if (error) {
                        this.expiredFilesService.extendExpirePeriodError();
                    }
                })
            );
        })
    );

    @Effect({ dispatch: false })
    extendExpirePeriodSuccess = this.actions$.pipe(
        ofType<ExtendExpirePeriodSuccess>(ExpiredFilesActionTypes.EXTEND_EXPIRE_PERIOD_SUCCESS),
        map((action: ExtendExpirePeriodSuccess) => {
            this.expiredFilesService.extendExpirePeriodSuccess(action.payload);
        })
    );


}
