import { TcService } from '@tc/core';
const { version } = require('../../../package.json');

export class VersionService extends TcService {

  constructor() {
    super();
  }

  public getVersion(): string {

    return version;
  }

}
