import { Component, OnInit } from '@angular/core';
import { TcDumbComponent } from '@tc/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'support-confirmation',
  templateUrl: './support-confirmation.component.html',
  styleUrls: ['./support-confirmation.component.scss']
})
export class SupportConfirmationComponent extends TcDumbComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<SupportConfirmationComponent>) {
    super();
  }
  ngOnInit() {
  }

  onClose() {
    this.dialogRef.close();
  }

}

