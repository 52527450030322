import { TcAction } from '@tc/core';
import { Folder, Box, AbstractFile } from 'src/generated/api/stockmemoriss';

export enum SelectFoldersAndFilesActionTypes {

    SELECT_FOLDERS_AND_FILES_LOAD_BOXES = '[Content - Select Folders And Files] Load Boxes',
    SELECT_FOLDERS_AND_FILES_LOAD_BOXES_SUCCESS = '[Content - Select Folders And Files] Load Boxes Success',

    SELECT_FOLDERS_AND_FILES_LOAD_FOLDER_CONTENT = '[Content - Select Folders And Files] Load Folder Content',
    SELECT_FOLDERS_AND_FILES_LOAD_FOLDER_CONTENT_SUCCESS = '[Content - Select Folders And Files] Load Folder Content Success',

    SELECT_FOLDERS_AND_FILES_SELECT_FOLDER = '[Content - Select Folders And Files] Select Folder',

    SELECT_FOLDERS_AND_FILES_SELECT_ROWS = '[Content - Select Folders And Files] Select Rows',
    SELECT_FOLDERS_AND_FILES_DESELECT_ROWS = '[Content - Select Folders And Files] Deselect Rows',
}

export class SelectFoldersAndFilesLoadBoxes implements TcAction {
    readonly type = SelectFoldersAndFilesActionTypes.SELECT_FOLDERS_AND_FILES_LOAD_BOXES;
}

export class SelectFoldersAndFilesLoadBoxesSuccess implements TcAction {
    readonly type = SelectFoldersAndFilesActionTypes.SELECT_FOLDERS_AND_FILES_LOAD_BOXES_SUCCESS;
    constructor(public payload: Box[]) { }
}

export class SelectFoldersAndFilesLoadFolderContent implements TcAction {
    readonly type = SelectFoldersAndFilesActionTypes.SELECT_FOLDERS_AND_FILES_LOAD_FOLDER_CONTENT;
    constructor(public payload: number) { }
}

export class SelectFoldersAndFilesLoadFolderContentSuccess implements TcAction {
    readonly type = SelectFoldersAndFilesActionTypes.SELECT_FOLDERS_AND_FILES_LOAD_FOLDER_CONTENT_SUCCESS;
    constructor(public payload: Folder) { }
}

export class SelectFoldersAndFilesSelectFolder implements TcAction {
    readonly type = SelectFoldersAndFilesActionTypes.SELECT_FOLDERS_AND_FILES_SELECT_FOLDER;
    constructor(public payload: number) { }
}

export class SelectFoldersAndFilesSelectRows implements TcAction {
    readonly type = SelectFoldersAndFilesActionTypes.SELECT_FOLDERS_AND_FILES_SELECT_ROWS;
    constructor(public payload: AbstractFile[]) { }
}

export class SelectFoldersAndFilesDeselectRows implements TcAction {
    readonly type = SelectFoldersAndFilesActionTypes.SELECT_FOLDERS_AND_FILES_DESELECT_ROWS;
    constructor(public payload: AbstractFile[]) { }
}

export type SelectFoldersAndFilesActions =
    | SelectFoldersAndFilesLoadBoxes
    | SelectFoldersAndFilesLoadBoxesSuccess
    | SelectFoldersAndFilesLoadFolderContent
    | SelectFoldersAndFilesLoadFolderContentSuccess
    | SelectFoldersAndFilesSelectFolder
    | SelectFoldersAndFilesSelectRows
    | SelectFoldersAndFilesDeselectRows;
