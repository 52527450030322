import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AccountState } from './account.reducer';

const getAccountState = createFeatureSelector<AccountState>(
    'account'
);
export const getAccountUser = createSelector(
    getAccountState,
    (state: AccountState) => state.user
);

export const getAccountHeir = createSelector(
    getAccountState,
    (state: AccountState) => state.heir
);
