import { Component, OnInit, OnDestroy } from '@angular/core';
import { TcSmartComponent } from '@tc/core';
import { MatDialog } from '@angular/material';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { Box, ContentService } from 'src/generated/api/stockmemoriss';
import { getSelectedBox } from '../../../store/selectors/box.selectors';
import { Utils } from 'src/app/modules/core/utils';
import { ContentState } from '../../../store/content.state';

@Component({
  selector: 'box-main-address',
  templateUrl: './box-main-address.component.html',
  styleUrls: ['./box-main-address.component.scss']
})
export class BoxMainAddressComponent extends TcSmartComponent implements OnInit, OnDestroy {

  temporaryEmail = '';

  selectedBox$: Observable<Box>;
  selectedBox: Box;
  selectedBoxSubscription: Subscription;
  temporaryEmailSubscription: Subscription;

  constructor(
    private contentService: ContentService,
    private store: Store<ContentState>
  ) {
    super();
  }

  ngOnInit() {

    this.temporaryEmail = '';

    this.selectedBox$ = this.store.pipe(select(getSelectedBox));
    this.selectedBoxSubscription = this.store.pipe(select(getSelectedBox)).subscribe(box => {
      if (box) {
        this.selectedBox = box;
      }
    });

  }

  generate() {
    if (this.selectedBox) {
      this.temporaryEmailSubscription = this.contentService.generateTemporaryEmail(this.selectedBox.id).subscribe(result => {
        this.temporaryEmail = result.email;
        Utils.copyToClipboard(result.email);
      });
    }
  }

  ngOnDestroy() {
    if (this.selectedBoxSubscription) {
      this.selectedBoxSubscription.unsubscribe();
    }

    if (this.temporaryEmailSubscription) {
      this.temporaryEmailSubscription.unsubscribe();
    }
  }


}


