import { TcAction } from '@tc/core';
import { Journal } from 'src/generated/api/stockmemoriss';

export enum JournalActionTypes {
    LOAD_JOURNAL = '[Journal] Load Journal',
    LOAD_JOURNAL_SUCCESS = '[Journal] Load Journal Success',
    LOAD_MORE_JOURNAL_DATA = '[Journal] Load More Journal Data',
    LOAD_MORE_JOURNAL_DATA_SUCCESS = '[Journal] Load More Journal Data Success',
}

export class LoadJournal implements TcAction {
    readonly type = JournalActionTypes.LOAD_JOURNAL;
    constructor(public payload: { folderId: number, fileId: number }) { }
}

export class LoadJournalSuccess implements TcAction {
    readonly type = JournalActionTypes.LOAD_JOURNAL_SUCCESS;
    constructor(public payload: { folderId: number, fileId: number, items: Journal[] }) { }
}

export class LoadMoreJournalData implements TcAction {
    readonly type = JournalActionTypes.LOAD_MORE_JOURNAL_DATA;
}

export class LoadMoreJournalDataSuccess implements TcAction {
    readonly type = JournalActionTypes.LOAD_MORE_JOURNAL_DATA_SUCCESS;
    constructor(public payload: { items: Journal[] }) { }
}

export type JournalActions =
    LoadJournal
    | LoadJournalSuccess
    | LoadMoreJournalData
    | LoadMoreJournalDataSuccess;
