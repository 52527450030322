import { TcAction } from '@tc/core';
import { SimpleFile, Share } from 'src/generated/api/stockmemoriss';

export enum ShareActionTypes {

    // OPEN SHARED FOLDER OR FILE
    OPEN_SHARED_FOLDER_OR_FILE = '[Content] Open Shared Folder Or File',

    // OPEN SHARED FILE
    OPEN_SHARED_FILE = '[Content] Open Shared File',


    // OPEN SHARED FOLDER
    OPEN_SHARED_FOLDER = '[Content] Open Shared Folder'
}

export class OpenSharedFolderOrFile implements TcAction {
    readonly type = ShareActionTypes.OPEN_SHARED_FOLDER_OR_FILE;
    constructor(public payload: { sharedUrl: string, sharedId: string }) { }
}


export class OpenSharedFile implements TcAction {
    readonly type = ShareActionTypes.OPEN_SHARED_FILE;
    constructor(public payload: { file: SimpleFile, share: Share, sharedUrl: string, sharedPassword?: string }) { }
}


export class OpenSharedFolder implements TcAction {
    readonly type = ShareActionTypes.OPEN_SHARED_FOLDER;
    constructor(public payload: { share: Share, sharedUrl: string, sharedPassword?: string }) { }
}

export type ShareActions =
    | OpenSharedFolderOrFile
    | OpenSharedFile
    | OpenSharedFolder;
