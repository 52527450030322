import { Component, OnInit, OnDestroy } from '@angular/core';
import { TcPage, TcAuthenticationService, Logout, TcNotificationService, TcTranslateService } from '@tc/core';
import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/modules/core/store/app.state';
import { getSingleDisplay } from 'src/app/modules/content/store/selectors/box.selectors';
import { getAccountUser } from 'src/app/modules/account/store/account.selectors';
import { User } from 'src/generated/api/stockmemoriss';
import { RouteOptions } from 'src/app/modules/core/enums/route-options';
import { RouterService } from 'src/app/services/router.service';

@Component({
  selector: 'F03-home-page',
  templateUrl: './F03-home-page.component.html',
  styleUrls: ['./F03-home-page.component.scss']
})
export class F03HomePageComponent extends TcPage implements OnInit, OnDestroy {

  isAuthenticated = false;

  singleDisplay$: Observable<boolean>;

  authUserSubscription: Subscription;

  constructor(
    private store: Store<AppState>,
    private authService: TcAuthenticationService,
    private routerService: RouterService,
    private notificationService: TcNotificationService,
    private translateService: TcTranslateService) {
    super();
  }

  ngOnInit() {

    this.singleDisplay$ = this.store.pipe(select(getSingleDisplay));

    this.authUserSubscription = this.store.pipe(select(getAccountUser)).subscribe(user => {

      const authUserId = this.authService.getAuthUserId();

      if (user && user.id + '' === authUserId) {

        if (user.role === User.RoleEnum.Lecteur) {
          this.notificationService.error(this.translateService.instant('account.lecteur-not-allowed'));
          this.store.dispatch(new Logout());
        } else {

          this.isAuthenticated = true;

          if (!user.emailConfirmation) {
            this.notificationService.error(this.translateService.instant('account.invalid-email'));
            this.store.dispatch(new Logout());
          } else if (!user.dateAcceptationCGU) {
            this.routerService.goToPage(RouteOptions.CGUAccept);
          }

        }

      }

    });


  }

  ngOnDestroy() {
    if (this.authUserSubscription) {
      this.authUserSubscription.unsubscribe();
    }
  }

}
