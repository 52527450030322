import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppState } from 'src/app/modules/core/store/app.state';
import { Store } from '@ngrx/store';
import { MoveOrphans } from '../../../store/actions/move.actions';
import { AbstractFile } from 'src/generated/api/stockmemoriss';
import { TcSmartComponent } from '@tc/core';

@Component({
  selector: 'restore-dialog',
  templateUrl: './restore-dialog.component.html',
  styleUrls: ['./restore-dialog.component.scss']
})
export class RestoreDialogComponent extends TcSmartComponent implements OnInit {

  orphans: AbstractFile[];
  items: string[];

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<RestoreDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super();
  }

  ngOnInit() {
    if (this.data) {
      this.orphans = this.data;
      this.items = this.orphans.map(i => i.name);
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  onOk() {
    this.store.dispatch(new MoveOrphans(this.orphans));
    this.dialogRef.close();
  }
}
