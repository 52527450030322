import { Component, OnInit } from '@angular/core';
import { TcDumbComponent } from '@tc/core';
import { ActivatedRoute } from '@angular/router';
import { Utils } from 'src/app/modules/core/utils';

@Component({
  selector: 'account-header',
  templateUrl: './account-header.component.html',
  styleUrls: ['./account-header.component.css']
})
// todo: move to smart component
export class AccountHeaderComponent extends TcDumbComponent implements OnInit {

  isSharedMode = false;

  constructor(private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    const returnUrl = this.route.snapshot.queryParams.returnUrl;

    // if url contains returnUrl => check if the return url contains share-file or share-folder
    if (returnUrl) {
      // if returnUrl contains shared-file or shared-folder => when create a new user, the user should be of type lecture
      if (Utils.isSharedUrl(returnUrl)) {
        this.isSharedMode = true;
      }
    }
  }

}
