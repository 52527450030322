import { TcAction } from '@tc/core';
import { Box } from 'src/generated/api/stockmemoriss';

export enum BoxActionTypes {

    // BOXES ACTIONS
    LOAD_BOXES = '[Content] Load Boxes',
    LOAD_BOXES_SUCCESS = '[Content] Load Boxes Success',

    // BOX ACTIONS
    ADD_BOX = '[Content] Add Box',
    ADD_BOX_SUCCESS = '[Content] Add Box Success',
    EDIT_BOX = '[Content] Edit Box',
    EDIT_BOX_SUCCESS = '[Content] Edit Box Success',
    DELETE_BOX = '[Content] Delete Box',
    DELETE_BOX_SUCCESS = '[Content] Delete Box Success',

    CHANGE_BOX_EMAIL_ADDRESS = '[Content] Change Box Email Address',
    SELECT_BOX = '[Content] Select Box',

    // SINGLE DISPLAY
    SET_SINGLE_DISPLAY = '[Content] Set Single Display',

}

export class SetSingleDisplay implements TcAction {
    readonly type = BoxActionTypes.SET_SINGLE_DISPLAY;
    constructor(public payload: boolean) { }
}

export class LoadBoxes implements TcAction {
    readonly type = BoxActionTypes.LOAD_BOXES;
}

export class LoadBoxesSuccess implements TcAction {
    readonly type = BoxActionTypes.LOAD_BOXES_SUCCESS;
    constructor(public payload: Box[]) { }
}

export class AddBox implements TcAction {
    readonly type = BoxActionTypes.ADD_BOX;
    constructor(public payload: Box) { }
}

export class AddBoxSuccess implements TcAction {
    readonly type = BoxActionTypes.ADD_BOX_SUCCESS;
    constructor(public payload: Box) { }
}

export class EditBox implements TcAction {
    readonly type = BoxActionTypes.EDIT_BOX;
    constructor(public payload: Box) { }
}

export class EditBoxSuccess implements TcAction {
    readonly type = BoxActionTypes.EDIT_BOX_SUCCESS;
    constructor(public payload: Box) { }
}

export class DeleteBox implements TcAction {
    readonly type = BoxActionTypes.DELETE_BOX;
    constructor(public payload: number) { }
}

export class DeleteBoxSuccess implements TcAction {
    readonly type = BoxActionTypes.DELETE_BOX_SUCCESS;
    constructor(public payload: number) { }
}

export class SelectBox implements TcAction {
    readonly type = BoxActionTypes.SELECT_BOX;
    constructor(public payload: Box) { }
}

export class ChangeBoxEmailAddress implements TcAction {
    readonly type = BoxActionTypes.CHANGE_BOX_EMAIL_ADDRESS;
    constructor(public payload: Box) { }
}

export type BoxActions =
    | SetSingleDisplay
    | LoadBoxes
    | LoadBoxesSuccess
    | AddBox
    | AddBoxSuccess
    | EditBox
    | EditBoxSuccess
    | DeleteBox
    | DeleteBoxSuccess
    | SelectBox
    | ChangeBoxEmailAddress;
