import { Component, OnInit, ElementRef, Optional, Inject, OnDestroy } from '@angular/core';
import { TcFormComponent, TcTranslateService, TcNotificationService } from '@tc/core';
import { User } from 'src/generated/api/stockmemoriss';
import { Store, select } from '@ngrx/store';
import { AccountState } from 'src/app/modules/account/store/account.reducer';
import { Router } from '@angular/router';
import { EditUser } from 'src/app/modules/account/store/account.actions';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Observable, Subscription } from 'rxjs';
import { getAccountUser } from 'src/app/modules/account/store/account.selectors';

@Component({
  selector: 'password-restitution',
  templateUrl: './password-restitution.component.html',
  styleUrls: ['./password-restitution.component.scss']
})
// pop-up component (EDIT Restitution password)
export class PasswordRestitutionComponent extends TcFormComponent<User> implements OnInit, OnDestroy {

  user: User;
  user$: Observable<User>;
  userSubscription: Subscription;

  constructor(
    private store: Store<AccountState>,
    public translateService: TcTranslateService,
    private notificationService: TcNotificationService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: User,
    public dialogRef: MatDialogRef<PasswordRestitutionComponent>,
    element: ElementRef) {

    super(translateService, element);

    this.fields = [
      {
        key: 'passwordRestitution',
        type: 'input',
        templateOptions: {
          type: 'password',
          required: true,
        }
      },
      {
        key: 'confirmPasswordRestitution',
        type: 'input',
        templateOptions: {
          type: 'password',
          required: true,
        },
        validators: {
          fieldMatch: {
            expression: (control) => control.value === this.model.passwordRestitution,
            message: this.translate.instant('password-restitution.validations.confirmPasswordMessage')
          },
        }
      },
    ];
  }
  ngOnInit() {
    this.userSubscription = this.store.pipe(select(getAccountUser)).subscribe(user => {
      if (user) {
        this.user = user;
      }
    });
    if (this.data) {
      this.model = Object.assign({}, this.data);
    }
    super.ngOnInit();
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  submit(model: User) {
    if (this.form.valid && this.validatePasswordRestitution(model.passwordRestitution)) {
      delete (model as any).confirmPasswordRestitution;
      this.store.dispatch(new EditUser({ user: model, actionType: 'password-restitution' }));
      this.dialogRef.close(model);
    }

  }
  // restitution password must contains 8 words of minim 5 charactes
  validatePasswordRestitution(password: string): boolean {

    let ok = true;

    const wordList = password.split(' ');
    if (wordList.length < 8) {
      ok = false;
    }

    for (const word of wordList) {
      if (word.length < 5) {
        ok = false;
      }
    }

    if (!ok) {
      this.notificationService.error(this.translateService.instant('password-restitution.validations.error'));
      return false;
    }

    return ok;
  }

  onClose() {
    this.dialogRef.close();
  }

}
