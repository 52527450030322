export * from './account.service';
import { AccountService } from './account.service';
export * from './coaching.service';
import { CoachingService } from './coaching.service';
export * from './contact.service';
import { ContactService } from './contact.service';
export * from './content.service';
import { ContentService } from './content.service';
export * from './faq.service';
import { FaqService } from './faq.service';
export * from './partner.service';
import { PartnerService } from './partner.service';
export const APIS = [AccountService, CoachingService, ContactService, ContentService, FaqService, PartnerService];
