import { TcAction } from '@tc/core';
import { Folder, AbstractFile, FilesAndFoldersModel } from 'src/generated/api/stockmemoriss';

export enum MoveActionTypes {

    // MOVE ENTITY
    MOVE_ENTITY = '[Content] Move Entity',

    // LOAD FOLDERS FOR MOVING
    LOAD_FOLDERS_FOR_MOVING = '[Content] Load Folders For Moving',
    LOAD_FOLDERS_FOR_MOVING_SUCCESS = '[Content] Load Folders For Moving Success',

    // LOAD MOVE BOXES
    LOAD_BOXES_FOR_MOVING = '[Content] Load Boxes For Moving',

    // MOVE ENTITY DONE
    MOVE_ENTITY_DONE = '[Content] Move Entity Done',

    // MOVE CANCEL
    MOVE_CANCEL = '[Content] Move Cancel',

    // MOVE ENTITY
    MOVE_ORPHANS = '[Content] Move Orphans',

    // MOVE Orphans DONE
    MOVE_ORPHANS_DONE = '[Content] Move Orphans Done',
}

export class MoveEntity implements TcAction {
    readonly type = MoveActionTypes.MOVE_ENTITY;
    constructor(public payload: AbstractFile) { }
}

export class LoadFoldersForMoving implements TcAction {
    readonly type = MoveActionTypes.LOAD_FOLDERS_FOR_MOVING;
    constructor(public payload: number) { }
}

export class LoadFoldersForMovingSuccess implements TcAction {
    readonly type = MoveActionTypes.LOAD_FOLDERS_FOR_MOVING_SUCCESS;
    constructor(public payload: Folder) { }
}

export class LoadBoxesForMoving implements TcAction {
    readonly type = MoveActionTypes.LOAD_BOXES_FOR_MOVING;
    constructor() { }
}

export class MoveEntityDone implements TcAction {
    readonly type = MoveActionTypes.MOVE_ENTITY_DONE;
    constructor(public payload: Folder) { }
}

export class MoveCancel implements TcAction {
    readonly type = MoveActionTypes.MOVE_CANCEL;
    constructor() { }
}

export class MoveOrphans implements TcAction {
    readonly type = MoveActionTypes.MOVE_ORPHANS;
    constructor(public payload: AbstractFile[]) { }
}

export class MoveOrphansDone implements TcAction {
    readonly type = MoveActionTypes.MOVE_ORPHANS_DONE;
    constructor(public payload: { newParentId: number, model: FilesAndFoldersModel }) { }
}

export type MoveActions =
    | MoveEntity
    | LoadFoldersForMoving
    | LoadFoldersForMovingSuccess
    | LoadBoxesForMoving
    | MoveEntityDone
    | MoveCancel
    | MoveOrphans
    | MoveOrphansDone;
