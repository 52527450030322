import { createSelector, createFeatureSelector } from '@ngrx/store';
import { ContentState } from '../content.state';

const getContentState = createFeatureSelector<ContentState>(
    'content'
);

export const getFiles = createSelector(
    getContentState,
    (state: ContentState) => state.files.filter(f => f.parentId === state.selectedFolderId)
);

export const getSearchText = createSelector(
    getContentState,
    (state: ContentState) => state.searchText
);

export const getSearchResults = createSelector(
    getContentState,
    (state: ContentState) => state.searchFiles
);

export const getSelectedFile = createSelector(
    getContentState,
    (state: ContentState) => state.selectedFile
);


