import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/modules/core/store/app.state';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
    CertificateActionTypes,
    SelectFilesForCertificate,
    SelectFilesForCertificateCancel,
    SelectFilesForCertificateDone,
    PayCertificate,
    GenerateCertificate,
    GenerateCertificateSuccess
} from '../actions/certificate.actions';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { CertificateService } from 'src/app/services/certificate.service';
import { Observable } from 'rxjs';
import { TcAction } from '@tc/core';
import { ContentService } from 'src/generated/api/stockmemoriss';
import { getFilesForCertificate } from '../selectors/content.selectors';

@Injectable()
export class CertificateEffects {
    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private contentService: ContentService,
        private certificateService: CertificateService
    ) { }

    @Effect({ dispatch: false })
    selectFilesForCertificate = this.actions$.pipe(
        ofType<SelectFilesForCertificate>(CertificateActionTypes.SELECT_FILES_FOR_CERTIFICATE),
        map((action: SelectFilesForCertificate) => {
            this.certificateService.selectFilesForCertificate();
        })
    );

    @Effect({ dispatch: false })
    selectFilesForCertificateCancel = this.actions$.pipe(
        ofType<SelectFilesForCertificateCancel>(CertificateActionTypes.SELECT_FILES_FOR_CERTIFICATE_CANCEL),
        map((action: SelectFilesForCertificateCancel) => {
            this.certificateService.selectFilesForCertificateCancel();
        })
    );

    @Effect({ dispatch: false })
    selectFilesForCertificateDone = this.actions$.pipe(
        ofType<SelectFilesForCertificateDone>(CertificateActionTypes.SELECT_FILES_FOR_CERTIFICATE_DONE),
        map((action: SelectFilesForCertificateDone) => {
            this.certificateService.selectFilesForCertificateDone();
        })
    );

    @Effect({ dispatch: false })
    payCertificate = this.actions$.pipe(
        ofType<PayCertificate>(CertificateActionTypes.PAY_CERTIFICATE),
        map((action: PayCertificate) => {
            this.certificateService.payCertificate();
        })
    );

    @Effect()
    generateCertificate$: Observable<TcAction> = this.actions$.pipe(
        ofType<GenerateCertificate>(
            CertificateActionTypes.GENERATE_CERTIFICATE
        ),
        withLatestFrom(this.store.pipe(select(getFilesForCertificate))),
        mergeMap(([action, filesForCertificate]) =>
            this.contentService.postCertificates({ files: filesForCertificate.map(c => c.id) }).pipe(
                map(
                    (result: any) =>
                        new GenerateCertificateSuccess()
                )
            )
        )
    );

    @Effect({ dispatch: false })
    generateCertificateSuccess = this.actions$.pipe(
        ofType<GenerateCertificateSuccess>(CertificateActionTypes.GENERATE_CERTIFICATE_SUCCESS),
        map((action: GenerateCertificateSuccess) => {
            this.certificateService.generateCertificateSucces();
        })
    );
}
