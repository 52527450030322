import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/modules/core/store/app.state';
import { Message } from 'src/generated/api/stockmemoriss/model/message';
import { SendContactSupportEmail, SendContactSuggestionEmail } from 'src/app/modules/contact/store/contact.actions';
import { TcSmartComponent } from '@tc/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent extends TcSmartComponent implements OnInit {

  @Input() screen: string;

  message: string;

  constructor(
    private store: Store<AppState>,
  ) {
    super();
  }

  ngOnInit() {
  }

  clear() {
    this.message = '';
  }
  submit(message: string) {
    const model: Message = {
      text: message,
      logiciel: 'My StockMemoriss-'+environment.version,
    };
    if (this.screen === 'support') {
      this.store.dispatch(new SendContactSupportEmail(model));
    } else if (this.screen === 'team') {
      this.store.dispatch(new SendContactSuggestionEmail(model));
    }
    this.message = '';

  }

}
