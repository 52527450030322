import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { TcDumbComponent } from '@tc/core';
import { RouterService } from 'src/app/services/router.service';

@Component({
  selector: 'generic-header',
  templateUrl: './generic-header.component.html',
  styleUrls: ['./generic-header.component.scss']
})
// header for my account and contact/support and heir account
export class GenericHeaderComponent extends TcDumbComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  hasBack: boolean;

  @Input()
  backToHome = false;

  constructor(
    private routerService: RouterService,
    private location: Location) {
    super();
  }

  ngOnInit() {
  }

  onNavigationBack() {
    if (this.backToHome) {
      this.routerService.goToHomePage();
    } else {
      this.location.back();
    }
  }

}
