import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { TcFormComponent, TcTranslateService } from '@tc/core';
import { MatDialogRef } from '@angular/material';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ContentService } from 'src/generated/api/stockmemoriss';
import { LoadJournal } from '../../../store/journal.actions';
import { ContentState } from 'src/app/modules/content/store/content.state';

@Component({
  selector: 'journal-filter',
  templateUrl: './journal-filter.component.html',
  styleUrls: ['./journal-filter.component.scss']
})
export class JournalFilterComponent extends TcFormComponent<any> implements OnInit, OnDestroy {

  boxesSubscription: Subscription;
  folderSubscription: Subscription;
  boxOptions: [];
  folder: any;

  constructor(
    private dialogRef: MatDialogRef<JournalFilterComponent>,
    translate: TcTranslateService,
    element: ElementRef,
    private store: Store<ContentState>,
    private contentService: ContentService
  ) {

    super(translate, element);
    this.fields = [
      {
        key: 'name',
        type: 'input',
        templateOptions: {
          type: 'text',
        }
      },
      {
        type: 'select',
        key: 'boxId',
        templateOptions: {
          label: 'boxes',
          options: this.contentService.listBoxes(),
          valueProp: 'id',
          labelProp: 'name',
        }
      },
      {
        type: 'select',
        key: 'folderId',
        lifecycle: {
          onInit: (form, field) => {
            const updateOptions = (v) => {
              if (v.boxId) {
                this.folderSubscription = this.contentService.getFolder(v.boxId).subscribe(box => {
                  const values = box.folders ? box.folders : [];
                  // todo: replace by key
                  this.fields[2].templateOptions.options = values.map(folder => ({
                    value: folder.id,
                    label: folder.name
                  }));
                });
              }
            };
            updateOptions(form.value);
            this.form.valueChanges.subscribe(v => updateOptions(v));
          },
        },
        templateOptions: {
          label: 'folders',
          options: [
            { value: '' },
          ],
        },
      },
      {
        key: 'time-interval',
        type: 'select',
        templateOptions: {
          options: [
            { value: 2, label: this.translate.instant('box-detail.conservation-durations.2') },
            { value: 4, label: this.translate.instant('box-detail.conservation-durations.4') },
            { value: 5, label: this.translate.instant('box-detail.conservation-durations.5') },
            { value: 10, label: this.translate.instant('box-detail.conservation-durations.10') },
          ]
        },
      },
      {
        key: 'since-date',
        type: 'datepicker',
        templateOptions: {
          type: 'text',
          datepickerPopup: 'dd-MMMM-yyyy',
          datepickerOptions: {
            format: 'dd-MM-yyyy hh:mm:ss'
          }
        }
      },
      {
        key: 'until-date',
        type: 'datepicker',
        templateOptions: {
          type: 'text',
          datepickerPopup: 'dd-MMMM-yyyy',
          datepickerOptions: {
            format: 'dd-MM-yyyy hh:mm:ss'
          }
        }
      }
    ];
  }


  ngOnInit() {
    super.ngOnInit();
  }

  onClose() {
    this.dialogRef.close();
  }

  submit(model) {

    window.scrollTo(0, 0);

    if (!model.boxId) {
      model.boxId = null;
    }
    this.store.dispatch(new LoadJournal({ folderId: model.folderId ? model.folderId : model.boxId, fileId: null }));
    this.dialogRef.close();

  }

  ngOnDestroy(): void {
    if (this.boxesSubscription) {
      this.boxesSubscription.unsubscribe();
    }

    if (this.folderSubscription) {
      this.folderSubscription.unsubscribe();
    }
  }

}
