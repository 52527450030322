import { Component, OnInit, ElementRef } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { TcFormComponent, TcTranslateService } from '@tc/core';

@Component({
  selector: 'shared-password-dialog',
  templateUrl: './shared-password-dialog.component.html',
  styleUrls: ['./shared-password-dialog.component.scss']
})
export class SharedPasswordDialogComponent extends TcFormComponent<any> implements OnInit {

  constructor(
    public translateService: TcTranslateService,
    private dialogRef: MatDialogRef<SharedPasswordDialogComponent>,
    element: ElementRef) {
    super(translateService, element);

    this.fields = [
      {
        key: 'password',
        type: 'input',
        templateOptions: {
          type: 'password',
          required: true
        }
      },
    ];
  }

  ngOnInit() {
    super.ngOnInit();
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  submit(model: any) {
    if (this.form.valid) {
      this.dialogRef.close(model);
    }
  }

}
