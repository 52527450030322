import { Component } from '@angular/core';
import { MatDialog } from '@angular/material';
import { BoxDetailComponent } from '../box-detail/box-detail.component';
import { TcSmartComponent } from '@tc/core';

@Component({
  selector: 'home-page-footer-component',
  templateUrl: './home-page-footer.component.html',
  styleUrls: ['./home-page-footer.component.scss']
})
export class HomePageFooterComponent extends TcSmartComponent {

  constructor(public dialog: MatDialog) {
    super();
  }

  addBox(): void {
    this.dialog.open(BoxDetailComponent);
  }

}
