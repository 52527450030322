import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { HeirDetailComponent } from '../heir-detail/heir-detail.component';
import { Succession, User } from 'src/generated/api/stockmemoriss';
import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AccountState } from 'src/app/modules/account/store/account.reducer';
import { getAccountHeir, getAccountUser } from 'src/app/modules/account/store/account.selectors';
import { LoadHeir, DeleteHeir } from 'src/app/modules/account/store/account.actions';
import { TcSmartComponent } from '@tc/core';

@Component({
  selector: 'set-heir-account',
  templateUrl: './set-heir-account.component.html',
  styleUrls: ['./set-heir-account.component.scss']
})
export class SetHeirAccountComponent extends TcSmartComponent implements OnInit, OnDestroy {

  constructor(
    public dialog: MatDialog,
    private store: Store<AccountState>
  ) {
    super();
  }

  // user: User;
  currentUser$: Observable<User>;
  currentUserSubscription: Subscription;

  heir: Succession;
  heir$: Observable<Succession>;
  heirSubscription: Subscription;

  ngOnInit() {
    this.currentUserSubscription = this.store.pipe(select(getAccountUser)).subscribe(user => {
      if (user) {
        // this.user = user;
        this.store.dispatch(new LoadHeir(user.id));
      }
    });
    // if (this.user) {
    //   this.store.dispatch(new LoadHeir(this.user.id));
    // }
    this.heir$ = this.store.pipe(select(getAccountHeir));
    this.heirSubscription = this.store.pipe(select(getAccountHeir)).subscribe(heir => {
      if (heir) {
        this.heir = heir;
      }
    });
  }

  ngOnDestroy() {
    if (this.currentUserSubscription) {
      this.currentUserSubscription.unsubscribe();
    }
    if (this.heirSubscription) {
      this.heirSubscription.unsubscribe();
    }
  }

  add() {
    this.dialog.open(HeirDetailComponent, {
      autoFocus: false
    });
  }
  edit() {
    this.dialog.open(HeirDetailComponent, {
      data: this.heir,
      autoFocus: false
    });
  }

  delete() {
    this.store.dispatch(new DeleteHeir(this.heir.id));
  }

}
