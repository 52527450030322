import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TcCoreModule } from '@tc/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { BoxListComponent } from './components/smart/box-list/box-list.component';
import { HomePageMenuComponent } from './components/smart/home-page-menu/home-page-menu.component';
import { NavigateFoldersAndFilesComponent } from './components/smart/navigate-folders-and-files/navigate-folders-and-files.component';
import { ListDocumentsMenuComponent } from './components/smart/list-documents-menu/list-documents-menu.component';
import { HomePageFooterComponent } from './components/smart/home-page-footer/home-page-footer.component';
import { ListDocumentsFooterComponent } from './components/smart/list-documents-footer/list-documents-footer.component';
import { MsmNavigateFoldersAndFilesComponent } from './components/dumb/msm-navigate-folders-and-files/msm-navigate-folders-and-files.component';
import { CdkTableModule } from '@angular/cdk/table';
import { BoxSliderComponent } from './components/smart/box-slider/box-slider.component';
import { BoxDetailComponent } from './components/smart/box-detail/box-detail.component';
import { ManageFolderComponent } from './components/smart/manage-folder/manage-folder.component';
import { MatProgressBarModule } from '@angular/material';
import { RestitutionPasswordDialogComponent } from './components/smart/restitution-password-dialog/restitution-password-dialog.component';
import { SimpleSearchComponent } from './components/smart/simple-search/simple-search.component';
import { MsmNavigateFilesOnlyComponent } from './components/dumb/msm-navigate-files-only/msm-navigate-files-only.component';
import { FormlyModule } from '@ngx-formly/core';
import { BoxEffects } from './store/effects/box.effects';
import { FileEffects } from './store/effects/file.effects';
import { FolderEffects } from './store/effects/folder.effects';
import { SelectIconComponent } from 'src/app/modules/core/components/select-icon/select-icon.component';
import { SelectColorComponent } from 'src/app/modules/core/components/select-color/select-color.component';
import { BoxMainAddressComponent } from './components/smart/box-main-address/box-main-address.component';
import { SharedModule } from '../shared/shared.module';
import { TcImageModule } from '@tc/image';
import { UploadResizeDialogComponent } from './components/dumb/upload-resize-dialog/upload-resize-dialog.component';
import { MsmNavigateFoldersOnlyComponent } from './components/dumb/msm-navigate-folders-only/msm-navigate-folders-only.component';
import { MoveFolderOrFileComponent } from './components/smart/move-folder-or-file/move-folder-or-file.component';
import { MsmBoxListComponent } from './components/dumb/msm-box-list/msm-box-list.component';
import { MoveEffects } from './store/effects/move.effects';
import { MsmNavigateRowComponent } from './components/dumb/msm-navigate-row/msm-navigate-row.component';
import { DocumentsFooterComponent } from './components/dumb/documents-footer/documents-footer.component';
import { ShareDocumentsOptionsDialogComponent } from './components/smart/share-documents-options-dialog/share-documents-options-dialog.component';
import { ShareDocumentsWarningDialogComponent } from './components/dumb/share-documents-warning-dialog/share-documents-warning-dialog.component';
import { ShareFileOrFolderComponent } from './components/smart/share-file-or-folder/share-file-or-folder.component';
import { SharedPasswordDialogComponent } from './components/dumb/shared-password-dialog/shared-password-dialog.component';
import { CfSubscriptionDialogComponent } from './components/dumb/cf-subscription-dialog/cf-subscription-dialog.component';
import { ShareEffects } from './store/effects/share.effects';
import { ContentEffects } from './store/effects/content.effects';
import { ConfirmDeleteDialogComponent } from './components/dumb/confirm-delete-dialog/confirm-delete-dialog.component';
import { ConfirmDeleteComponent } from './components/smart/confirm-delete/confirm-delete.component';
import { RestoreDialogComponent } from './components/smart/restore-dialog/restore-dialog.component';
import { ExpiredFilesComponent } from './components/smart/expired-files/expired-files.component';
import { CertificateEffects } from './store/effects/certificate.effects';
import { SelectFilesForCertificateComponent } from './components/smart/select-files-for-certificate/select-files-for-certificate.component';
import { SelectFoldersAndFilesComponent } from './components/smart/select-folders-and-files/select-folders-and-files.component';
import { SelectFoldersAndFilesEffects } from './store/effects/select-folders-and-files.effects';
import { CertificateComponent } from './components/smart/certificate/certificate.component';
import { CertificatePaymentComponent } from './components/smart/certificate-payment/certificate-payment.component';
import { ConfirmCertificateDialogComponent } from './components/dumb/confirm-certificate-dialog/confirm-certificate-dialog.component';
import { ExpiredFilesEffects } from './store/effects/expired-files.effects';
import { ConfirmExtendComponent } from './components/smart/confirm-extend/confirm-extend.component';
import { MsmBoxItemComponent } from './components/dumb/msm-box-item/msm-box-item.component';
import { ConfirmConservationDurationDialogComponent } from './components/dumb/confirm-conservation-duration-dialog/confirm-conservation-duration-dialog.component';
import { contentReducer } from './store/content.reducer';
import { DownloadLinkComponent } from './components/smart/download-link/download-link.component';


const dumbComponents = [
  MsmNavigateFoldersAndFilesComponent,
  MsmNavigateFilesOnlyComponent,
  MsmNavigateFoldersOnlyComponent,
  MsmBoxListComponent,
  UploadResizeDialogComponent,
  MsmNavigateRowComponent,
  DocumentsFooterComponent,
  ShareDocumentsWarningDialogComponent,
  ConfirmDeleteDialogComponent,
  ConfirmCertificateDialogComponent,
  MsmBoxItemComponent,
  ConfirmConservationDurationDialogComponent
];

const smartComponents = [
  BoxListComponent,
  BoxSliderComponent,
  HomePageMenuComponent,
  NavigateFoldersAndFilesComponent,
  ListDocumentsMenuComponent,
  HomePageFooterComponent,
  ListDocumentsFooterComponent,
  BoxDetailComponent,
  ManageFolderComponent,
  RestitutionPasswordDialogComponent,
  SimpleSearchComponent,
  BoxMainAddressComponent,
  MoveFolderOrFileComponent,
  ShareDocumentsOptionsDialogComponent,
  ShareFileOrFolderComponent,
  SharedPasswordDialogComponent,
  CfSubscriptionDialogComponent,
  ConfirmDeleteComponent,
  RestoreDialogComponent,
  CertificateComponent,
  SelectFilesForCertificateComponent,
  SelectFoldersAndFilesComponent,
  CertificatePaymentComponent,
  ExpiredFilesComponent,
  ConfirmExtendComponent,
  DownloadLinkComponent
];

@NgModule({
  declarations: [
    ...smartComponents,
    ...dumbComponents,
    SelectIconComponent,
    SelectColorComponent
  ],
  imports: [
    CommonModule,
    TcCoreModule,
    TcImageModule,
    SharedModule,
    CdkTableModule,
    FormlyModule.forRoot({
      types: [{
        name: 'select-icon',
        component: SelectIconComponent,
        wrappers: ['form-field']
      },
      {
        name: 'select-color',
        component: SelectColorComponent,
        wrappers: ['form-field']
      }
      ]
    }),
    StoreModule.forFeature('content', contentReducer),
    EffectsModule.forFeature([
      BoxEffects,
      FileEffects,
      FolderEffects,
      MoveEffects,
      ShareEffects,
      ContentEffects,
      CertificateEffects,
      SelectFoldersAndFilesEffects,
      ExpiredFilesEffects
    ]),
    MatProgressBarModule
  ],
  exports: [
    ...smartComponents,
    ...dumbComponents,
  ],
  entryComponents: [
    BoxDetailComponent,
    ManageFolderComponent,
    RestitutionPasswordDialogComponent,
    UploadResizeDialogComponent,
    MoveFolderOrFileComponent,
    ShareDocumentsOptionsDialogComponent,
    ShareDocumentsWarningDialogComponent,
    SharedPasswordDialogComponent,
    CfSubscriptionDialogComponent,
    ConfirmDeleteDialogComponent,
    RestoreDialogComponent,
    ConfirmCertificateDialogComponent,
    ConfirmConservationDurationDialogComponent
  ]
})
export class ContentModule { }
