import { FolderActions, FolderActionTypes } from '../actions/folder.actions';
import { Folder } from 'src/generated/api/stockmemoriss';
import { initialContentState, ContentState } from '../content.state';

export function folderReducer(
    state = initialContentState,
    action: FolderActions
): ContentState {
    switch (action.type) {
        case FolderActionTypes.SELECT_FOLDER:
        case FolderActionTypes.BACK_TO_PARENT_FOLDER: {
            return {
                ...state,
                selectedFolderId: action.payload,
                selectedFolder: null,
                selectedFile: null
            };
        }

        case FolderActionTypes.LOAD_FOLDER_CONTENT_SUCCESS: {
            return {
                ...state,
                folders: action.payload.folders ? action.payload.folders : [],
                files: action.payload.files ? action.payload.files : [],
                selectedFolder: action.payload,
                searchText: '',
                searchFiles: [],
                selectedRows: [],
                filesForCertificate: []
            };
        }
        case FolderActionTypes.ADD_FOLDER_SUCCESS: {

            // existing folders
            let folders = state.folders;

            // get move state
            const move = state.move;

            // check if moveToEntity is equal with new folder parent id => in this case the folder was added from move page
            if (move && move.moveToEntity && move.moveToEntity.id === action.payload.parentId) {
                move.entitiesForMoving = move.entitiesForMoving.concat(action.payload);
            } else {
                folders = folders.concat(action.payload);
            }

            return {
                ...state,
                folders,
                move
            };
        }
        case FolderActionTypes.EDIT_FOLDER_SUCCESS: {

            const newFolders = state.folders.map(folder => {

                // todo: find another way
                const f = {} as Folder;
                f.id = folder.id;
                f.name = folder.id === action.payload.folder.id ? action.payload.folder.name : folder.name;
                f.type = folder.type;
                f.parentId = folder.parentId;
                f.files = folder.files;
                f.folders = folder.folders;

                return f;

            });

            const newSelectedRows = state.selectedRows;
            newSelectedRows.filter(row => row.id !== action.payload.folder.id);

            return Object.assign({
                ...state,
                folders: newFolders,
                selectedRows: newSelectedRows

            });

        }
        case FolderActionTypes.DELETE_FOLDER_SUCCESS:
            return Object.assign({
                ...state,
                folders: state.folders.filter(folder => folder.id !== action.payload)

            });

        default: {
            return state;
        }

    }
}
