/**
 * StockMemoriss API
 * StockMemoriss API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Succession { 
    /**
     * id = user.id
     */
    id: number;
    civility?: Succession.CivilityEnum;
    email?: string;
    password?: string;
    firstName?: string;
    lastName?: string;
    birthDate?: string;
    officialDocumentNumber?: string;
    address1?: string;
    address2?: string;
    codePostal?: string;
    city?: string;
    country?: string;
}
export namespace Succession {
    export type CivilityEnum = 'M.' | 'Mme';
    export const CivilityEnum = {
        M: 'M.' as CivilityEnum,
        Mme: 'Mme' as CivilityEnum
    };
}

