import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { JournalFilterComponent } from '../../smart/journal-filter/journal-filter.component';
import { TcDumbComponent } from '@tc/core';

@Component({
  selector: 'journal-header',
  templateUrl: './journal-header.component.html',
  styleUrls: ['./journal-header.component.scss']
})
export class JournalHeaderComponent extends TcDumbComponent implements OnInit {

  @Input() hasFilter: boolean;

  @Input() title: string;

  constructor(
    private dialog: MatDialog
  ) {
    super();
  }

  ngOnInit() {

  }

  onClickFilter(): void {
    this.dialog.open(JournalFilterComponent, {
      maxHeight: '90vh'
    });
  }

}
