import { Box, Folder, SimpleFile, AbstractFile, ExpiredFile } from 'src/generated/api/stockmemoriss';

import { BreadCrumbItem } from '../../shared/models/breadcrumb-item';

export interface ContentState {

    singleDisplay: boolean;

    boxes: Box[];
    folders: Folder[];
    files: SimpleFile[];

    searchText: string;
    searchFiles: SimpleFile[];

    isSharedMode: boolean;

    sharedId: string;
    sharedFileId: number;
    sharedFolderId: number;
    sharedPassword: string;

    selectedFolderId: number;
    selectedFolder: AbstractFile | null;

    selectedFile: SimpleFile | null;
    selectedBox: Box | null;

    move: {
        // entity should be moved
        entityToMove: AbstractFile;
        // available entities for move to
        entitiesForMoving: AbstractFile[];
        // entity in which file or folder will be moved
        moveToEntity: AbstractFile;
        // breadcrumb navivagtion list: Boxes > Folder1 > Folder 11 > Folder 12
        navigation: BreadCrumbItem[];
        // orphans
        orphans: AbstractFile[];

    };

    // todo: array of AbstractFile or ExpiredFile
    selectedRows: any[];

    selectFoldersAndFiles: {
        navigation: BreadCrumbItem[];
        boxes: Box[];
        folders: Folder[];
        files: SimpleFile[];
        selectedFolderId: number;
        selectedRows: AbstractFile[];
    };

    filesForCertificate: AbstractFile[];

    expiredFiles: ExpiredFile[];

}

export const initialContentState: ContentState = {

    singleDisplay: false,

    boxes: [],
    folders: [],
    files: [],

    searchText: null,
    searchFiles: [],

    isSharedMode: false,

    sharedId: null,
    sharedFileId: null,
    sharedFolderId: null,
    sharedPassword: null,

    selectedFolderId: null,
    selectedFolder: null,

    selectedFile: null,
    selectedBox: null,

    move: {
        entityToMove: null,
        entitiesForMoving: [],
        moveToEntity: null,
        navigation: [],
        orphans: []
    },

    selectedRows: [],

    selectFoldersAndFiles: {
        navigation: [],
        boxes: [],
        folders: [],
        files: [],
        selectedFolderId: null,
        selectedRows: []
    },

    filesForCertificate: [],

    expiredFiles: []

};
