import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ContentService, Journal } from 'src/generated/api/stockmemoriss';
import { Observable } from 'rxjs';
import { TcAction } from '@tc/core';
import { mergeMap, map, withLatestFrom, tap } from 'rxjs/operators';
import { LoadJournal, JournalActionTypes, LoadJournalSuccess, LoadMoreJournalData, LoadMoreJournalDataSuccess } from './journal.actions';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/modules/core/store/app.state';

@Injectable()
export class JournalEffects {

    constructor(
        private actions$: Actions,
        private contentService: ContentService,
        private store: Store<AppState>) { }

    @Effect()
    loadJournal$: Observable<TcAction> = this.actions$.pipe(
        ofType<LoadJournal>(
            JournalActionTypes.LOAD_JOURNAL
        ),
        withLatestFrom(this.store.select(state => state)),
        mergeMap(([action, state]) =>
            this.contentService.getJournal(action.payload.folderId, action.payload.fileId, 0).pipe(
                map(
                    (items: Journal[]) =>
                        new LoadJournalSuccess({
                            folderId: action.payload.folderId, fileId: action.payload.fileId, items
                        })
                )
            )
        )
    );

    @Effect()
    loadMoreJournalData$: Observable<TcAction> = this.actions$.pipe(
        ofType<LoadMoreJournalData>(
            JournalActionTypes.LOAD_MORE_JOURNAL_DATA
        ),
        withLatestFrom(this.store.select(state => state.journal)),
        // tap(([a, b]) => console.log('in effect', b)),
        mergeMap(([action, state]) =>
            this.contentService.getJournal(state.folderId, state.fileId, state.offset + 50).pipe(
                map(
                    (items: Journal[]) =>
                        new LoadMoreJournalDataSuccess({
                            items
                        })
                )
            )
        )
    );

}
