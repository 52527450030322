import { Journal } from 'src/generated/api/stockmemoriss';
import { JournalActionTypes } from './journal.actions';

export interface JournalState {
    results: Journal[];
    folderId: number;
    fileId: number;
    offset: number;
}

export const initialJournalState: JournalState = {
    results: [],
    folderId: null,
    fileId: null,
    offset: 0
};

export function journalReducer(
    state = initialJournalState,
    action
): JournalState {
    switch (action.type) {

        case JournalActionTypes.LOAD_JOURNAL: {

            return {
                ...state,
                results: [],
                folderId: null,
                fileId: null,
                offset: 0
            };
        }

        case JournalActionTypes.LOAD_JOURNAL_SUCCESS: {

            const items = action.payload.items;

            return {
                ...state,
                results: items,
                folderId: action.payload.folderId,
                fileId: action.payload.fileId,
                offset: 0
            };
        }

        case JournalActionTypes.LOAD_MORE_JOURNAL_DATA_SUCCESS: {

            const items = state.results.concat(action.payload.items);
            const newOffset = state.offset + 50;

            return {
                ...state,
                results: items,
                offset: newOffset
            };
        }
        default: {
            return state;
        }
    }
}
