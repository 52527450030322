import { SelectFoldersAndFilesActions, SelectFoldersAndFilesActionTypes } from '../actions/select-folders-and-files.actions';
import { BreadCrumbItem } from 'src/app/modules/shared/models/breadcrumb-item';
import { initialContentState, ContentState } from '../content.state';

export function selectFoldersAndFilesReducer(
    state = initialContentState,
    action: SelectFoldersAndFilesActions
): ContentState {
    switch (action.type) {
        case SelectFoldersAndFilesActionTypes.SELECT_FOLDERS_AND_FILES_LOAD_BOXES_SUCCESS: {
            return {
                ...state,
                selectFoldersAndFiles: {
                    navigation: [],
                    boxes: action.payload,
                    folders: [],
                    files: [],
                    selectedFolderId: null,
                    selectedRows: state.selectFoldersAndFiles.selectedRows
                }
            };
        }

        case SelectFoldersAndFilesActionTypes.SELECT_FOLDERS_AND_FILES_LOAD_FOLDER_CONTENT_SUCCESS: {

            const selectFoldersAndFiles = state.selectFoldersAndFiles;

            selectFoldersAndFiles.selectedFolderId = action.payload.id;
            selectFoldersAndFiles.folders = action.payload.folders ? action.payload.folders : [];
            selectFoldersAndFiles.files = action.payload.files ? action.payload.files : [];


            const existingNavigationItem = selectFoldersAndFiles.navigation.find(n => n.id === action.payload.id);

            if (existingNavigationItem) {
                const order = existingNavigationItem.order;
                const newItems = selectFoldersAndFiles.navigation.filter(n => n.order <= order);
                selectFoldersAndFiles.navigation = newItems;
            } else {
                const navigationItem = {} as BreadCrumbItem;
                navigationItem.id = action.payload.id;
                navigationItem.name = action.payload.name;
                navigationItem.type = action.payload.type;
                navigationItem.order = selectFoldersAndFiles.navigation.length + 1;

                selectFoldersAndFiles.navigation.push(navigationItem);
            }

            return {
                ...state,
                selectFoldersAndFiles
            };

        }

        case SelectFoldersAndFilesActionTypes.SELECT_FOLDERS_AND_FILES_SELECT_FOLDER: {

            const selectFoldersAndFiles = state.selectFoldersAndFiles;
            selectFoldersAndFiles.selectedFolderId = action.payload;

            return {
                ...state,
                selectFoldersAndFiles
            };
        }

        case SelectFoldersAndFilesActionTypes.SELECT_FOLDERS_AND_FILES_SELECT_ROWS: {

            const selectFoldersAndFiles = state.selectFoldersAndFiles;

            const selectedRows = selectFoldersAndFiles.selectedRows;
            selectedRows.push(...action.payload);

            selectFoldersAndFiles.selectedRows = selectedRows;

            return {
                ...state,
                selectFoldersAndFiles
            };
        }

        case SelectFoldersAndFilesActionTypes.SELECT_FOLDERS_AND_FILES_DESELECT_ROWS: {

            const selectFoldersAndFiles = state.selectFoldersAndFiles;

            const selectedIds = action.payload.map(f => f.id);

            const selectedRows = selectFoldersAndFiles.selectedRows.filter(value => !selectedIds.includes(value.id));

            selectFoldersAndFiles.selectedRows = selectedRows;

            return {
                ...state,
                selectFoldersAndFiles
            };
        }

        default: {
            return state;
        }
    }
}
