/**
 * StockMemoriss API
 * StockMemoriss API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type FileType = 'File' | 'Folder' | 'Box';

export const FileType = {
    File: 'File' as FileType,
    Folder: 'Folder' as FileType,
    Box: 'Box' as FileType
};
