import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material';
import { RouterService } from 'src/app/services/router.service';
import { TcDumbComponent } from '@tc/core';

@Component({
  selector: 'cf-subscription-dialog',
  templateUrl: './cf-subscription-dialog.component.html',
  styleUrls: ['./cf-subscription-dialog.component.scss']
})
export class CfSubscriptionDialogComponent extends TcDumbComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<CfSubscriptionDialogComponent>,
    private routerService: RouterService
  ) {
    super();
  }

  ngOnInit() {
  }

  onClose() {
    this.dialogRef.close();
  }

  subscribe() {
    this.routerService.goToSignUpPage();
    this.dialogRef.close();
  }

}
