import { Component, ElementRef, OnInit, Inject, Optional, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TcFormComponent, TcTranslateService, TcNotificationService } from '@tc/core';
import { Box, FileType, User } from 'src/generated/api/stockmemoriss';
import { Store, select } from '@ngrx/store';
import { Subscription, Observable } from 'rxjs';
import { getAccountUser } from 'src/app/modules/account/store/account.selectors';
import { AddBox, EditBox } from '../../../store/actions/box.actions';
import { getBoxes } from '../../../store/selectors/box.selectors';
import { ContentState } from '../../../store/content.state';
import { ConfirmConservationDurationDialogComponent } from '../../dumb/confirm-conservation-duration-dialog/confirm-conservation-duration-dialog.component';

@Component({
  selector: 'box-detail',
  templateUrl: './box-detail.component.html',
  styleUrls: ['./box-detail.component.scss']
})
// pop-up component (add/edit box)
export class BoxDetailComponent extends TcFormComponent<Box> implements OnInit, OnDestroy {

  boxesSubscription: Subscription;
  boxes: Box[] = [];

  user: User;
  user$: Observable<User>;
  userSubscription: Subscription;

  constructor(
    private dialogRef: MatDialogRef<BoxDetailComponent>,
    private matDialog: MatDialog,
    private store: Store<ContentState>,
    translate: TcTranslateService,
    element: ElementRef,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Box) {

    super(translate, element);

    this.fields = [
      {
        key: 'name',
        type: 'input',
        templateOptions: {
          type: 'text',
          required: true
        },
      },
      {
        key: 'icon',
        type: 'select-icon',
        templateOptions: {
          required: true,
          type: 'select',
          icons: ['house', 'directions_car', 'people_outline', 'attach_money', 'work', 'local_hospital',
            'cake', 'deck', 'notifications', 'school', 'sports_baseball', 'sports_football', 'thumb_up_alt',
            'fitness_center', 'pool', 'room_service', 'spa', 'rv_hookup', 'child_friendly', 'directions_bike',
            'directions_run', 'directions_subway', 'flight', 'hotel', 'local_dining', 'local_gas_station',
            'local_grocery_store', 'local_see'
          ]
        }
      },
      {
        key: 'color',
        type: 'select-color',
        templateOptions: {
          required: true,
          type: 'select',
          colors: ['#001f3f', '#0074D9', '#7FDBFF', '#39CCCC', '#3D9970', '#2ECC40', '#01FF70', '#FFDC00',
            '#FF851B', '#FF4136', '#85144b', '#F012BE', '#B10DC9', '#AAAAAA', '#DDDDDD'
          ]
        },
      },
      {
        key: 'conservationDuration',
        type: 'select',
        templateOptions: {
          required: true,
          options: [
            { value: 2, label: this.translate.instant('box-detail.conservation-durations.2') },
            { value: 4, label: this.translate.instant('box-detail.conservation-durations.4') },
            { value: 5, label: this.translate.instant('box-detail.conservation-durations.5') },
            { value: 10, label: this.translate.instant('box-detail.conservation-durations.10') },
            { value: 0, label: this.translate.instant('box-detail.conservation-durations.0') },
          ],
          change: (field, $event) => {
            if (this.data) {
              if (this.data.conservationDuration !== $event.value) {
                this.matDialog.open(ConfirmConservationDurationDialogComponent);
              }
            }
          }
        },
      },
    ];
  }

  ngOnInit() {

    this.userSubscription = this.store.pipe(select(getAccountUser)).subscribe(user => {
      if (user) {
        this.user = user;
      }
    });
    if (this.data) {
      this.model = Object.assign({}, this.data);
    } else {
      this.boxesSubscription = this.store.pipe(select(getBoxes)).subscribe(boxes => {
        this.boxes = boxes;
      });
      this.model.id = 0;
      this.model.type = FileType.Box;
    }

    super.ngOnInit();
  }

  ngOnDestroy() {
    if (this.boxesSubscription) {
      this.boxesSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  submit(model: Box) {

    if (this.form.valid) {

      if (model.id === 0 && this.user) {

        let maxOrder = 0;
        if (this.boxes.length > 0) {
          maxOrder = Math.max.apply(Math, this.boxes.map(b => b.order));
        }

        model.order = maxOrder + 1;
        model.parentId = this.user.defaultBoxId;

        this.store.dispatch(new AddBox(model));

      } else {

        this.store.dispatch(new EditBox(model));

      }

      this.dialogRef.close(model);
    }
  }

  onClose() {
    this.dialogRef.close();
  }

}
