import { Injectable } from '@angular/core';
import { TcMenuItem, TcService, Logout } from '@tc/core';
import { User } from 'src/generated/api/stockmemoriss';
import { SetSingleDisplay } from '../modules/content/store/actions/box.actions';
import { Store } from '@ngrx/store';
import { LoadJournal } from '../modules/journal/store/journal.actions';
import { RouterService } from './router.service';
import { HomePageMenuOptions } from '../modules/core/enums/home-page-menu-options';
import { RouteOptions } from '../modules/core/enums/route-options';
import { EditUser } from '../modules/account/store/account.actions';
import { ContentState } from '../modules/content/store/content.state';

@Injectable({
    providedIn: 'root'
})
export class HomePageMenuService extends TcService {

    constructor(
        private store: Store<ContentState>,
        private routerService: RouterService,
    ) {
        super();
    }

    public getMenuItems(): TcMenuItem[] {

        return [
            {
                name: HomePageMenuOptions.Account,
            },
            {
                name: HomePageMenuOptions.Params,
            },
            {
                name: HomePageMenuOptions.Journal,
            },
            {
                name: HomePageMenuOptions.Certificate,
            },
            {
                name: HomePageMenuOptions.ExpiredFiles,
            },
            {
                name: HomePageMenuOptions.ContactSupport
            },
            {
                name: HomePageMenuOptions.Remarque
            },
            {
                name: HomePageMenuOptions.CoachingMode,
                hasSlideToggle: true,
                isChecked: false
            },
            {
                name: HomePageMenuOptions.Faq,
            },
            {
                name: HomePageMenuOptions.DisplayOption,
                hasSlideToggle: true,
                isChecked: false
            },
            {
                name: HomePageMenuOptions.Logout
            }
        ];
    }

    onMenuItem(item: TcMenuItem, currentUser: User) {

        if (item.name === HomePageMenuOptions.Logout) {
            this.store.dispatch(new Logout());
        }
        if (item.name === HomePageMenuOptions.DisplayOption) {
            this.store.dispatch(new SetSingleDisplay(item.isChecked));
        }
        if (item.name === HomePageMenuOptions.Account) {
            this.routerService.goToPage(RouteOptions.MyAccount);
        }
        if (item.name === HomePageMenuOptions.ContactSupport) {
            this.routerService.goToPage(RouteOptions.ContactSupport);
        }
        if (item.name === HomePageMenuOptions.Remarque) {
            this.routerService.goToPage(RouteOptions.ContactTeam);
        }
        if (item.name === HomePageMenuOptions.Params) {
            this.routerService.goToPage(RouteOptions.Settings);
        }
        if (item.name === HomePageMenuOptions.CoachingMode) {
            if (currentUser) {
                if (item.isChecked) {
                    currentUser.coachingMode = 'debutant';
                } else {
                    currentUser.coachingMode = 'expert';
                }
                this.store.dispatch(new EditUser({ user: currentUser, actionType: 'set-coaching-mode' }));
            }
        }
        if (item.name === HomePageMenuOptions.Faq) {
            this.routerService.goToPage(RouteOptions.FAQ);
        }
        // journal general
        if (item.name === HomePageMenuOptions.Journal) {
            this.store.dispatch(new LoadJournal({ folderId: null, fileId: null }));
            // todo: move this to JournalService
            this.routerService.goToJournalGeneralPage();
        }

        if (item.name === HomePageMenuOptions.Certificate) {
            this.routerService.goToPage(RouteOptions.Certificate);
        }

        if (item.name === HomePageMenuOptions.ExpiredFiles) {
            this.routerService.goToPage(RouteOptions.ExpiredFiles);
        }

    }
}
