import { Component, OnInit, Input } from '@angular/core';
import { AccountState } from 'src/app/modules/account/store/account.reducer';
import { Store } from '@ngrx/store';
import { ConfirmEmail } from 'src/app/modules/account/store/account.actions';
import { TcSmartComponent } from '@tc/core';

@Component({
  selector: 'confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss']
})
export class ConfirmEmailComponent extends TcSmartComponent implements OnInit {

  @Input() code: string;

  constructor(
    private store: Store<AccountState>) {
    super();
  }

  ngOnInit() {
    if (this.code) {
      this.store.dispatch(new ConfirmEmail(this.code));
    }
  }

}
