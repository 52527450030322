import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService, User } from 'src/generated/api/stockmemoriss';
import { Subscription, Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { getAccountUser } from 'src/app/modules/account/store/account.selectors';
import { MatDialog } from '@angular/material';
import { CancelCguComponent } from '../cancel-cgu/cancel-cgu.component';
import { Utils } from 'src/app/modules/core/utils';
import { TcSmartComponent } from '@tc/core';
import { PartnerProvider } from 'src/app/modules/core/providers/partner-provider';
import { RouteOptions } from 'src/app/modules/core/enums/route-options';
import { RouterService } from 'src/app/services/router.service';

@Component({
  selector: 'cgu-acceptation',
  templateUrl: './cgu-acceptation.component.html',
  styleUrls: ['./cgu-acceptation.component.scss']
})
export class CguAcceptationComponent extends TcSmartComponent implements OnInit, OnDestroy {

  cguText: string;
  sharedUrl: string;

  user: User;
  user$: Observable<User>;
  userSubscription: Subscription;
  editUserSubscription: Subscription;

  constructor(
    private store: Store<AccountService>,
    private routerService: RouterService,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private partnerProvider: PartnerProvider) {
    super();

    const partnerConfig = this.partnerProvider.getPartnerConfig();

    if (partnerConfig) {
      this.cguText = partnerConfig.cgu;
    }

  }

  ngOnInit() {

    const sharedUrl = this.route.snapshot.queryParams.sharedUrl;

    if (sharedUrl) {
      this.sharedUrl = sharedUrl;
    }

    this.userSubscription = this.store.pipe(select(getAccountUser)).subscribe(user => {
      if (user) {
        this.user = user;
        if (user.dateAcceptationCGU) {

          this.navigateForward();

        }
      } else {
        this.routerService.goToPage(RouteOptions.Login);
      }
    });
  }

  cancel() {
    this.dialog.open(CancelCguComponent);
  }


  validate() {
    if (this.user) {
      this.user.dateAcceptationCGU = Utils.formatDateToString(new Date());
      // TO DO crate action and dispatch
      this.editUserSubscription = this.accountService.putUser(this.user.id, this.user).subscribe(u => {
        this.navigateForward();
      });
    } else {
      this.routerService.goToPage(RouteOptions.Login);
    }

  }

  ngOnDestroy(): void {

    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }

    if (this.editUserSubscription) {
      this.editUserSubscription.unsubscribe();
    }
  }

  navigateForward() {
    if (this.user.role === User.RoleEnum.Lecteur) {
      if (this.sharedUrl) {
        this.routerService.goToUrl(this.sharedUrl);
      } else {
        this.routerService.goToPage(RouteOptions.Login);
      }
    } else {
      this.routerService.goToHomePage();
    }
  }
}
