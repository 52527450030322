import { Component, OnInit, OnDestroy } from '@angular/core';
import { Journal } from 'src/generated/api/stockmemoriss';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { JournalState } from '../../../store/journal.reducer';
import { LoadMoreJournalData } from '../../../store/journal.actions';
import { getJournalResults } from '../../../store/journal.selectors';
import { TcSmartComponent } from '@tc/core';

@Component({
  selector: 'journal-results-list',
  templateUrl: './journal-results-list.component.html',
  styleUrls: ['./journal-results-list.component.scss']
})
export class JournalResultsListComponent extends TcSmartComponent implements OnInit, OnDestroy {

  journalResults$: Observable<Journal[]>;
  journalResultsSubscription: Subscription;

  canAddMoreResults = true;

  constructor(
    private store: Store<JournalState>) {
    super();
  }

  ngOnInit() {
    this.journalResults$ = this.store.pipe(select(getJournalResults));

    this.journalResultsSubscription = this.journalResults$.subscribe(items => {
      if (items.length > 0 && items.length < 50) {
        this.canAddMoreResults = false;
      }
    });
  }

  ngOnDestroy() {
    if (this.journalResultsSubscription) {
      this.journalResultsSubscription.unsubscribe();
    }
  }

  scrollAction() {
    if (this.canAddMoreResults) {
      this.store.dispatch(new LoadMoreJournalData());
    }

  }

}
