import { Component, OnInit, Input, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { TcDumbComponent } from '@tc/core';

@Component({
  selector: 'coaching-modal',
  templateUrl: './coaching-modal.component.html',
  styleUrls: ['./coaching-modal.component.scss']
})
export class CoachingModalComponent extends TcDumbComponent implements OnInit {

  text = '';

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: string) {
    super();
  }

  ngOnInit() {
    if (this.data) {
      this.text = this.data;
    }
  }

}
