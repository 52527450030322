import { Component, OnInit, Optional, Inject, ElementRef, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Store } from '@ngrx/store';
import { TcTranslateService, TcFormComponent } from '@tc/core';
import { Folder, FileType } from 'src/generated/api/stockmemoriss';
import { AddFolder, EditFolder } from '../../../store/actions/folder.actions';
import { ContentState } from '../../../store/content.state';

@Component({
  selector: 'manage-folder',
  templateUrl: './manage-folder.component.html',
  styleUrls: ['./manage-folder.component.scss']
})
export class ManageFolderComponent extends TcFormComponent<Folder> implements OnInit, OnDestroy {

  parentId: number;

  constructor(

    public dialogRef: MatDialogRef<ManageFolderComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<ContentState>,
    translate: TcTranslateService,
    element: ElementRef) {

    super(translate, element);

    this.fields = [
      {
        key: 'name',
        type: 'input',
        templateOptions: {
          type: 'text',
          required: true
        },
      }
    ];
  }

  ngOnInit() {

    if (this.data) {
      if (this.data.model) {
        this.model = Object.assign({}, this.data.model);
      } else {
        this.model.id = 0;
        this.parentId = this.data.parentFolderId;
        this.model.type = FileType.Folder;
      }
    }

    super.ngOnInit();
  }

  ngOnDestroy() {

  }

  submit(model: Folder) {
    if (this.form.valid) {
      if (model.id === 0) {
        model.parentId = this.parentId;
        this.store.dispatch(new AddFolder(model));
      } else {
        this.store.dispatch(new EditFolder({ folder: model, type: 'edit-folder' }));
      }
      this.dialogRef.close(model);
    }
  }

  onClose() {
    this.dialogRef.close();
  }

}
