import { Component, OnInit } from '@angular/core';
import { TcPage } from '@tc/core';

@Component({
  selector: 'F04-manage-account',
  templateUrl: './F04-manage-account.component.html',
  styleUrls: ['./F04-manage-account.component.scss']
})
export class F04ManageAccountComponent extends TcPage implements OnInit {

  title: string;
  constructor() {
    super();
  }

  ngOnInit() {
  }

}
