export enum BoxMenuOptions {
    Edit = 'edit',
    RequestDelete = 'request-delete',
    BoxEmail = 'box-email',
    Send = 'send',
    Share = 'share',
    Journal = 'journal',

    BinDeleteAll = 'delete-all',
    BinDeleteSelected = 'delete-selected',
    BinRestore = 'restore'

}

