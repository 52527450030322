import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Faq, FaqService } from 'src/generated/api/stockmemoriss';
import { Observable } from 'rxjs';
import { TcAction } from '@tc/core';
import { mergeMap, map } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { LoadFaq, FaqActionTypes, LoadFaqSuccess } from './faq.actions';

@Injectable()
export class FaqEffects {

    constructor(
        private actions$: Actions,
        private faqService: FaqService,
        public dialog: MatDialog
    ) { }

    @Effect()
    loadFaq$: Observable<TcAction> = this.actions$.pipe(
        ofType<LoadFaq>(
            FaqActionTypes.LOAD_FAQ
        ),
        mergeMap((action: LoadFaq) =>
            this.faqService.getFaq().pipe(
                map(
                    (items: Faq[]) =>
                        new LoadFaqSuccess(items)
                )
            )
        )
    );
}
