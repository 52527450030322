
import { BoxActionTypes, BoxActions } from '../actions/box.actions';
import { initialContentState, ContentState } from '../content.state';

export function boxReducer(
    state = initialContentState,
    action: BoxActions
): ContentState {
    switch (action.type) {
        case BoxActionTypes.LOAD_BOXES_SUCCESS: {
            return {
                ...state,
                boxes: action.payload,
                folders: [],
                files: [],
                searchText: '',
                searchFiles: [],
                selectedBox: null,
                selectedFolder: null,
                selectedFolderId: null,

                isSharedMode: false,

                sharedId: null,
                sharedFileId: null,
                sharedFolderId: null,
                sharedPassword: null,

                selectedRows: [],

                selectFoldersAndFiles: {
                    navigation: [],
                    boxes: [],
                    folders: [],
                    files: [],
                    selectedFolderId: null,
                    selectedRows: []
                },

                filesForCertificate: [],

                expiredFiles: []

            };
        }
        case BoxActionTypes.ADD_BOX_SUCCESS: {
            return {
                ...state,
                boxes: state.boxes.concat(action.payload)
            };
        }
        case BoxActionTypes.EDIT_BOX_SUCCESS: {

            const items = state.boxes;
            const index = items.findIndex(item => item.id === action.payload.id);

            if (index >= 0) {
                items[index].icon = action.payload.icon;
                items[index].color = action.payload.color;
                items[index].name = action.payload.name;
                items[index].conservationDuration = action.payload.conservationDuration;
            }

            const selectedBox = state.selectedBox;

            if (selectedBox) {
                selectedBox.icon = action.payload.icon;
                selectedBox.color = action.payload.color;
                selectedBox.name = action.payload.name;
                selectedBox.conservationDuration = action.payload.conservationDuration;
            }

            const selectedFolder = state.selectedFolder;

            if (selectedFolder) {
                selectedFolder.name = action.payload.name;
            }

            return Object.assign({
                ...state,
                selectedBox,
                selectedFolder,
                boxes: items
            });
        }
        case BoxActionTypes.DELETE_BOX_SUCCESS:
            return Object.assign({
                ...state,
                boxes: state.boxes.filter(box => box.id !== action.payload)
            });
        case BoxActionTypes.CHANGE_BOX_EMAIL_ADDRESS:
        case BoxActionTypes.SELECT_BOX: {
            return {
                ...state,
                selectedFolderId: action.payload.id,
                selectedBox: action.payload,
                selectedFolder: null,
                selectedFile: null
            };
        }

        case BoxActionTypes.SET_SINGLE_DISPLAY: {
            return {
                ...state,
                singleDisplay: action.payload
            };
        }

        default: {
            return state;
        }
    }
}
