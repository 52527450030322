import { Component, OnInit } from '@angular/core';
import { TcPage } from '@tc/core';

@Component({
  selector: 'F02-login-page',
  templateUrl: './F02-login-page.component.html',
  styleUrls: ['./F02-login-page.component.scss']
})
export class F02LoginPageComponent extends TcPage implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
  }

}
