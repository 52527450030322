
import { MoveActions, MoveActionTypes } from '../actions/move.actions';
import { BreadCrumbItem } from 'src/app/modules/shared/models/breadcrumb-item';
import { initialContentState, ContentState } from '../content.state';

export function moveReducer(
    state = initialContentState,
    action: MoveActions
): ContentState {
    switch (action.type) {
        case MoveActionTypes.MOVE_ENTITY: {
            return {
                ...state,
                move: {
                    entityToMove: action.payload,
                    entitiesForMoving: [],
                    moveToEntity: null,
                    navigation: [],
                    orphans: []
                }
            };
        }
        case MoveActionTypes.MOVE_ORPHANS: {
            return {
                ...state,
                move: {
                    entityToMove: null,
                    entitiesForMoving: [],
                    moveToEntity: null,
                    navigation: [],
                    orphans: action.payload
                }
            };
        }
        case MoveActionTypes.LOAD_FOLDERS_FOR_MOVING_SUCCESS: {

            const move = state.move;

            if (move) {
                const existingNavigationItem = move.navigation.find(n => n.id === action.payload.id);

                if (existingNavigationItem) {
                    const order = existingNavigationItem.order;
                    const newItems = move.navigation.filter(n => n.order <= order);
                    move.navigation = newItems;
                } else {
                    const navigationItem = {} as BreadCrumbItem;
                    navigationItem.id = action.payload.id;
                    navigationItem.name = action.payload.name;
                    navigationItem.type = action.payload.type;
                    navigationItem.order = move.navigation.length + 1;

                    move.navigation.push(navigationItem);
                }

                move.entitiesForMoving = action.payload.folders ? action.payload.folders : [];
                move.moveToEntity = action.payload;
            }

            return {
                ...state,
                move
            };
        }
        case MoveActionTypes.LOAD_BOXES_FOR_MOVING: {

            const move = state.move;

            if (move) {
                move.entitiesForMoving = [];
                move.navigation = [];
                move.moveToEntity = null;
            }

            return {
                ...state,
                move
            };
        }
        case MoveActionTypes.MOVE_CANCEL:
        case MoveActionTypes.MOVE_ENTITY_DONE: {
            return {
                ...state,
                move: {
                    entityToMove: null,
                    entitiesForMoving: [],
                    moveToEntity: null,
                    navigation: [],
                    orphans: []
                }
            };
        }
        default: {
            return state;
        }
    }
}
