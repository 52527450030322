import { Component, OnInit } from '@angular/core';
import { TcPage } from '@tc/core';

@Component({
  selector: 'F09-file-viewer',
  templateUrl: './F09-file-viewer.component.html',
  styleUrls: ['./F09-file-viewer.component.scss']
})
export class F09FileViewerComponent extends TcPage implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
