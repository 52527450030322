import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/modules/core/store/app.state';
import { Effect, Actions, ofType, act } from '@ngrx/effects';
import {
    ContentActionTypes, DeleteAllFoldersAndFiles,
    DeleteFoldersAndFiles, RequestFinalDeleteConfirmation,
    RequestFinalDeleteConfirmationSuccess, FinalDeleteSuccess,
    FinalDelete, RestoreSelectedRows, RestoreFilesAndFolders,
    RestoreFilesAndFoldersSuccess, RestoreOrphans, RestoreOrphansSuccess, DownloadLink,ArchiveAttestation,ControleAttestation
} from '../actions/content.actions';
import { map, withLatestFrom, mergeMap, finalize, switchMap } from 'rxjs/operators';
import { RecycleBinService } from 'src/app/services/recycle-bin.service';
import { getSelectedRows } from '../selectors/content.selectors';
import { getFolders } from '../selectors/folder.selectors';
import { getFiles } from '../selectors/file.selectors';
import { ContentService } from 'src/generated/api/stockmemoriss';
import { getSelectedBox } from '../selectors/box.selectors';
import { MSMContentService } from 'src/app/services/msm-content.service';

@Injectable()
export class ContentEffects {

    constructor(
        private actions$: Actions,
        private contentService: ContentService,
        private recycleBinService: RecycleBinService,
        private msmContentService: MSMContentService,
        private store: Store<AppState>
    ) { }


    @Effect({ dispatch: false })
    deleteAllFoldersAndFiles = this.actions$.pipe(
        ofType<DeleteAllFoldersAndFiles>(ContentActionTypes.DELETE_ALL_FOLDERS_AND_FILES),
        withLatestFrom(this.store.pipe(select(getSelectedBox))),
        map(([action, binBox]) => {
            this.recycleBinService.deleteAllFoldersAndFiles(binBox);
        })
    );


    @Effect({ dispatch: false })
    deleteFoldersAndFiles = this.actions$.pipe(
        ofType<DeleteFoldersAndFiles>(ContentActionTypes.DELETE_FOLDERS_AND_FILES),
        withLatestFrom(this.store.pipe(select(getSelectedBox))),
        withLatestFrom(this.store.pipe(select(getSelectedRows))),
        withLatestFrom(this.store.pipe(select(getFolders))),
        withLatestFrom(this.store.pipe(select(getFiles))),
        map(([[[[action, binBox], selectedRows], folders], files]) => {
            this.recycleBinService.deleteFoldersAndFiles(binBox, selectedRows, folders, files);
        })
    );

    @Effect()
    requestFinalDelete = this.actions$.pipe(
        ofType<RequestFinalDeleteConfirmation>(ContentActionTypes.REQUEST_FINAL_DELETE_CONFIRMATION),
        withLatestFrom(this.store.pipe(select(getSelectedRows))),
        mergeMap(([action, selectedRows]) =>
            this.contentService.requestFinalDeleteConfirmation(action.payload.folders, action.payload.files).pipe(
                map(() =>
                    new RequestFinalDeleteConfirmationSuccess({ selectedRows, removeAll: action.payload.removeAll })
                )
            )
        )
    );

    @Effect({ dispatch: false })
    requestFinalDeleteConfirmationSuccess = this.actions$.pipe(
        ofType<RequestFinalDeleteConfirmationSuccess>(ContentActionTypes.REQUEST_FINAL_DELETE_CONFIRMATION_SUCCESS),
        map(() => {
            this.recycleBinService.finalDeleteConfirmationSuccess();
        })
    );

    @Effect()
    finalDelete = this.actions$.pipe(
        ofType<FinalDelete>(ContentActionTypes.FINAL_DELETE),
        switchMap((action: FinalDelete) => {
            let error = true;
            return this.contentService.finalDelete(action.payload).pipe(
                map((items: number) => {
                    error = false;
                    return new FinalDeleteSuccess(items);
                }),
                finalize(() => {
                    if (error) {
                        this.recycleBinService.finalDeleteError();
                    }
                })
            );
        })
    );

    @Effect({ dispatch: false })
    finalDeleteSuccess = this.actions$.pipe(
        ofType<FinalDeleteSuccess>(ContentActionTypes.FINAL_DELETE_SUCCESS),
        map((action: FinalDeleteSuccess) => {
            this.recycleBinService.finalDeleteSuccess(action.payload);
        })
    );

    @Effect({ dispatch: false })
    restoreSelectedRows = this.actions$.pipe(
        ofType<RestoreSelectedRows>(ContentActionTypes.RESTORE_SELECTED_ROWS),
        withLatestFrom(this.store.pipe(select(getSelectedRows))),
        map(([action, selectedRows]) => {
            this.recycleBinService.restoreSelectedRows(selectedRows);
        })
    );

    @Effect()
    restoreFilesAndFolders = this.actions$.pipe(
        ofType<RestoreFilesAndFolders>(ContentActionTypes.RESTORE_FILES_AND_FOLDERS),
        withLatestFrom(this.store.pipe(select(getSelectedRows))),
        mergeMap(([action, selectedRows]) =>
            this.contentService.restoreFilesAndFolders(null, action.payload).pipe(
                map((result) =>
                    new RestoreFilesAndFoldersSuccess({ selectedRows, result })
                )
            )
        )
    );

    @Effect({ dispatch: false })
    restoreFilesAndFoldersSuccess = this.actions$.pipe(
        ofType<RestoreFilesAndFoldersSuccess>(ContentActionTypes.RESTORE_FILES_AND_FOLDERS_SUCCESS),
        map((action: RestoreFilesAndFoldersSuccess) => {
            this.recycleBinService.restoreFilesAndFoldersSuccess(action.payload.selectedRows, action.payload.result);
        })
    );


    @Effect()
    restoreOrphans = this.actions$.pipe(
        ofType<RestoreOrphans>(ContentActionTypes.RESTORE_ORPHANS),
        mergeMap((action: RestoreOrphans) =>
            this.contentService.restoreFilesAndFolders(action.payload.newParentId, action.payload.model).pipe(
                map((result) =>
                    new RestoreOrphansSuccess()
                )
            )
        )
    );

    @Effect({ dispatch: false })
    restoreOrphansSuccess = this.actions$.pipe(
        ofType<RestoreOrphansSuccess>(ContentActionTypes.RESTORE_ORPHANS_SUCCESS),
        map((action: RestoreOrphansSuccess) => {
            this.recycleBinService.restoreOrphansSuccess();
        })
    );

    @Effect({ dispatch: false })
    downloadLink = this.actions$.pipe(
        ofType<DownloadLink>(ContentActionTypes.DOWNLOAD_LINK),
        map((action: DownloadLink) => {
            this.msmContentService.downloadLink(action.payload);
        })
    );

    @Effect({ dispatch: false })
    attestationArchive = this.actions$.pipe(
        ofType<ArchiveAttestation>(ContentActionTypes.ATTESTATION_ARCHIVE),
        map((action: ArchiveAttestation) => {
            this.msmContentService.archiveAttestation(action.payload);
        })
    );

    @Effect({ dispatch: false })
    attestationControle = this.actions$.pipe(
        ofType<ControleAttestation>(ContentActionTypes.ATTESTATION_CONTROLE),
        map((action: ControleAttestation) => {
            this.msmContentService.controleAttestation(action.payload);
        })
    );

}
