import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { TcDumbComponent } from '@tc/core';

@Component({
  selector: 'documents-footer',
  templateUrl: './documents-footer.component.html',
  styleUrls: ['./documents-footer.component.scss']
})
export class DocumentsFooterComponent extends TcDumbComponent implements OnInit {

  @Input()
  canAddFolder = true;

  @Input()
  canAddFile = true;

  @Input()
  canScanFile = true;

  @Output()
  addFolder: EventEmitter<any> = new EventEmitter();

  @Output()
  addFile: EventEmitter<any> = new EventEmitter();

  @Output()
  scanFile: EventEmitter<any> = new EventEmitter();

  footerButtonsVisible = false;

  constructor() {
    super();
  }

  ngOnInit() {

  }

  onAddButtonClick() {
    this.footerButtonsVisible = true;
  }

  onAddFolder() {
    this.addFolder.emit();
  }

  onAddFile() {
    this.addFile.emit();
  }

  onScanFile() {
    this.scanFile.emit();
  }


}
