import { Component, OnInit, ViewChild, OnDestroy, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { TcListComponent, TcAuthenticationService } from '@tc/core';
import { LoadExpiredFiles, DeleteSelectedExpiredFiles, ExtendsSelectedExpiredFiles } from '../../../store/actions/expired-files.actions';
import { getExpiredFiles } from '../../../store/selectors/expired-files.selectors';
import { getSelectedRows } from '../../../store/selectors/content.selectors';
import { SelectRows, DeselectAllRows, DeselectRows } from '../../../store/actions/content.actions';
import { ContentState } from '../../../store/content.state';
import { RouterService } from 'src/app/services/router.service';
import { Router } from '@angular/router';

@Component({
  selector: 'expired-files',
  templateUrl: './expired-files.component.html',
  styleUrls: ['./expired-files.component.scss']
})
export class ExpiredFilesComponent implements OnInit, OnDestroy {

  private selectionSubscription: Subscription;
  private selectedExpiredFilesSubscription: Subscription;

  expiredFilesList: TcListComponent;
  @ViewChild('expiredFilesList', { static: true }) set setExpiredFilesList(values: TcListComponent) {
    this.expiredFilesList = values;
  }

  @ViewChild('colExpirationTemplate', { static: true }) colExpirationTemplate: TemplateRef<any>;
  @ViewChild('colNameTemplate', { static: true }) colNameTemplate: TemplateRef<any>;
  @ViewChild('colBoxTemplate', { static: true }) colBoxTemplate: TemplateRef<any>;


  constructor(
    private router: Router,
    private store: Store<ContentState>,
    private authService: TcAuthenticationService,
    private routerService: RouterService) {
  }

  ngOnInit() {

    if (!this.authService.isAuthenticated()) {
      this.routerService.goToLoginPageWithReturnUrl(this.router.url);
    }

    this.store.dispatch(new LoadExpiredFiles());

    this.expiredFilesList.rows$ = this.store.pipe(select(getExpiredFiles));

    this.expiredFilesList.hasFixedHeader = true;
    this.expiredFilesList.hasActionsLabel = false;
    this.expiredFilesList.isPaged = false;
    this.expiredFilesList.isFiltrable = false;
    this.expiredFilesList.columns = [
      {
        propertyName: 'expiration',
        visible: true,
        filtrable: false,
        htmlTemplate: this.colExpirationTemplate,
      },
      {
        propertyName: 'name',
        visible: true,
        filtrable: false,
        htmlTemplate: this.colNameTemplate,
      },
      {
        propertyName: 'box',
        visible: true,
        filtrable: false,
        htmlTemplate: this.colBoxTemplate,
      }
    ];

    this.expiredFilesList.bulkActions = [
      {
        actionName: 'select',
        visible: true
      }
    ];

    this.selectionSubscription = this.expiredFilesList.selection.changed.subscribe(selection => {
      if (selection.added.length > 0) {
        this.store.dispatch(new SelectRows(selection.added));
      }
      if (selection.removed.length > 0) {
        this.store.dispatch(new DeselectRows(selection.removed));
      }
    });

    this.selectedExpiredFilesSubscription = this.store.pipe(select(getSelectedRows)).subscribe(selectedExpiredFiles => {
      if (!selectedExpiredFiles || selectedExpiredFiles.length === 0) {
        this.expiredFilesList.selection.clear();
      }
    });

  }

  ngOnDestroy(): void {

    if (this.selectionSubscription) {
      this.selectionSubscription.unsubscribe();
    }

    if (this.selectedExpiredFilesSubscription) {
      this.selectedExpiredFilesSubscription.unsubscribe();
    }

  }

  cancel() {
    this.store.dispatch(new DeselectAllRows());
  }
  delete() {
    this.store.dispatch(new DeleteSelectedExpiredFiles());
  }
  extend() {
    this.store.dispatch(new ExtendsSelectedExpiredFiles());
  }

}
