import { TcAction } from '@tc/core';
import { SimpleFile, Share, ExpiredFile } from 'src/generated/api/stockmemoriss';

export enum FileActionTypes {
    // FILE ACTIONS
    ADD_FILE = '[Content] Add File',
    ADD_FILE_SUCCESS = '[Content] Add File Success',
    EDIT_FILE = '[Content] Edit File',
    EDIT_FILE_SUCCESS = '[Content] Edit File Success',
    REQUEST_DELETE_FILE = '[Content] Request Delete File',
    DELETE_FILE = '[Content] Delete File',
    DELETE_FILE_SUCCESS = '[Content] Delete File Success',
    SELECT_FILE = '[Content] Select File',
    DOWNLOAD_FILE = '[Content] Download File',
    // SEARCH ACTIONS
    SEARCH_FILES = '[Content] Search Files',
    SEARCH_FILES_SUCCESS = '[Content] Search Files Success',
    // SHARE FILE
    SHARE_FILE = '[Content] Share File',
    // SHARE FILE BY LINK
    SHARE_FILE_BY_LINK = '[Content] Share File By Link',
    SHARE_FILE_BY_LINK_SUCCESS = '[Content] Share File By Link Success',
    // SHARE FILE BY EMAIL
    SHARE_FILE_BY_EMAIL = '[Content] Share File By Email',
    SHARE_FILE_BY_EMAIL_SUCCESS = '[Content] Share File By Email Success',
    // SCANNED IMAGE ACTIONS
    ADD_SCANNED_IMAGE = '[Content] Add Scanned Image',
    ADD_SCANNED_IMAGE_SUCCESS = '[Content] Add Scanned Image Success'
}


export class AddFile implements TcAction {
    readonly type = FileActionTypes.ADD_FILE;
    constructor(public payload: SimpleFile) { }
}

export class AddFileSuccess implements TcAction {
    readonly type = FileActionTypes.ADD_FILE_SUCCESS;
    constructor(public payload: SimpleFile) { }
}

export class EditFile implements TcAction {
    readonly type = FileActionTypes.EDIT_FILE;
    constructor(public payload: { file: SimpleFile, type: string }) { }
}

export class EditFileSuccess implements TcAction {
    readonly type = FileActionTypes.EDIT_FILE_SUCCESS;
    constructor(public payload: { file: SimpleFile, type: string }) { }
}

export class DeleteFile implements TcAction {
    readonly type = FileActionTypes.DELETE_FILE;
    constructor(public payload: number) { }
}

export class DeleteFileSuccess implements TcAction {
    readonly type = FileActionTypes.DELETE_FILE_SUCCESS;
    constructor(public payload: number) { }
}

export class RequestDeleteFile implements TcAction {
    readonly type = FileActionTypes.REQUEST_DELETE_FILE;
    constructor(public payload: SimpleFile) { }
}

export class SelectFile implements TcAction {
    readonly type = FileActionTypes.SELECT_FILE;
    constructor(public payload: SimpleFile) { }
}

export class DownloadFile implements TcAction {
    readonly type = FileActionTypes.DOWNLOAD_FILE;
    constructor(public payload: number) { }
}


export class SearchFiles implements TcAction {
    readonly type = FileActionTypes.SEARCH_FILES;
    constructor(public payload: string) { }
}

export class SearchFilesSuccess implements TcAction {
    readonly type = FileActionTypes.SEARCH_FILES_SUCCESS;
    constructor(public payload: SimpleFile[]) { }
}

export class AddScannedImage implements TcAction {
    readonly type = FileActionTypes.ADD_SCANNED_IMAGE;
    constructor(public payload: { image: File, parentFolderId: number }) { }
}

export class AddScannedImageSuccess implements TcAction {
    readonly type = FileActionTypes.ADD_SCANNED_IMAGE_SUCCESS;
    constructor(public payload: SimpleFile) { }
}

export class ShareFile implements TcAction {
    readonly type = FileActionTypes.SHARE_FILE;
    constructor(public payload: SimpleFile) { }
}

export class ShareFileByEmail implements TcAction {
    readonly type = FileActionTypes.SHARE_FILE_BY_EMAIL;
    constructor(public payload: { fileId: number, duration?: number, password?: string }) { }
}

export class ShareFileByEmailSuccess implements TcAction {
    readonly type = FileActionTypes.SHARE_FILE_BY_EMAIL_SUCCESS;
    constructor(public payload: string) { }
}

export class ShareFileByLink implements TcAction {
    readonly type = FileActionTypes.SHARE_FILE_BY_LINK;
    constructor(public payload: { fileId: number, duration?: number, password?: string }) { }
}

export class ShareFileByLinkSuccess implements TcAction {
    readonly type = FileActionTypes.SHARE_FILE_BY_LINK_SUCCESS;
    constructor(public payload: string) { }
}

export type FileActions =
    | AddFile
    | AddFileSuccess
    | EditFile
    | EditFileSuccess
    | DeleteFile
    | DeleteFileSuccess
    | RequestDeleteFile
    | SelectFile
    | DownloadFile
    | SearchFiles
    | SearchFilesSuccess
    | AddScannedImage
    | AddScannedImageSuccess
    | ShareFile
    | ShareFileByEmail
    | ShareFileByEmailSuccess
    | ShareFileByLink
    | ShareFileByLinkSuccess;
