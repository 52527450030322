import { Component, OnInit } from '@angular/core';
import { TcSmartComponent, TcAuthenticationService } from '@tc/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { RouterService } from 'src/app/services/router.service';
import { ExtendExpirePeriod } from '../../../store/actions/expired-files.actions';
import { ContentState } from '../../../store/content.state';

@Component({
  selector: 'confirm-extend',
  templateUrl: './confirm-extend.component.html',
  styleUrls: ['./confirm-extend.component.scss']
})
export class ConfirmExtendComponent extends TcSmartComponent implements OnInit {

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private store: Store<ContentState>,
    private authService: TcAuthenticationService,
    private routerService: RouterService) {
    super();
  }

  ngOnInit() {

    if (!this.authService.isAuthenticated()) {
      this.routerService.goToLoginPageWithReturnUrl(this.router.url);
    } else {
      const routeParams = this.route.snapshot.queryParams;
      const code = routeParams.code;

      if (code) {
        this.store.dispatch(new ExtendExpirePeriod(code));
      } else {
        this.routerService.goToHomePage();
      }
    }

  }

}
