import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TcAppState, TcSmartComponent } from '@tc/core';
import { Store } from '@ngrx/store';
import { OpenSharedFolderOrFile } from '../../../store/actions/share.actions';

@Component({
  selector: 'share-file-or-folder',
  templateUrl: './share-file-or-folder.component.html',
  styleUrls: ['./share-file-or-folder.component.scss']
})
export class ShareFileOrFolderComponent extends TcSmartComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store<TcAppState>) {
    super();
  }

  ngOnInit() {

    // current url of type /share-file/:id or /share-folder/:id
    const sharedUrl = this.router.url;

    // extract shared id
    const sharedId = this.activatedRoute.snapshot.paramMap.get('id');

    this.store.dispatch(new OpenSharedFolderOrFile({ sharedUrl, sharedId }));

  }

}
