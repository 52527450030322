import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TcActivatedGuard } from '@tc/core';
import { F02LoginPageComponent } from './pages/F02-login-page/F02-login-page.component';
import { F02ForgetPasswordPageComponent } from './pages/F02-forget-password-page/F02-forget-password-page.component';
import { F02PasswordExpiredPageComponent } from './pages/F02-password-expired-page/F02-password-expired-page.component';
import { F03HomePageComponent } from './pages/F03-home-page/F03-home-page.component';
import { F29ListDocumentsComponent } from './pages/F29-list-documents/F29-list-documents.component';
import { F01CreateAccountComponent } from './pages/F01-create-account/F01-create-account.component';
import { F28CGUAcceptComponent } from './pages/F28-CGU-accept/F28-CGU-accept.component';
import { F34PaymentComponent } from './pages/F34-payment/F34-payment.component';
import { F01EmailVerficationComponent } from './pages/F01-email-verification/F01-email-verification.component';
import { F01ConfirmEmailComponent } from './pages/F01-confirm-email/F01-confirm-email.component';
import { F04ManageAccountComponent } from './pages/F04-manage-account/F04-manage-account.component';
import { F09FileViewerComponent } from './pages/F09-file-viewer/F09-file-viewer.component';
import { F07SimpleSearchComponent } from './pages/F07-simple-search/F07-simple-search.component';
import { F30ContactTeamComponent } from './pages/F30-contact-team/F30-contact-team.component';
import { F30ContactSupportComponent } from './pages/F30-contact-support/F30-contact-support.component';
import { F05SetHeirAccountComponent } from './pages/F05-set-heir-account/F05-set-heir-account.component';
import { F17BoxEmailAddressesComponent } from './pages/F17-box-email-addresses/F17-box-email-addresses.component';
import { MasterPageComponent } from './pages/master-page/master-page.component';
import { F32FaqComponent } from './pages/F32-faq/F32-faq.component';
import { F13MoveFolderOrFileComponent } from './pages/F13-move-folder-or-file/F13-move-folder-or-file.component';
import { F18JournalComponent } from './pages/F18-journal/F18-journal.component';
import { F19JournalModificationsComponent } from './pages/F19-journal-modifications/F19-journal-modifications.component';
import { ShareFileOrFolderComponent } from './modules/content/components/smart/share-file-or-folder/share-file-or-folder.component';
import { I18NResolver } from './modules/core/resolvers/i18n.resolver';
import { ConfirmDeleteComponent } from './modules/content/components/smart/confirm-delete/confirm-delete.component';
import { F37ExpiredFilesComponent } from './pages/F37-expired-files/F37-expired-files.component';
import { F39CertificateComponent } from './pages/F39-certificate/F39-certificate.component';
import { SelectFilesForCertificateComponent } from './modules/content/components/smart/select-files-for-certificate/select-files-for-certificate.component';
import { CertificatePaymentComponent } from './modules/content/components/smart/certificate-payment/certificate-payment.component';
import { RouteOptions } from './modules/core/enums/route-options';
import { ConfirmExtendComponent } from './modules/content/components/smart/confirm-extend/confirm-extend.component';
import { DownloadLinkComponent } from './modules/content/components/smart/download-link/download-link.component';

const routes: Routes = [
  {
    path: RouteOptions.Login,
    component: F02LoginPageComponent
  },
  {
    path: RouteOptions.SignUp,
    component: F01CreateAccountComponent
  },
  {
    path: RouteOptions.ForgetPassword,
    component: F02ForgetPasswordPageComponent
  },
  {
    path: RouteOptions.PasswordExpired,
    component: F02PasswordExpiredPageComponent
  },
  {
    path: RouteOptions.Payment,
    component: F34PaymentComponent
  },
  {
    path: RouteOptions.EmailVerification,
    component: F01EmailVerficationComponent
  },
  {
    path: RouteOptions.ConfirmEmail,
    component: F01ConfirmEmailComponent
  },
  {
    path: RouteOptions.CGUAccept,
    component: F28CGUAcceptComponent
  },
  {
    path: RouteOptions.ShareFile,
    component: ShareFileOrFolderComponent
  },
  {
    path: RouteOptions.ShareFolder,
    component: ShareFileOrFolderComponent
  },
  {
    path: '',
    component: MasterPageComponent,
    canActivate: [TcActivatedGuard],
    children: [
      {
        outlet: 'master-page',
        path: '',
        component: F03HomePageComponent,
        resolve: { i18n: I18NResolver }
      }
    ]
  },
  {
    path: RouteOptions.ListDocuments,
    component: MasterPageComponent,
    canActivate: [TcActivatedGuard],
    children: [
      {
        outlet: 'master-page',
        path: '',
        component: F29ListDocumentsComponent
      }
    ]
  },
  {
    path: RouteOptions.FileViewer,
    component: MasterPageComponent,
    canActivate: [TcActivatedGuard],
    children: [
      {
        outlet: 'master-page',
        path: '',
        component: F09FileViewerComponent
      }
    ]
  },
  {
    path: RouteOptions.MyAccount,
    component: MasterPageComponent,
    canActivate: [TcActivatedGuard],
    children: [
      {
        outlet: 'master-page',
        path: '',
        component: F04ManageAccountComponent
      }
    ]
  },
  {
    path: RouteOptions.Search,
    component: MasterPageComponent,
    canActivate: [TcActivatedGuard],
    children: [
      {
        outlet: 'master-page',
        path: '',
        component: F07SimpleSearchComponent
      }
    ]
  },
  {
    path: RouteOptions.ContactTeam,
    component: MasterPageComponent,
    canActivate: [TcActivatedGuard],
    children: [
      {
        outlet: 'master-page',
        path: '',
        component: F30ContactTeamComponent
      }
    ]
  },
  {
    path: RouteOptions.ContactSupport,
    component: MasterPageComponent,
    canActivate: [TcActivatedGuard],
    children: [
      {
        outlet: 'master-page',
        path: '',
        component: F30ContactSupportComponent
      }
    ]
  },
  {
    path: RouteOptions.Settings,
    component: MasterPageComponent,
    canActivate: [TcActivatedGuard],
    children: [
      {
        outlet: 'master-page',
        path: '',
        component: F05SetHeirAccountComponent
      }
    ]
  },
  {
    path: RouteOptions.BoxAddress,
    component: MasterPageComponent,
    canActivate: [TcActivatedGuard],
    children: [
      {
        outlet: 'master-page',
        path: '',
        component: F17BoxEmailAddressesComponent
      }
    ]
  },
  {
    path: RouteOptions.FAQ,
    component: MasterPageComponent,
    canActivate: [TcActivatedGuard],
    children: [
      {
        outlet: 'master-page',
        path: '',
        component: F32FaqComponent
      }
    ]
  },
  {
    path: RouteOptions.Move,
    component: F13MoveFolderOrFileComponent,
    canActivate: [TcActivatedGuard]
  },
  {
    path: RouteOptions.Journal,
    component: MasterPageComponent,
    canActivate: [TcActivatedGuard],
    children: [
      {
        outlet: 'master-page',
        path: '',
        component: F18JournalComponent
      }
    ]
  },
  {
    path: RouteOptions.JournalModifications,
    component: MasterPageComponent,
    canActivate: [TcActivatedGuard],
    children: [
      {
        outlet: 'master-page',
        path: '',
        component: F19JournalModificationsComponent
      }
    ]
  },
  {
    path: RouteOptions.ConfirmDelete,
    component: ConfirmDeleteComponent
  },
  {
    path: 'expired-files',
    component: MasterPageComponent,
    // canActivate: [TcActivatedGuard],
    children: [
      {
        outlet: 'master-page',
        path: '',
        component: F37ExpiredFilesComponent
      }
    ]
  },
  {
    path: RouteOptions.Certificate,
    component: MasterPageComponent,
    canActivate: [TcActivatedGuard],
    children: [
      {
        outlet: 'master-page',
        path: '',
        component: F39CertificateComponent
      }
    ]
  },
  {
    path: RouteOptions.SelectFilesForCertificate,
    component: SelectFilesForCertificateComponent,
    canActivate: [TcActivatedGuard]
  },
  {
    path: RouteOptions.CertificatePayment,
    component: CertificatePaymentComponent,
    canActivate: [TcActivatedGuard]
  },
  {
    path: RouteOptions.ConfirmProlongation,
    component: ConfirmExtendComponent
  },
  {
    path: RouteOptions.DownloadLink,
    component: DownloadLinkComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
