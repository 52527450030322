import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { TcSmartComponent, TcTranslateService } from '@tc/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { Box, Folder, SimpleFile, AbstractFile } from 'src/generated/api/stockmemoriss';
import {
  getSelectFoldersAndFilesBoxes, getSelectFoldersAndFilesFolders,
  getSelectFoldersAndFilesFiles, getSelectFoldersAndFilesSelectedFolderId,
  getSelectFoldersAndFilesNavigation, getSelectFoldersAndFilesSelectedRows
} from '../../../store/selectors/select-folders-and-files.selectors';
import {
  SelectFoldersAndFilesLoadFolderContent,
  SelectFoldersAndFilesLoadBoxes,
  SelectFoldersAndFilesSelectRows,
  SelectFoldersAndFilesDeselectRows
} from '../../../store/actions/select-folders-and-files.actions';
import { BreadCrumbItem } from 'src/app/modules/shared/models/breadcrumb-item';
import { ContentState } from '../../../store/content.state';

@Component({
  selector: 'select-folders-and-files',
  templateUrl: './select-folders-and-files.component.html',
  styleUrls: ['./select-folders-and-files.component.scss']
})
export class SelectFoldersAndFilesComponent extends TcSmartComponent implements OnInit, OnDestroy {

  // list of boxes
  boxes$: Observable<Box[]>;

  // list of folders
  folders$: Observable<Folder[]>;

  // list of files
  files$: Observable<SimpleFile[]>;

  // selectedRows
  selectedRows$: Observable<AbstractFile[]>;

  selectedFolderId$: Observable<number>;

  // breadcrumb navigation items
  navigationItems: BreadCrumbItem[] = [];
  navigationItemsSubscription: Subscription;

  @Output()
  cancel: EventEmitter<any> = new EventEmitter();

  @Output()
  done: EventEmitter<any> = new EventEmitter();

  constructor(
    private store: Store<ContentState>,
    private translate: TcTranslateService
  ) {
    super();
  }

  ngOnInit() {

    // on init => get list of boxes
    this.store.dispatch(new SelectFoldersAndFilesLoadBoxes());

    this.boxes$ = this.store.pipe(select(getSelectFoldersAndFilesBoxes));
    this.folders$ = this.store.pipe(select(getSelectFoldersAndFilesFolders));
    this.files$ = this.store.pipe(select(getSelectFoldersAndFilesFiles));

    this.selectedFolderId$ = this.store.pipe(select(getSelectFoldersAndFilesSelectedFolderId));

    // breadcrumb navigation items
    this.navigationItemsSubscription = this.store.pipe(select(getSelectFoldersAndFilesNavigation)).subscribe(items => {
      // if there are no navigation items => in screen is displayed a box content => we need to add manually "My boxes" menu
      if (items && items.length === 0) {
        const boxesName = this.translate.instant('select-folders-and-files.boxes');
        items.unshift({ id: 0, name: boxesName, type: 'boxes', order: 0 });
      }
      this.navigationItems = items;
    });

    this.selectedRows$ = this.store.pipe(select(getSelectFoldersAndFilesSelectedRows));

  }

  ngOnDestroy() {
    if (this.navigationItemsSubscription) {
      this.navigationItemsSubscription.unsubscribe();
    }
  }

  onSelectBox(box: Box) {
    this.store.dispatch(new SelectFoldersAndFilesLoadFolderContent(box.id));
  }

  onSelectFolder(folder: Folder) {
    this.store.dispatch(new SelectFoldersAndFilesLoadFolderContent(folder.id));

  }

  onSelectFile(file: SimpleFile) {
  }

  onSelectRows(rows: AbstractFile[]) {
    this.store.dispatch(new SelectFoldersAndFilesSelectRows(rows));
  }

  onDeselectRows(rows: AbstractFile[]) {
    this.store.dispatch(new SelectFoldersAndFilesDeselectRows(rows));
  }

  // click on breadcrumb navigation item
  // if (item.id !== 0) => item is a folder => load folder content
  // if (item.id == 0) => item is "My boxes" => display list of boxes
  onSelectNavigationItem(item: BreadCrumbItem) {
    if (item) {
      if (item.id !== 0) {
        this.store.dispatch(new SelectFoldersAndFilesLoadFolderContent(item.id));
      } else {
        this.store.dispatch(new SelectFoldersAndFilesLoadBoxes());
      }
    }
  }

  onCancel() {
    this.cancel.emit();
  }

  onDone() {
    this.done.emit();
  }

}
