import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './components/smart/login/login.component';
import { TcCoreModule } from '@tc/core';
import { SignUpComponent } from './components/smart/sign-up/sign-up.component';
import { ForgetPasswordComponent } from './components/smart/forget-password/forget-password.component';
import { PasswordExpiredComponent } from './components/smart/password-expired/password-expired.component';
import { PaymentComponent } from './components/smart/payment/payment.component';
import { AccountHeaderComponent } from './components/dumb/account-header/account-header.component';
import { EmailVerificationComponent } from './components/smart/email-verification/email-verification.component';
import { StoreModule } from '@ngrx/store';
import { AccountEffects } from './store/account.effects';
import { EffectsModule } from '@ngrx/effects';
import { accountReducer } from './store/account.reducer';
import { ConfirmEmailComponent } from './components/smart/confirm-email/confirm-email.component';
import { ManageAccountComponent } from './components/smart/manage-account/manage-account.component';
import { AccountDetailComponent } from './components/smart/account-detail/account-detail.component';
import { ModifyPasswordComponent } from './components/smart/modify-password/modify-password.component';
import { PasswordRestitutionComponent } from './components/smart/password-restitution/password-restitution.component';
import { PasswordRestitutionUseComponent } from './components/smart/password-restitution-use/password-restitution-use.component';
import { SetHeirAccountComponent } from './components/smart/set-heir-account/set-heir-account.component';
import { HeirDetailComponent } from './components/smart/heir-detail/heir-detail.component';
import { HeirEffects } from './store/heir.effects';
import { FormlyModule } from '@ngx-formly/core';
import { DatepickerComponent } from '../core/components/datepicker/datepicker.component';

const components = [
  LoginComponent,
  SignUpComponent,
  ForgetPasswordComponent,
  PasswordExpiredComponent,
  PaymentComponent,
  AccountHeaderComponent,
  EmailVerificationComponent,
  ConfirmEmailComponent,
  ManageAccountComponent,
  AccountDetailComponent,
  PasswordRestitutionComponent,
  PasswordRestitutionUseComponent,
  ModifyPasswordComponent,
  SetHeirAccountComponent,
  HeirDetailComponent,
  DatepickerComponent
];
@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    CommonModule,
    TcCoreModule,
    FormlyModule.forRoot({
      types: [{
        name: 'datepicker',
        component: DatepickerComponent,
        wrappers: ['form-field'],
        defaultOptions: {
          defaultValue: new Date(),
          templateOptions: {
            datepickerOptions: {},
          },
        },
      }]
    }),
    StoreModule.forFeature('account', accountReducer),
    EffectsModule.forFeature([AccountEffects, HeirEffects]),
  ],
  exports: [
    ...components
  ],
  entryComponents: [
    AccountDetailComponent,
    PasswordRestitutionComponent,
    PasswordRestitutionUseComponent,
    ModifyPasswordComponent,
    HeirDetailComponent
  ]
})
export class AccountModule { }
