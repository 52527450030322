import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TcCoreModule } from '@tc/core';
import { FileViewerComponent } from './components/smart/file-viewer/file-viewer.component';
import { FileViewerHeaderComponent } from './components/smart/file-viewer-header/file-viewer-header.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';

const components = [
  FileViewerComponent,
  FileViewerHeaderComponent
];

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    CommonModule,
    TcCoreModule,
    PdfViewerModule
  ],
  exports: [
    ...components
  ]
})
export class FileViewerModule { }
