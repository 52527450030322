import { Component, OnInit, ViewChild, TemplateRef, Input, Output, EventEmitter } from '@angular/core';
import { Journal } from 'src/generated/api/stockmemoriss';
import { TcListComponent, TcSmartComponent } from '@tc/core';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material';
import { JournalItemComponent } from '../journal-item/journal-item.component';

@Component({
  selector: 'journal-results',
  templateUrl: './journal-results.component.html',
  styleUrls: ['./journal-results.component.scss']
})
// todo: move to smart components
export class JournalResultsComponent extends TcSmartComponent implements OnInit {

  @Input()
  journalResults$: Observable<Journal[]>;

  @Output()
  scrollAction: EventEmitter<any> = new EventEmitter();

  resultsList: TcListComponent;

  @ViewChild('colNameTemplate', { static: true }) colNameTemplate: TemplateRef<any>;
  @ViewChild('colDateTemplate', { static: true }) colDateTemplate: TemplateRef<any>;
  @ViewChild('colResponsableTemplate', { static: true }) colResponsableTemplate: TemplateRef<any>;
  @ViewChild('colActionTemplate', { static: true }) colActionTemplate: TemplateRef<any>;

  @ViewChild('resultsList', { static: true }) set appResultsList(values: TcListComponent) {
    this.resultsList = values;
  }

  constructor(
    private dialog: MatDialog
  ) {
    super();
  }

  ngOnInit() {
    this.resultsList.rows$ = this.journalResults$;
    this.resultsList.hasActionsLabel = false;
    this.resultsList.isPaged = false;
    this.resultsList.isFiltrable = false;
    this.resultsList.hasFixedHeader = true;

    this.resultsList.onRowClick = (row: any) => {
      this.dialog.open(JournalItemComponent, {
        data: row,
        maxHeight: '300px'
      });
    };

    this.resultsList.columns = [
      {
        propertyName: 'horodatage',
        visible: true,
        filtrable: false,
        htmlTemplate: this.colDateTemplate,
      },
      {
        propertyName: 'id_on_uti',
        visible: true,
        filtrable: false,
        htmlTemplate: this.colNameTemplate,
      },
      {
        propertyName: 'id_uti',
        visible: true,
        filtrable: false,
        htmlTemplate: this.colResponsableTemplate
      },
      {
        propertyName: 'fonction',
        visible: true,
        filtrable: false,
        htmlTemplate: this.colActionTemplate
      }
    ];

    this.resultsList.onScrollDown = () => {
      this.scrollAction.emit();
    };
  }
}
