import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { TcFormComponent, TcTranslateService, TcNotificationService } from '@tc/core';
import { Store, select } from '@ngrx/store';
import { ForgetPassword } from 'src/app/modules/account/store/account.actions';
import { User } from 'src/generated/api/stockmemoriss';
import { AccountState } from '../../../store/account.reducer';
import { Utils } from 'src/app/modules/core/utils';
import { Router, ActivatedRoute } from '@angular/router';
import { RouterService } from 'src/app/services/router.service';
import { Subscription } from 'rxjs';
import { Email } from 'src/generated/api/stockmemoriss/model/email';

@Component({
  selector: 'forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent extends TcFormComponent<User> implements OnInit {

  sharedUrl: string;

  email: Email;
  

  constructor(
    private router: Router,
    private store: Store<AccountState>,
    private notificationService: TcNotificationService,
    private route: ActivatedRoute,
    private routerService: RouterService,
    translate: TcTranslateService,
    element: ElementRef) {

    super(translate, element);

    this.fields = [   
      {
        key: 'username',
        type: 'input',
        templateOptions: {
          type: 'email',
          required: true
        },
      }
    ];
  }
  ngOnInit() {

    super.ngOnInit();

    const sharedUrl = this.route.snapshot.queryParams.sharedUrl;

    if (sharedUrl) {
      this.sharedUrl = sharedUrl;
    }

  }

  submit(email) {
    if (this.form.valid && this.validateEmail(email.username)) {
      this.store.dispatch(new ForgetPassword({ email: email.username, sharedUrl: this.sharedUrl }));
    }
  }

  validateEmail(email: string): boolean {
    if (!Utils.isValidEmail(email)) {
      this.notificationService.error(this.translate.instant('account-detail.email-format-error'));
      return false;
    }
    return true;
  }
}
