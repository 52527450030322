import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { Folder } from 'src/generated/api/stockmemoriss';
import { Observable } from 'rxjs';
import { TcMenuComponent, TcMenuItem, TcListComponent, TcDumbComponent } from '@tc/core';

@Component({
  selector: 'msm-navigate-folders-only',
  templateUrl: './msm-navigate-folders-only.component.html',
  styleUrls: ['./msm-navigate-folders-only.component.scss']
})
export class MsmNavigateFoldersOnlyComponent extends TcDumbComponent implements OnInit {

  @Input()
  folders$: Observable<Folder[]>;

  @Input()
  hasMenu = true;

  @Input()
  menuItems = [];

  @Output()
  selectFolder: EventEmitter<Folder> = new EventEmitter();

  @Output()
  menuFolder: EventEmitter<any> = new EventEmitter();

  folderList: TcListComponent;
  @ViewChild('folderList', { static: true }) set appFolderList(values: TcListComponent) {
    this.folderList = values;
  }

  folderRowMenu: TcMenuComponent;
  @ViewChild('folderRowMenu', { static: true }) set appFolderRowMenu(value: TcMenuComponent) {
    this.folderRowMenu = value;
  }

  @ViewChild('colNameTemplate', { static: true }) colNameTemplate: TemplateRef<any>;

  constructor() {
    super();
  }

  ngOnInit() {

    this.folderList.rows$ = this.folders$;
    this.folderList.hasActionsLabel = false;
    this.folderList.isPaged = false;
    this.folderList.isFiltrable = false;

    this.folderList.columns = [
      {
        propertyName: 'name',
        visible: true,
        filtrable: false,
        htmlTemplate: this.colNameTemplate,
      }
    ];

    this.folderRowMenu.items = this.menuItems;

    this.folderRowMenu.onMenuItemClick = (item: TcMenuItem, row: Folder) => {
      this.menuFolder.emit({ item, row });
    };
  }

  onSelectFolder(folder: Folder) {
    if (folder) {
      this.selectFolder.emit(folder);
    }
  }

}
