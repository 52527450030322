import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { ShareService } from 'src/app/services/share.service';
import { map, withLatestFrom } from 'rxjs/operators';
import { OpenSharedFolderOrFile, ShareActionTypes, OpenSharedFile, OpenSharedFolder } from '../actions/share.actions';
import { AppState } from 'src/app/modules/core/store/app.state';
import { Store } from '@ngrx/store';

@Injectable()
export class ShareEffects {

    constructor(
        private store$: Store<AppState>,
        private actions$: Actions,
        private shareService: ShareService
    ) { }

    @Effect({ dispatch: false })
    openSharedFolderOrFile = this.actions$.pipe(
        ofType<OpenSharedFolderOrFile>(ShareActionTypes.OPEN_SHARED_FOLDER_OR_FILE),
        withLatestFrom(this.store$.select(state => state.account.user)),
        map(([action, currentUser]) => {
            this.shareService.openSharedFolderOrFile(action.payload.sharedUrl, action.payload.sharedId, currentUser);
        })
    );

    @Effect({ dispatch: false })
    openSharedFile = this.actions$.pipe(
        ofType<OpenSharedFile>(ShareActionTypes.OPEN_SHARED_FILE),
        map((action: OpenSharedFile) => {
            this.shareService.viewSharedFile(action.payload.file, action.payload.sharedUrl);
        })
    );

    @Effect({ dispatch: false })
    openSharedFolder = this.actions$.pipe(
        ofType<OpenSharedFolder>(ShareActionTypes.OPEN_SHARED_FOLDER),
        map((action: OpenSharedFolder) => {
            this.shareService.viewSharedFolder(action.payload.sharedUrl);
        })
    );

}
