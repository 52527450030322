import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/modules/core/store/app.state';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs/operators';
import {
    SelectFoldersAndFilesActionTypes,
    SelectFoldersAndFilesLoadBoxes,
    SelectFoldersAndFilesLoadBoxesSuccess,
    SelectFoldersAndFilesLoadFolderContent,
    SelectFoldersAndFilesLoadFolderContentSuccess
} from '../actions/select-folders-and-files.actions';
import { ContentService, Box, AbstractFile } from 'src/generated/api/stockmemoriss';
import { TcAction } from '@tc/core';
import { Observable } from 'rxjs';

@Injectable()
export class SelectFoldersAndFilesEffects {
    constructor(
        private actions$: Actions,
        private contentService: ContentService
    ) { }


    @Effect()
    selectFoldersAndFilesLoadBoxes$: Observable<TcAction> = this.actions$.pipe(
        ofType<SelectFoldersAndFilesLoadBoxes>(
            SelectFoldersAndFilesActionTypes.SELECT_FOLDERS_AND_FILES_LOAD_BOXES
        ),
        mergeMap((action: SelectFoldersAndFilesLoadBoxes) =>
            this.contentService.listBoxes().pipe(
                map(
                    (items: Box[]) =>
                        new SelectFoldersAndFilesLoadBoxesSuccess(items)
                )
            )
        )
    );

    @Effect()
    selectFoldersAndFilesLoadFolderContent$: Observable<TcAction> = this.actions$.pipe(
        ofType<SelectFoldersAndFilesLoadFolderContent>(
            SelectFoldersAndFilesActionTypes.SELECT_FOLDERS_AND_FILES_LOAD_FOLDER_CONTENT
        ),
        mergeMap((action: SelectFoldersAndFilesLoadFolderContent) =>
            this.contentService.getFolder(action.payload).pipe(
                map(
                    (item: AbstractFile) =>
                        new SelectFoldersAndFilesLoadFolderContentSuccess(item)
                )
            )
        )
    );
}
