import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AccountDetailComponent } from '../account-detail/account-detail.component';
import { Store, select } from '@ngrx/store';
import { AccountState } from 'src/app/modules/account/store/account.reducer';
import { User } from 'src/generated/api/stockmemoriss';
import { getAccountUser } from 'src/app/modules/account/store/account.selectors';
import { Observable, Subscription } from 'rxjs';
import { ModifyPasswordComponent } from '../modify-password/modify-password.component';
import { PasswordRestitutionComponent } from '../password-restitution/password-restitution.component';
import { PasswordRestitutionUseComponent } from '../password-restitution-use/password-restitution-use.component';
import { TcSmartComponent } from '@tc/core';

@Component({
  selector: 'manage-account',
  templateUrl: './manage-account.component.html',
  styleUrls: ['./manage-account.component.scss']
})
// My account screen
export class ManageAccountComponent extends TcSmartComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<AccountState>,
    public dialog: MatDialog
  ) {
    super();
  }

  user: User;
  user$: Observable<User>;
  userSubscription: Subscription;

  ngOnInit() {

    this.userSubscription = this.store.pipe(select(getAccountUser)).subscribe(user => {
      if (user) {
        this.user = user;
      }
    });

  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  showMyDetails() {
    this.dialog.open(AccountDetailComponent, {
      data: this.user,
      autoFocus: false
    });
  }
  changePassword() {
    this.dialog.open(ModifyPasswordComponent, {
      data: this.user,
      autoFocus: false
    });
  }
  changePasswordRestitution() {
    this.dialog.open(PasswordRestitutionComponent, {
      data: this.user,
      autoFocus: false
    });
  }
  // 3259
  changePasswordRestitutionUse() {
    this.dialog.open(PasswordRestitutionUseComponent, {
      data: this.user,
      autoFocus: false
    });
  }

}
