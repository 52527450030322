import { interval, concat } from 'rxjs';
import { SwUpdate } from '@angular/service-worker';
import { ApplicationRef } from '@angular/core';
import { first } from 'rxjs/operators';
import { TcService } from '@tc/core';
import { Router } from '@angular/router';

export class UpdateService extends TcService {

  updateInterval = 10 * 1000;

  constructor(private appRef: ApplicationRef, private swUpdate: SwUpdate, private router: Router) {
    super();
  }

  public setup(): void {

    this.swUpdate.available.subscribe(event => this.promptUser());
    this.swUpdate.activated.subscribe(() => console.log('new version activated.'));

    if (this.swUpdate.isEnabled) {
      console.log('swUpdate is enabled....');
    } else {
      console.log('swUpdate is disabled!');
    }

    // Allow the app to stabilize first, before starting polling for updates with `interval()`.
    const appIsStable = this.appRef.isStable.pipe(first(isStable => isStable === true));
    const everyInterval = interval(this.updateInterval);
    const everyIntervalOnceAppIsStable = concat(appIsStable, everyInterval);
    // let i = 0;

    everyIntervalOnceAppIsStable.subscribe(() => {
      // console.log(`checking for updates: ${i++}`);
      this.swUpdate.checkForUpdate().catch((reason) => console.log('checking error: ', reason));
    });
  }

  private promptUser(): void {
    console.log('Updating to new version');

    this.swUpdate.activateUpdate().then(() => {

      console.log('Une nouvelle version est disponible. L\'application sera rechargée.');

      if (this.router.url !== '/' && this.router.url !== '/login') {
        alert('Une nouvelle version est disponible. L\'application sera rechargée.');
      }

      document.location.reload();
    });

  }
}
