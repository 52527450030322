import { Component, OnInit } from '@angular/core';
import { TcTranslateService, TcPage } from '@tc/core';

@Component({
  selector: 'F17-box-email-addresses',
  templateUrl: './F17-box-email-addresses.component.html',
  styleUrls: ['./F17-box-email-addresses.component.scss']
})
export class F17BoxEmailAddressesComponent extends TcPage implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
  }
}
