import { TcAction } from '@tc/core';
import { ExpiredFile } from 'src/generated/api/stockmemoriss';
import { RequestFinalDeleteConfirmation } from './content.actions';

export enum ExpiredFilesActionTypes {

    // Action for load expired files
    LOAD_EXPIRED_FILES = '[Content] Load Expired Files',
    // Action for load expired files with success
    LOAD_EXPIRED_FILES_SUCCESS = '[Content] Load Expired Files Success',
    // Action for delete selected expired files
    DELETE_SELECTED_EXPIRED_FILES = '[Content] Delete Selected Expired Files',
    // Action for delete selected expired files
    EXTEND_SELECTED_EXPIRED_FILES = '[Content] Extend Selected Expired Files',

    REQUEST_EXTEND_EXPIRE_PERIOD_CONFIRMATION = '[Content] Request Extend Expire Period Confirmation',
    REQUEST_EXTEND_EXPIRE_PERIOD_CONFIRMATION_SUCCESS = '[Content] Request Extend Expire Period Confirmation Success',

    EXTEND_EXPIRE_PERIOD = '[Content] Extend Expire Period',
    EXTEND_EXPIRE_PERIOD_SUCCESS = '[Content] Extend Expire Period Success',

}


export class LoadExpiredFiles implements TcAction {
    readonly type = ExpiredFilesActionTypes.LOAD_EXPIRED_FILES;
}

export class LoadExpiredFilesSuccess implements TcAction {
    readonly type = ExpiredFilesActionTypes.LOAD_EXPIRED_FILES_SUCCESS;
    constructor(public payload: ExpiredFile[]) { }
}

export class DeleteSelectedExpiredFiles implements TcAction {
    readonly type = ExpiredFilesActionTypes.DELETE_SELECTED_EXPIRED_FILES;
}

export class ExtendsSelectedExpiredFiles implements TcAction {
    readonly type = ExpiredFilesActionTypes.EXTEND_SELECTED_EXPIRED_FILES;
}

export class RequestExtendExpirePeriodConfirmation implements TcAction {
    readonly type = ExpiredFilesActionTypes.REQUEST_EXTEND_EXPIRE_PERIOD_CONFIRMATION;
    constructor(public payload: number[]) { }
}

export class RequestExtendExpirePeriodConfirmationSuccess implements TcAction {
    readonly type = ExpiredFilesActionTypes.REQUEST_EXTEND_EXPIRE_PERIOD_CONFIRMATION_SUCCESS;
    constructor(public payload: { selectedRows: ExpiredFile[] }) { }
}

export class ExtendExpirePeriod implements TcAction {
    readonly type = ExpiredFilesActionTypes.EXTEND_EXPIRE_PERIOD;
    constructor(public payload: string) { }
}

export class ExtendExpirePeriodSuccess implements TcAction {
    readonly type = ExpiredFilesActionTypes.EXTEND_EXPIRE_PERIOD_SUCCESS;
    constructor(public payload: number) { }
}

export type ExpiredFilesActions =
    | LoadExpiredFiles
    | LoadExpiredFilesSuccess
    | DeleteSelectedExpiredFiles
    | ExtendsSelectedExpiredFiles
    | RequestFinalDeleteConfirmation
    | RequestExtendExpirePeriodConfirmationSuccess
    | ExtendExpirePeriod
    | ExtendExpirePeriodSuccess;
