import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CguAcceptationComponent } from './components/smart/cgu-acceptation/cgu-acceptation.component';
import { TcCoreModule } from '@tc/core';
import { CancelCguComponent } from './components/smart/cancel-cgu/cancel-cgu.component';

const components = [
  CguAcceptationComponent,
  CancelCguComponent
];

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    CommonModule,
    TcCoreModule,
  ],
  exports: [
    ...components
  ],
  entryComponents: [
    CancelCguComponent
  ]
})
export class CguModule { }
