import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AbstractFile, User, Box } from 'src/generated/api/stockmemoriss';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/modules/core/store/app.state';
import { getAccountUser } from 'src/app/modules/account/store/account.selectors';
import { TcSmartComponent, TcMenuItem, Logout } from '@tc/core';
import { BackToParentFolder } from '../../../store/actions/folder.actions';
import { GenericMenuComponent } from 'src/app/modules/shared/components/dumb/generic-menu/generic-menu.component';
import { getSelectedBox } from '../../../store/selectors/box.selectors';
import { getSelectedFolder, getSelectedFolderId } from '../../../store/selectors/folder.selectors';
import { BoxMenuService } from 'src/app/services/box-menu.service';
import { isSharedMode, getSharedFolderId } from '../../../store/selectors/share.selectors';
import { RouteOptions } from 'src/app/modules/core/enums/route-options';
import { RouterService } from 'src/app/services/router.service';
import { HomePageMenuOptions } from 'src/app/modules/core/enums/home-page-menu-options';

@Component({
  selector: 'list-documents-menu-component',
  templateUrl: './list-documents-menu.component.html',
  styleUrls: ['./list-documents-menu.component.scss']
})
export class ListDocumentsMenuComponent extends TcSmartComponent implements OnInit, OnDestroy {

  isSharedMode$: Observable<boolean>;
  isSharedMode: boolean;
  sharedModeSubscription: Subscription;

  selectedFolder: AbstractFile = null;
  selectedFolder$: Observable<AbstractFile>;
  selectedFolderSubscription: Subscription;

  selectedBox: Box;
  selectedBox$: Observable<Box>;
  selectedBoxSubscription: Subscription;

  user: User;
  userSubscription: Subscription;

  sharedFolderId$: Observable<number>;
  selectedFolderId$: Observable<number>;

  genericMenu: GenericMenuComponent;
  @ViewChild('genericMenu', { static: true }) set appGenericMenu(value: GenericMenuComponent) {
    this.genericMenu = value;
  }

  constructor(
    private store: Store<AppState>,
    private routerService: RouterService,
    private boxMenuService: BoxMenuService) {
    super();
  }

  ngOnInit() {

    this.isSharedMode$ = this.store.pipe(select(isSharedMode));
    this.sharedModeSubscription = this.isSharedMode$.subscribe(sharedMode => {
      this.isSharedMode = sharedMode;
    });


    this.sharedFolderId$ = this.store.pipe(select(getSharedFolderId));

    this.selectedFolderId$ = this.store.pipe(select(getSelectedFolderId));

    this.selectedBox$ = this.store.pipe(select(getSelectedBox));
    this.selectedFolder$ = this.store.pipe(select(getSelectedFolder));

    this.selectedFolderSubscription = this.selectedFolder$.subscribe(selectedFolder => {
      if (selectedFolder) {
        this.selectedFolder = selectedFolder;
      }
    });

    this.selectedBoxSubscription = this.selectedBox$.subscribe(selectedBox => {
      if (selectedBox) {
        this.selectedBox = selectedBox;
      }
    });

    this.userSubscription = this.store.pipe(select(getAccountUser)).subscribe(user => {
      if (user) {
        this.user = user;
      }
    });

    // if is not shared mode => menu should be box menu
    if (!this.isSharedMode) {

      this.genericMenu.menuName = 'box-menu';
      if (this.selectedBox) {
        this.genericMenu.menuItems = this.boxMenuService.getMenuItems(this.selectedBox.isBin);
      } else {
        this.genericMenu.menuItems = [];
      }


      // if is shared mode => replace box menu with 'home-page-menu', only with 'logout' options
    } else {

      this.genericMenu.menuName = 'home-page-menu';
      this.genericMenu.menuItems = [
        {
          name: HomePageMenuOptions.Logout,
        }];

    }

  }


  onMenuItemClick = (item: TcMenuItem) => {
    if (!this.isSharedMode) {
      if (this.selectedBox) {
        this.boxMenuService.onMenuBox(item, this.selectedBox);
      }
    } else {
      if (item.name === HomePageMenuOptions.Logout) {
        this.store.dispatch(new Logout());
      }
    }

  }

  ngOnDestroy() {

    if (this.selectedFolderSubscription) {
      this.selectedFolderSubscription.unsubscribe();
    }

    if (this.selectedBoxSubscription) {
      this.selectedBoxSubscription.unsubscribe();
    }

    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }

    if (this.sharedModeSubscription) {
      this.sharedModeSubscription.unsubscribe();
    }

  }

  goToSearch() {
    this.routerService.goToPage(RouteOptions.Search);
  }

  onNavigationBack() {
    if (this.selectedFolder) {
      if (this.selectedFolder.parentId !== this.user.defaultBoxId) {
        this.store.dispatch(new BackToParentFolder(this.selectedFolder.parentId));
      } else {
        this.routerService.goToHomePage();
      }
    } else {
      this.routerService.goToHomePage();
    }
  }
}
