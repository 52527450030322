import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AccountService, Succession } from 'src/generated/api/stockmemoriss';
import { Observable } from 'rxjs';
import { TcAction, TcNotificationService, TcTranslateService } from '@tc/core';
import {
    AddHeir, AddHeirSuccess, EditHeir, EditHeirSuccess, LoadHeir, LoadHeirSuccess, AccountActionTypes,
    DeleteHeir, DeleteHeirSuccess
} from './account.actions';
import { mergeMap, map } from 'rxjs/operators';

@Injectable()
export class HeirEffects {
    constructor(
        private actions$: Actions,
        private accountService: AccountService,
        private notificationService: TcNotificationService,
        private translateService: TcTranslateService,
    ) { }

    @Effect()
    addHeir$: Observable<TcAction> = this.actions$.pipe(
        ofType<AddHeir>(
            AccountActionTypes.ADD_HEIR
        ),
        mergeMap((action: AddHeir) =>
            this.accountService.postSuccession(action.payload).pipe(
                map(
                    (heir: Succession) =>
                        new AddHeirSuccess(heir)
                )
            )
        )
    );

    @Effect({ dispatch: false })
    addHeirSuccess = this.actions$.pipe(
        ofType<AddHeirSuccess>(AccountActionTypes.ADD_HEIR_SUCCESS),
        map((action: AddHeirSuccess) => {
            this.notificationService.success(this.translateService.instant('heir-detail.add'));
        })
    );

    @Effect({ dispatch: false })
    editHeirSuccess = this.actions$.pipe(
        ofType<EditHeirSuccess>(AccountActionTypes.EDIT_HEIR_SUCCESS),
        map((action: EditHeirSuccess) => {
            this.notificationService.success(this.translateService.instant('heir-detail.edit'));
        })
    );

    @Effect()
    editHeir$: Observable<TcAction> = this.actions$.pipe(
        ofType<EditHeir>(
            AccountActionTypes.EDIT_HEIR
        ),
        mergeMap((action: EditHeir) =>
            this.accountService.putSuccession(action.payload.id, action.payload).pipe(
                map(
                    (heir: Succession) =>
                        new EditHeirSuccess(heir)
                )
            )
        )
    );

    @Effect()
    loadHeir$: Observable<TcAction> = this.actions$.pipe(
        ofType<LoadHeir>(
            AccountActionTypes.LOAD_HEIR
        ),
        mergeMap((action: LoadHeir) =>
            this.accountService.getSuccession(action.payload).pipe(
                map(
                    (item: Succession) => {
                        if (item === null || item === undefined) {
                            return null;
                        } else {
                            return new LoadHeirSuccess(item);
                        }
                    }
                )
            )
        )
    );

    @Effect()
    deleteHeir$: Observable<TcAction> = this.actions$.pipe(
        ofType<DeleteHeir>(
            AccountActionTypes.DELETE_HEIR
        ),
        mergeMap((action: DeleteHeir) =>
            this.accountService.deleteSuccession(action.payload).pipe(
                map(
                    () =>
                        new DeleteHeirSuccess(action.payload)
                )
            )
        )
    );


    @Effect({ dispatch: false })
    deleteHeirSuccess = this.actions$.pipe(
        ofType<DeleteHeirSuccess>(AccountActionTypes.DELETE_HEIR_SUCCESS),
        map(() => {
            this.notificationService.success(this.translateService.instant('heir-detail.delete'));
        })
    );

}

