/**
 * StockMemoriss API
 * StockMemoriss API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { PasswordRestitutionChallenge } from '../model/passwordRestitutionChallenge';
import { Succession } from '../model/succession';
import { User } from '../model/user';
import { Email } from '../model/email';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class AccountService {

    protected basePath = 'https://virtserver.swaggerhub.com/TanaCorp/StockMemoriss/1.0.0';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * confirm email
     * - sets emailConfirmation &#x3D; true for user where emailConfirmatioCode &#x3D; code - if code missing or not found returns BadRequest({code&#x3D;confirm_email_invalid_code}) 
     * @param code guid
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public confirmEmail(code: string, observe?: 'body', reportProgress?: boolean): Observable<object>;
    public confirmEmail(code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<object>>;
    public confirmEmail(code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<object>>;
    public confirmEmail(code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling confirmEmail.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (code !== undefined && code !== null) {
            queryParameters = queryParameters.set('code', <any>code);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/html',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<object>(`${this.configuration.basePath}/account/confirm-email`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deletes a succession
     * deletes a succession 
     * @param id id of succession
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteSuccession(id: number, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public deleteSuccession(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public deleteSuccession(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public deleteSuccession(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteSuccession.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<string>(`${this.configuration.basePath}/account/successions/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deletes a new created user for MSM
     * - delete the association (default_service, user) from table services_utilisateurs - delete of default_service of user from table services - deletes the user from utilisateurs table - to do check current implementation in UtilisateursController-&gt;supprimerAction - to do delete from CoffreFort using default UTI_F user in order to have permission 
     * @param id id of user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUser(id: number, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public deleteUser(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public deleteUser(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public deleteUser(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteUser.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<string>(`${this.configuration.basePath}/account/users/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns a succession
     * returns a succession 
     * @param id id of Succession to read
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSuccession(id: number, observe?: 'body', reportProgress?: boolean): Observable<Succession>;
    public getSuccession(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Succession>>;
    public getSuccession(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Succession>>;
    public getSuccession(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getSuccession.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Succession>(`${this.configuration.basePath}/account/successions/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns a user
     * returns a user 
     * @param id id of folder to read
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUser(id: number, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public getUser(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public getUser(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public getUser(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getUser.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<User>(`${this.configuration.basePath}/account/users/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns a password restitution challenge for user
     * - retreives mdpRestitution from CF - wordCount &#x3D; count words of mdpRestitution - generates 3 random distinct positions (position1, position2, position3),  between [1, wordCount] (1 &lt;&#x3D; position &lt;&#x3D; wordCount)  - calculates targetWordPosition &#x3D; random (position1, position2, position3) - Returns { targetWordPosition, words : [mdpRestitution[position1-1], mdpRestitution[position2-1], mdpRestitution[position3-1]] } 
     * @param id id of user to generate challenge
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserRestitutionChallenge(id: number, observe?: 'body', reportProgress?: boolean): Observable<PasswordRestitutionChallenge>;
    public getUserRestitutionChallenge(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PasswordRestitutionChallenge>>;
    public getUserRestitutionChallenge(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PasswordRestitutionChallenge>>;
    public getUserRestitutionChallenge(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getUserRestitutionChallenge.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PasswordRestitutionChallenge>(`${this.configuration.basePath}/account/restitution-challenge/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * login based on username/pass
     * returns user based on login/pass 
     * @param username user name
     * @param password password
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public login(username: string, password: string, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public login(username: string, password: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public login(username: string, password: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public login(username: string, password: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling login.');
        }
        if (password === null || password === undefined) {
            throw new Error('Required parameter password was null or undefined when calling login.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (username !== undefined && username !== null) {
            queryParameters = queryParameters.set('username', <any>username);
        }
        if (password !== undefined && password !== null) {
            queryParameters = queryParameters.set('password', <any>password);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<User>(`${this.configuration.basePath}/account/login`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * create a succession
     * - create a succession in successions table (new table) - id &#x3D; user.id 
     * @param succession 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postSuccession(succession?: Succession, observe?: 'body', reportProgress?: boolean): Observable<Succession>;
    public postSuccession(succession?: Succession, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Succession>>;
    public postSuccession(succession?: Succession, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Succession>>;
    public postSuccession(succession?: Succession, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Succession>(`${this.configuration.basePath}/account/successions`,
            succession,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * create a user
     * - creates a service in table services    - id_client &#x3D; id of previous created client   - nom &#x3D; \&quot;MSM Root\&quot; - creates a user in table utilisateurs   - role &#x3D; UTI-S   - utilisateur.msm_rols &#x3D; user.rols   - id_client &#x3D; id of previously created client   - langue &#x3D; fr   - date_inscription &#x3D; now()   - service_default &#x3D; id of previously created service   - email_verified &#x3D; false   - droits &#x3D; TODO define - updates previously created service.nom &#x3D; \&quot;MSM Root &lt;id_user&gt;\&quot; where id_user is the id of newly created user - generates an emailConfirmationCode for user ( &#x3D; new GUID) and stores it in DB - detects  - sends a confirmation email to [email address &#x3D; username] containing a link for email validation: [msmsite.name]/confirm-email?code&#x3D;[emailConfirmationCode] 
     * @param user 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postUser(user?: User, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public postUser(user?: User, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public postUser(user?: User, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public postUser(user?: User, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<User>(`${this.configuration.basePath}/account/users`,
            user,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * create or update a Succession
     * create or update a Succession 
     * @param id id of Succession
     * @param succession 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putSuccession(id: number, succession?: Succession, observe?: 'body', reportProgress?: boolean): Observable<Succession>;
    public putSuccession(id: number, succession?: Succession, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Succession>>;
    public putSuccession(id: number, succession?: Succession, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Succession>>;
    public putSuccession(id: number, succession?: Succession, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling putSuccession.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Succession>(`${this.configuration.basePath}/account/successions/${encodeURIComponent(String(id))}`,
            succession,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * create or update a user
     * create or update a user 
     * @param id id of user
     * @param user 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putUser(id: number, user?: User, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public putUser(id: number, user?: User, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public putUser(id: number, user?: User, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public putUser(id: number, user?: User, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling putUser.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<User>(`${this.configuration.basePath}/account/users/${encodeURIComponent(String(id))}`,
            user,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * validates password restitution word choosen from challenge
     * - checks that password restitution contains the word {word} on position {position} 
     * @param id id of user to generate challenge
     * @param position the position of word to check
     * @param word word to check
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validateUserRestitutionChallenge(id: number, position: number, word: string, observe?: 'body', reportProgress?: boolean): Observable<object>;
    public validateUserRestitutionChallenge(id: number, position: number, word: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<object>>;
    public validateUserRestitutionChallenge(id: number, position: number, word: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<object>>;
    public validateUserRestitutionChallenge(id: number, position: number, word: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling validateUserRestitutionChallenge.');
        }
        if (position === null || position === undefined) {
            throw new Error('Required parameter position was null or undefined when calling validateUserRestitutionChallenge.');
        }
        if (word === null || word === undefined) {
            throw new Error('Required parameter word was null or undefined when calling validateUserRestitutionChallenge.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (position !== undefined && position !== null) {
            queryParameters = queryParameters.set('position', <any>position);
        }
        if (word !== undefined && word !== null) {
            queryParameters = queryParameters.set('word', <any>word);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<object>(`${this.configuration.basePath}/account/validate-restitution-word/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


    /**
     * send a password reset link
     * @param email 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
     public sendPasswordReset(email?: string, observe?: 'body', reportProgress?: boolean): Observable<Email>;
     public sendPasswordReset(email?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Email>>;
     public sendPasswordReset(email?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Email>>;
     public sendPasswordReset(email?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
        if (email === null || email === undefined) {
            throw new Error('Required parameter code was null or undefined when calling confirmEmail.');
        }

        // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        // if (email !== undefined && email !== null) {
        //     queryParameters = queryParameters.set('email', <any>email);
        // }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        const model: Email = {
            email: email,
        };

        return this.httpClient.post<Email>(`${this.configuration.basePath}/account/password`,
            model,
            {
                // params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
     } 




    /**
     * send a password reset link
     * @param email 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
     public sendPasswordExpired(email?: string, motdepasse?: string,  motdepasseold?: string, observe?: 'body', reportProgress?: boolean): Observable<Email>;
     public sendPasswordExpired(email?: string, motdepasse?: string,  motdepasseold?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Email>>;
     public sendPasswordExpired(email?: string, motdepasse?: string,  motdepasseold?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Email>>;
     public sendPasswordExpired(email?: string, motdepasse?: string,  motdepasseold?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
        if (email === null || email === undefined) {
            throw new Error('Required parameter code was null or undefined when calling confirmEmail.');
        }

        // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        // if (email !== undefined && email !== null) {
        //     queryParameters = queryParameters.set('email', <any>email);
        // }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        const passwordExpired = {
            email: email,
            motdepasseold: motdepasseold,
            motdepasse: motdepasse,
        };

        return this.httpClient.post<Email>(`${this.configuration.basePath}/account/password-expired`,
        passwordExpired,
            {
                // params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
     }  

}
