import { Component, OnInit, ElementRef, Optional, Inject } from '@angular/core';
import { TcFormComponent, TcTranslateService } from '@tc/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FileType } from 'src/generated/api/stockmemoriss';
import { AppState } from 'src/app/modules/core/store/app.state';
import { Store } from '@ngrx/store';
import { ShareFolderByLink, ShareFolderByEmail } from '../../../store/actions/folder.actions';
import { ShareFileByEmail, ShareFileByLink } from '../../../store/actions/file.actions';

@Component({
  selector: 'share-documents-options-dialog',
  templateUrl: './share-documents-options-dialog.component.html',
  styleUrls: ['./share-documents-options-dialog.component.scss']
})
export class ShareDocumentsOptionsDialogComponent extends TcFormComponent<any> implements OnInit {

  entityId: number;
  entityType: FileType;

  constructor(
    private store: Store<AppState>,
    public translateService: TcTranslateService,
    public dialogRef: MatDialogRef<ShareDocumentsOptionsDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    element: ElementRef) {
    super(translateService, element);

    this.fields = [
      {
        key: 'duration',
        type: 'select',
        templateOptions: {
          options: [
            { value: 0, label: this.translate.instant('share-documents.durations.0') },
            { value: 1, label: this.translate.instant('share-documents.durations.1') },
            { value: 2, label: this.translate.instant('share-documents.durations.2') },
            { value: 4, label: this.translate.instant('share-documents.durations.4') },
            { value: 5, label: this.translate.instant('share-documents.durations.5') },
            { value: 10, label: this.translate.instant('share-documents.durations.10') },
            { value: 20, label: this.translate.instant('share-documents.durations.20') },
            { value: 30, label: this.translate.instant('share-documents.durations.30') },
            { value: 60, label: this.translate.instant('share-documents.durations.60') },
            { value: 90, label: this.translate.instant('share-documents.durations.90') }
          ]
        },
      },
      {
        key: 'password',
        type: 'input',
        templateOptions: {
          type: 'text',
        },
      }
    ];

  }

  ngOnInit() {

    if (this.data) {
      this.entityId = this.data.entityId;
      this.entityType = this.data.entityType;
    }

    super.ngOnInit();
  }

  onClose() {
    this.dialogRef.close();
  }

  generateLink() {

    if (this.entityId && this.entityType) {
      if (this.entityType === FileType.Folder) {
        this.store.dispatch(new ShareFolderByLink({ folderId: this.entityId, duration: this.model.duration, password: this.model.password }));
      }
      if (this.entityType === FileType.File) {
        this.store.dispatch(new ShareFileByLink({ fileId: this.entityId, duration: this.model.duration, password: this.model.password }));
      }
    }

    this.dialogRef.close();

  }

  generateEmail() {
    if (this.entityId && this.entityType) {
      if (this.entityType === FileType.Folder) {
        this.store.dispatch(new ShareFolderByEmail({ folderId: this.entityId, duration: this.model.duration, password: this.model.password }));
      }
      if (this.entityType === FileType.File) {
        this.store.dispatch(new ShareFileByEmail({ fileId: this.entityId, duration: this.model.duration, password: this.model.password }));
      }
    }

    this.dialogRef.close();

  }

}
