import { TcAction } from '@tc/core';
import { Faq } from 'src/generated/api/stockmemoriss';

export enum FaqActionTypes {
    LOAD_FAQ = '[FAQ] Load Faq',
    LOAD_FAQ_SUCCESS = '[FAQ] Load Faq Success'
}

export class LoadFaq implements TcAction {
    readonly type = FaqActionTypes.LOAD_FAQ;
}

export class LoadFaqSuccess implements TcAction {
    readonly type = FaqActionTypes.LOAD_FAQ_SUCCESS;
    constructor(public payload: Faq[]) { }
}

export type FaqActions =
    | LoadFaq
    | LoadFaqSuccess;
