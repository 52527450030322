import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ContentService, SimpleFile, User } from 'src/generated/api/stockmemoriss';
import { Observable } from 'rxjs';
import { TcAction } from '@tc/core';
import { mergeMap, map, withLatestFrom } from 'rxjs/operators';
import { MSMContentService } from 'src/app/services/msm-content.service';
import {
    AddFileSuccess, FileActionTypes, SelectFile, SearchFiles,
    SearchFilesSuccess, DownloadFile, AddScannedImage, EditFile, EditFileSuccess,
    ShareFile, ShareFileByLink, ShareFileByLinkSuccess, ShareFileByEmail, ShareFileByEmailSuccess, RequestDeleteFile,
} from '../actions/file.actions';
import { AppState } from 'src/app/modules/core/store/app.state';
import { Store, select } from '@ngrx/store';
import { getBinBoxId } from '../selectors/box.selectors';
import { getAccountUser } from '../../.././account/store/account.selectors';

@Injectable()
export class FileEffects {

    constructor(
        private actions$: Actions,
        private contentService: ContentService,
        private msmContentService: MSMContentService,
        private store: Store<AppState>
    ) { }

    @Effect()
    loadFiles$: Observable<TcAction> = this.actions$.pipe(
        ofType<SearchFiles>(
            FileActionTypes.SEARCH_FILES
        ),
        mergeMap((action: SearchFiles) =>
            this.contentService.getFiles(action.payload).pipe(
                map(
                    (items: any) => {
                        if (Array.isArray(items)) {
                            return new SearchFilesSuccess(items);
                        } else {
                            return new SearchFilesSuccess([]);
                        }
                    }
                )
            )
        )
    );

    @Effect()
    editFile$: Observable<TcAction> = this.actions$.pipe(
        ofType<EditFile>(
            FileActionTypes.EDIT_FILE
        ),
        mergeMap((action: EditFile) =>
            this.contentService.putFile(action.payload.file.id, action.payload.file).pipe(
                map(
                    (file: SimpleFile) =>
                        new EditFileSuccess({ file: action.payload.file, type: action.payload.type })
                )
            )
        )
    );

    @Effect({ dispatch: false })
    addFileSuccess = this.actions$.pipe(
        ofType<AddFileSuccess>(FileActionTypes.ADD_FILE_SUCCESS),
        map(() => {
            this.msmContentService.addFileSuccess();
        })
    );

    @Effect({ dispatch: false })
    editFileSuccess = this.actions$.pipe(
        ofType<EditFileSuccess>(FileActionTypes.EDIT_FILE_SUCCESS),
        map((action: EditFileSuccess) => {
            this.msmContentService.editFileSuccess(action.payload.type);
        })
    );

    @Effect({ dispatch: false })
    requestDeleteFile = this.actions$.pipe(
        ofType<RequestDeleteFile>(FileActionTypes.REQUEST_DELETE_FILE),
        withLatestFrom(this.store.pipe(select(getBinBoxId))),
        map(([action, binBoxId]) => {
            this.msmContentService.requestDeleteFile(action.payload, binBoxId);
        })
    );

    // #3603
    @Effect({ dispatch: false })
    selectFile = this.actions$.pipe(
        ofType<SelectFile>(FileActionTypes.SELECT_FILE),
        withLatestFrom(this.store.select(state => state.content.isSharedMode), this.store.pipe(select(getAccountUser))),
        map(([action, isSharedMode, currentUser]) => {
            this.msmContentService.selectFile(action.payload, isSharedMode, currentUser);
        })
    );

    // #3603
    @Effect({ dispatch: false })
    downloadFile = this.actions$.pipe(
        ofType<DownloadFile>(FileActionTypes.DOWNLOAD_FILE),
        withLatestFrom(this.store.pipe(select(getAccountUser))),
        map(([action, currentUser]) => {
            this.msmContentService.downloadFile(action.payload, currentUser);
        })
    );

    @Effect({ dispatch: false })
    addScannedImage = this.actions$.pipe(
        ofType<AddScannedImage>(FileActionTypes.ADD_SCANNED_IMAGE),
        map((action: AddScannedImage) => {
            this.msmContentService.addScannedImage(action.payload.image, action.payload.parentFolderId);
        })
    );

    @Effect({ dispatch: false })
    shareFile = this.actions$.pipe(
        ofType<ShareFile>(FileActionTypes.SHARE_FILE),
        withLatestFrom(this.store.pipe(select(getAccountUser))),
        map(([action, currentUser]) => {
            this.msmContentService.shareFile(action.payload, currentUser);
        })
    );

    @Effect()
    shareFileByLink$: Observable<TcAction> = this.actions$.pipe(
        ofType<ShareFileByLink>(
            FileActionTypes.SHARE_FILE_BY_LINK
        ),
        mergeMap((action: ShareFileByLink) =>
            this.contentService.shareFileByLink(action.payload.fileId, action.payload.duration, action.payload.password).pipe(
                map(
                    (text: string) =>
                        new ShareFileByLinkSuccess(text)
                )
            )
        )
    );

    @Effect({ dispatch: false })
    shareFileByLinkSuccess = this.actions$.pipe(
        ofType<ShareFileByLinkSuccess>(FileActionTypes.SHARE_FILE_BY_LINK_SUCCESS),
        map((action: ShareFileByLinkSuccess) => {
            this.msmContentService.shareFileByLinkSuccess(action.payload);
        })
    );

    @Effect()
    shareFileByEmail$: Observable<TcAction> = this.actions$.pipe(
        ofType<ShareFileByEmail>(
            FileActionTypes.SHARE_FILE_BY_EMAIL
        ),
        mergeMap((action: ShareFileByEmail) =>
            this.contentService.shareFileByEmail(action.payload.fileId, action.payload.duration, action.payload.password).pipe(
                map(
                    (text: string) =>
                        new ShareFileByEmailSuccess(text)
                )
            )
        )
    );

    @Effect({ dispatch: false })
    shareFileByEmailSuccess = this.actions$.pipe(
        ofType<ShareFileByEmailSuccess>(FileActionTypes.SHARE_FILE_BY_EMAIL_SUCCESS),
        map((action: ShareFileByEmailSuccess) => {
            this.msmContentService.shareFileByEmailSuccess(action.payload);
        })
    );

}
