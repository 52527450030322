import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { Observable, combineLatest, Subscription } from 'rxjs';
import { Folder, SimpleFile, AbstractFile } from 'src/generated/api/stockmemoriss';
import { TcDumbComponent, TcListComponent, TcMenuComponent, TcMenuItem } from '@tc/core';
import { map } from 'rxjs/operators';
import { MSMAbstractFoldersAndFilesList } from 'src/app/modules/core/interfaces/msm-abstract-folders-and-files-list';

@Component({
  selector: 'msm-navigate-folders-and-files',
  templateUrl: './msm-navigate-folders-and-files.component.html',
  styleUrls: ['./msm-navigate-folders-and-files.component.scss']
})
export class MsmNavigateFoldersAndFilesComponent extends TcDumbComponent implements MSMAbstractFoldersAndFilesList, OnInit, OnDestroy {

  @Input()
  hasMenu = true;

  @Input()
  hasSelectColumn = false;

  @Input()
  folderMenuItems: TcMenuItem[] = [];

  @Input()
  fileMenuItems: TcMenuItem[] = [];

  @Input()
  folders$: Observable<Folder[]>;

  @Input()
  files$: Observable<SimpleFile[]>;

  @Input()
  selectedRows$: Observable<AbstractFile[]>;

  @Output()
  selectFolder: EventEmitter<Folder> = new EventEmitter();

  @Output()
  selectFile: EventEmitter<SimpleFile> = new EventEmitter();

  @Output()
  menuFolder: EventEmitter<any> = new EventEmitter();

  @Output()
  menuFile: EventEmitter<any> = new EventEmitter();

  @Output()
  selectRows: EventEmitter<any> = new EventEmitter();

  @Output()
  deselectRows: EventEmitter<any> = new EventEmitter();

  private selectionSubscription: Subscription;
  private selectedRowsSubscription: Subscription;

  constructor() {
    super();
  }

  foldersAndFilesList: TcListComponent;
  @ViewChild('foldersAndFilesList', { static: true }) set appFoldersAndFilesList(values: TcListComponent) {
    this.foldersAndFilesList = values;
  }

  folderRowMenu: TcMenuComponent;
  @ViewChild('folderRowMenu', { static: true }) set appFolderRowMenu(value: TcMenuComponent) {
    this.folderRowMenu = value;
  }

  fileRowMenu: TcMenuComponent;
  @ViewChild('fileRowMenu', { static: true }) set appFileRowMenu(value: TcMenuComponent) {
    this.fileRowMenu = value;
  }

  @ViewChild('colNameTemplate', { static: true }) colNameTemplate: TemplateRef<any>;

  ngOnInit() {

    window.scrollTo(0, 0);

    this.foldersAndFilesList.rows$ = combineLatest(this.folders$, this.files$).pipe(
      map(([folders, files]) => {
        return [...folders, ...files];
      })
    );

    this.foldersAndFilesList.hasActionsLabel = false;
    this.foldersAndFilesList.isPaged = false;
    this.foldersAndFilesList.isFiltrable = false;

    this.foldersAndFilesList.columns = [
      {
        propertyName: 'name',
        visible: true,
        filtrable: false,
        htmlTemplate: this.colNameTemplate,
      }
    ];

    if (this.hasSelectColumn) {
      this.foldersAndFilesList.bulkActions = [
        {
          actionName: 'select',
          visible: true
        }
      ];
    }

    this.selectionSubscription = this.foldersAndFilesList.selection.changed.subscribe(selection => {
      if (selection.added.length > 0) {
        this.selectRows.emit(selection.added);
      }
      if (selection.removed.length > 0) {
        this.deselectRows.emit(selection.removed);
      }
    });



    // after restore or delete files or folders from recycle bin, selection should be clear
    this.selectedRowsSubscription = this.selectedRows$.subscribe(selectedRows => {
      if (!selectedRows || selectedRows.length === 0) {
        this.foldersAndFilesList.selection.clear();
      }
    });

    // this.selectedRowsSubscription = combineLatest(this.foldersAndFilesList.rows$, this.selectedRows$).subscribe(s => {
    //   if (!s[1] || s[1].length === 0) {
    //     this.foldersAndFilesList.selection.clear();
    //   } else {
    //     s[0].forEach(k => {
    //       if (!s[1].find(t => t.id === k.id)) {
    //         this.foldersAndFilesList.selection.select(k);
    //       }
    //     })
    //   }
    // });

    this.folderRowMenu.items = this.folderMenuItems;
    this.folderRowMenu.onMenuItemClick = (item: TcMenuItem, row: Folder) => {
      this.menuFolder.emit({ item, row });
    };

    this.fileRowMenu.items = this.fileMenuItems;
    this.fileRowMenu.onMenuItemClick = (item: TcMenuItem, row: Folder) => {
      this.menuFile.emit({ item, row });
    };

  }

  onSelectFolder(folder: Folder) {
    if (folder) {
      this.selectFolder.emit(folder);
    }
  }

  onSelectFile(file: SimpleFile) {
    if (file) {
      this.selectFile.emit(file);
    }
  }

  ngOnDestroy() {
    if (this.selectionSubscription) {
      this.selectionSubscription.unsubscribe();
    }
    if (this.selectedRowsSubscription) {
      this.selectedRowsSubscription.unsubscribe();
    }
  }

}

