import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JournalFilterComponent } from './components/smart/journal-filter/journal-filter.component';
import { JournalResultsComponent } from './components/dumb/journal-results/journal-results.component';
import { TcCoreModule } from '@tc/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { journalReducer } from './store/journal.reducer';
import { JournalEffects } from './store/journal.effects';
import { JournalResultsListComponent } from './components/smart/journal-results-list/journal-results-list.component';
import { JournalItemComponent } from './components/dumb/journal-item/journal-item.component';
import { JournalHeaderComponent } from './components/dumb/journal-header/journal-header.component';
import { SharedModule } from '../shared/shared.module';

const components = [
  JournalFilterComponent,
  JournalResultsComponent,
  JournalResultsListComponent,
  JournalItemComponent,
  JournalHeaderComponent
];

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    CommonModule,
    TcCoreModule,
    SharedModule,
    StoreModule.forFeature('journal', journalReducer),
    EffectsModule.forFeature([JournalEffects]),
  ],
  exports: [
    ...components
  ],
  entryComponents: [
    JournalFilterComponent,
    JournalItemComponent
  ]
})
export class JournalModule { }
