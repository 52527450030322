import { Component, OnInit } from '@angular/core';
import { TcTranslateService, TcPage } from '@tc/core';

@Component({
  selector: 'F30-contact-support',
  templateUrl: './F30-contact-support.component.html',
  styleUrls: ['./F30-contact-support.component.scss']
})
export class F30ContactSupportComponent extends TcPage implements OnInit {

  screen: string;
  constructor() {
    super();
  }

  ngOnInit() {
    this.screen = 'support';
  }

}
