import { User, Succession } from 'src/generated/api/stockmemoriss';
import { AccountActions, AccountActionTypes } from './account.actions';

export interface AccountState {
    user: User;
    heir: Succession;
}

export const initialAccountState: AccountState = {
    user: null,
    heir: null
};

export function accountReducer(
    state = initialAccountState,
    action: AccountActions
): AccountState {
    switch (action.type) {
        case AccountActionTypes.ADD_USER_SUCCESS: {
            return {
                ...state,
                user: action.payload.user,
                heir: null
            };
        }
        case AccountActionTypes.LOAD_USER_SUCCESS: {
            return {
                ...state,
                user: action.payload,
                heir: null
            };
        }
        case AccountActionTypes.EDIT_USER_SUCCESS: {

            return {
                ...state,
                user: action.payload.user
            };
        }
        case AccountActionTypes.DELETE_USER_SUCCESS: {

            return {
                ...state,
                user: null,
                heir: null
            };
        }
        case AccountActionTypes.ADD_HEIR_SUCCESS: {
            return {
                ...state,
                heir: action.payload
            };
        }
        case AccountActionTypes.LOAD_HEIR_SUCCESS: {
            return {
                ...state,
                heir: action.payload
            };
        }
        case AccountActionTypes.EDIT_HEIR_SUCCESS: {

            return {
                ...state,
                heir: action.payload
            };
        }
        case AccountActionTypes.CONFIRM_EMAIL_SUCCESS: {

            const user = state.user;
            if (user) {
                user.emailConfirmation = true;
            }

            return {
                ...state,
                user
            };
        }
        case AccountActionTypes.DELETE_HEIR_SUCCESS: {
            return Object.assign({
                ...state,
                heir: null
            });
        }
        default:
            return { ...state };
    }

}
