import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { SelectFilesForCertificateDone, SelectFilesForCertificateCancel } from '../../../store/actions/certificate.actions';
import { TcSmartComponent } from '@tc/core';
import { ContentState } from '../../../store/content.state';

@Component({
  selector: 'select-files-for-certificate',
  templateUrl: './select-files-for-certificate.component.html',
  styleUrls: ['./select-files-for-certificate.component.scss']
})
export class SelectFilesForCertificateComponent extends TcSmartComponent implements OnInit {

  constructor(private store: Store<ContentState>) {
    super();
  }

  ngOnInit() {
  }

  onCancel() {
    this.store.dispatch(new SelectFilesForCertificateCancel());
  }

  onDone() {
    this.store.dispatch(new SelectFilesForCertificateDone());
  }

}
