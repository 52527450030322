import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ContentState } from '../content.state';

const getContentState = createFeatureSelector<ContentState>(
    'content'
);

export const getFolders = createSelector(
    getContentState,
    (state: ContentState) => state.folders.filter(f => f.parentId === state.selectedFolderId)
);

export const getSelectedFolderId = createSelector(
    getContentState,
    (state: ContentState) => state.selectedFolderId
);

export const getSelectedFolder = createSelector(
    getContentState,
    (state: ContentState) => state.selectedFolder
);

