import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AccountService, User } from 'src/generated/api/stockmemoriss';
import { Observable, of } from 'rxjs';
import { TcAction, TcNotificationService, TcTranslateService } from '@tc/core';
import {
    AddUser, AccountActionTypes, AddUserSuccess, EditUser,
    EditUserSuccess, LoadUser, LoadUserSuccess, ConfirmEmail,
    ConfirmEmailSuccess, DeleteUser, DeleteUserSuccess, CheckRestitutionPassword,
    ForgetPassword, ForgetPasswordSuccess, PasswordExpired, PasswordExpiredSuccess
} from './account.actions';
import { mergeMap, map, withLatestFrom, catchError, switchMap } from 'rxjs/operators';
import { MSMAccountService } from 'src/app/services/msm-account.service';
import { Store, select } from '@ngrx/store';
import { getAccountUser } from './account.selectors';
import { AccountState } from './account.reducer';
import { RouterService } from './../../../services/router.service';


@Injectable()
export class AccountEffects {
    constructor(
        private store: Store<AccountState>,
        private actions$: Actions,
        private accountService: AccountService,
        private msmAccountService: MSMAccountService,
        private routerService: RouterService,
    ) { }

    @Effect()
    addUser$: Observable<TcAction> = this.actions$.pipe(
        ofType<AddUser>(
            AccountActionTypes.ADD_USER
        ),
        mergeMap((action: AddUser) =>
            this.accountService.postUser(action.payload.user).pipe(
                map(
                    (user: User) =>
                        new AddUserSuccess({ user, password: action.payload.user.password, sharedUrl: action.payload.sharedUrl })
                )
            )
        )
    );

    @Effect({ dispatch: false })
    addUserSuccess = this.actions$.pipe(
        ofType<AddUserSuccess>(AccountActionTypes.ADD_USER_SUCCESS),
        map((action: AddUserSuccess) => {
            this.msmAccountService.loginUserAfterRegister(action.payload.user.username, action.payload.password, action.payload.sharedUrl);
        })
    );

    @Effect({ dispatch: false })
    editUserSuccess = this.actions$.pipe(
        ofType<EditUserSuccess>(AccountActionTypes.EDIT_USER_SUCCESS),
        map((action: EditUserSuccess) => {
            this.msmAccountService.editUserSuccess(action.payload.actionType);
        })
    );

    @Effect()
    editUser$: Observable<TcAction> = this.actions$.pipe(
        ofType<EditUser>(
            AccountActionTypes.EDIT_USER
        ),
        mergeMap((action: EditUser) =>
            this.accountService.putUser(action.payload.user.id, action.payload.user).pipe(
                map(
                    (user: User) =>
                        new EditUserSuccess({ user, actionType: action.payload.actionType })
                )
            )
        )
    );

    @Effect()
    loadUser$: Observable<TcAction> = this.actions$.pipe(
        ofType<LoadUser>(
            AccountActionTypes.LOAD_USER
        ),
        mergeMap((action: LoadUser) =>
            this.accountService.getUser(action.payload).pipe(
                switchMap(item => [
                    new LoadUserSuccess(item)
                  ]),
                  catchError(error => {
                    if (error.status == 403) {
                        this.routerService.goToLoginExpiredPage();
                    }
                    new LoadUserSuccess(null)
                    return Observable.throw(error.statusText);
                  })
                // map(
                //     (item: User) => 
                //         new LoadUserSuccess(item)
                // ),catchError(error => {
                //     if (error.status == 403) {
                //         this.routerService.goToLoginExpiredPage();
                //     }
                //     new LoadUserSuccess(null)
                //     return Observable.throw(error.statusText);
                // })
            )
        )
    );

    @Effect()
    confirmEmail$: Observable<TcAction> = this.actions$.pipe(
        ofType<ConfirmEmail>(
            AccountActionTypes.CONFIRM_EMAIL
        ),
        mergeMap((action: ConfirmEmail) =>
            this.accountService.confirmEmail(action.payload).pipe(
                map(
                    () =>
                        new ConfirmEmailSuccess()
                )
            )
        )
    );

    @Effect({ dispatch: false })
    confirmEmailSuccess = this.actions$.pipe(
        ofType<ConfirmEmailSuccess>(AccountActionTypes.CONFIRM_EMAIL_SUCCESS),
        map(() => {
            this.msmAccountService.confirmEmailSuccess();
        })
    );

    @Effect()
    deleteUser$: Observable<TcAction> = this.actions$.pipe(
        ofType<DeleteUser>(
            AccountActionTypes.DELETE_USER
        ),
        mergeMap((action: DeleteUser) =>
            this.accountService.deleteUser(action.payload).pipe(
                map(
                    () =>
                        new DeleteUserSuccess()
                )
            )
        )
    );

    @Effect({ dispatch: false })
    deleteUserSuccess = this.actions$.pipe(
        ofType<DeleteUserSuccess>(AccountActionTypes.DELETE_USER_SUCCESS),
        map(() => {
            this.msmAccountService.deleteAccountSuccess();
        })
    );

    @Effect({ dispatch: false })
    checkRestitutionPassword = this.actions$.pipe(
        ofType<CheckRestitutionPassword>(AccountActionTypes.CHECK_RESTITUTION_PASSWORD),
        withLatestFrom(this.store.pipe(select(getAccountUser))),
        map(([action, currentUser]) => {
            this.msmAccountService.checkRestitutionPassword(currentUser);
        })
    );

    @Effect()
    ForgetPassword$: Observable<TcAction> = this.actions$.pipe(
        ofType<ForgetPassword>(
            AccountActionTypes.FORGET_PASSWORD
        ),
        mergeMap((action: ForgetPassword) =>
            this.accountService.sendPasswordReset(action.payload.email).pipe(
                map( () =>
                    new ForgetPasswordSuccess()
                )
            )
        )
    );
    

    @Effect({ dispatch: false })
    ForgetPasswordSuccess = this.actions$.pipe(
        ofType<ForgetPasswordSuccess>(AccountActionTypes.FORGET_PASSWORD_SUCCESS),
        map((action: ForgetPasswordSuccess) => {
            this.msmAccountService.forgetPasswordSuccess();
            console.log('ForgetPasswordSuccess');
        })
    );

    @Effect()
    PasswordExpired$: Observable<TcAction> = this.actions$.pipe(
        ofType<PasswordExpired>(
            AccountActionTypes.PASSWORD_EXPIRED
        ),
        mergeMap((action: PasswordExpired) =>
            this.accountService.sendPasswordExpired(action.payload.email, action.payload.motdepasse, action.payload.motdepasseold).pipe(
                map( () =>
                    new PasswordExpiredSuccess()
                )
            )
        )
    );
    

    @Effect({ dispatch: false })
    PasswordExpiredSuccess = this.actions$.pipe(
        ofType<PasswordExpiredSuccess>(AccountActionTypes.PASSWORD_EXPIRED_SUCCESS),
        map((action: PasswordExpiredSuccess) => {
            this.msmAccountService.passwordExpiredSuccess();
            console.log('PasswordExpiredSuccess');
        })
    );

}

