import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { TcSmartComponent } from '@tc/core';
import { AppState } from 'src/app/modules/core/store/app.state';
import { Store, select } from '@ngrx/store';
import { DeleteUser } from 'src/app/modules/account/store/account.actions';
import { User } from 'src/generated/api/stockmemoriss';
import { Subscription } from 'rxjs';
import { getAccountUser } from 'src/app/modules/account/store/account.selectors';

@Component({
  selector: 'cancel-cgu',
  templateUrl: './cancel-cgu.component.html',
  styleUrls: ['./cancel-cgu.component.scss']
})
export class CancelCguComponent extends TcSmartComponent implements OnInit, OnDestroy {


  currentUser: User;
  currentUserSubscription: Subscription;

  constructor(
    private dialogRef: MatDialogRef<CancelCguComponent>,
    private store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    this.currentUserSubscription = this.store.pipe(select(getAccountUser)).subscribe(user => {
      if (user) {
        this.currentUser = user;
      }
    });
  }

  close() {
    this.dialogRef.close();
  }

  redirectToLogin() {
    this.dialogRef.close();
    if (this.currentUser) {
      this.store.dispatch(new DeleteUser(this.currentUser.id));
    }

  }

  ngOnDestroy() {
    if (this.currentUserSubscription) {
      this.currentUserSubscription.unsubscribe();
    }
  }

  onClose() {
    this.dialogRef.close();
  }

}
