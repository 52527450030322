import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { ContentService, AbstractFile } from 'src/generated/api/stockmemoriss';
import { MoveEntity, MoveActionTypes, MoveEntityDone, LoadFoldersForMoving, LoadFoldersForMovingSuccess, MoveCancel, MoveOrphans, MoveOrphansDone } from '../actions/move.actions';
import { map, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TcAction } from '@tc/core';
import { MoveService } from 'src/app/services/move.service';

@Injectable()
export class MoveEffects {

    constructor(
        private actions$: Actions,
        private contentService: ContentService,
        private moveService: MoveService
    ) { }

    @Effect({ dispatch: false })
    moveEntity = this.actions$.pipe(
        ofType<MoveEntity>(MoveActionTypes.MOVE_ENTITY),
        map((action: MoveEntity) => {
            this.moveService.moveEntity();
        })
    );


    @Effect()
    loadFoldersForMoving$: Observable<TcAction> = this.actions$.pipe(
        ofType<LoadFoldersForMoving>(
            MoveActionTypes.LOAD_FOLDERS_FOR_MOVING
        ),
        mergeMap((action: LoadFoldersForMoving) =>
            this.contentService.getFolder(action.payload).pipe(
                map(
                    (item: AbstractFile) =>
                        new LoadFoldersForMovingSuccess(item)
                )
            )
        )
    );

    @Effect({ dispatch: false })
    moveEntityDone = this.actions$.pipe(
        ofType<MoveEntityDone>(MoveActionTypes.MOVE_ENTITY_DONE),
        map((action: MoveEntityDone) => {
            this.moveService.moveEntityDone(action.payload);
        })
    );

    @Effect({ dispatch: false })
    moveCancel = this.actions$.pipe(
        ofType<MoveCancel>(MoveActionTypes.MOVE_CANCEL),
        map((action: MoveCancel) => {
            this.moveService.moveCancel();
        })
    );

    @Effect({ dispatch: false })
    moveOrphans = this.actions$.pipe(
        ofType<MoveOrphans>(MoveActionTypes.MOVE_ORPHANS),
        map((action: MoveOrphans) => {
            this.moveService.moveOrphans();
        })
    );

    @Effect({ dispatch: false })
    moveOrphansDone = this.actions$.pipe(
        ofType<MoveOrphansDone>(MoveActionTypes.MOVE_ORPHANS_DONE),
        map((action: MoveOrphansDone) => {
            this.moveService.moveOrphansDone(action.payload.newParentId, action.payload.model);
        })
    );

}
