import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { TcSliderListComponent, TcMenuItem, TcSmartComponent } from '@tc/core';
import { TemplateRef } from '@angular/core';
import { Box } from 'src/generated/api/stockmemoriss';
import { Store, select } from '@ngrx/store';
import { LoadBoxes, SelectBox } from '../../../store/actions/box.actions';
import { getBoxes } from '../../../store/selectors/box.selectors';
import { getSelectedFolder } from '../../../store/selectors/folder.selectors';
import { MSMAccountService } from 'src/app/services/msm-account.service';
import { BoxMenuService } from 'src/app/services/box-menu.service';
import { CheckRestitutionPassword } from 'src/app/modules/account/store/account.actions';
import { ContentState } from '../../../store/content.state';


@Component({
  selector: 'box-slider-component',
  templateUrl: './box-slider.component.html',
  styleUrls: ['./box-slider.component.scss']
})
export class BoxSliderComponent extends TcSmartComponent implements OnInit, OnDestroy {

  boxMenuItems: TcMenuItem[];

  constructor(
    private store: Store<ContentState>,
    private msmAccountService: MSMAccountService,
    private boxMenuService: BoxMenuService) {
    super();
  }

  boxSlider: TcSliderListComponent;
  @ViewChild('boxSlider', { static: true }) set appBoxSliderList(values: TcSliderListComponent) {
    this.boxSlider = values;
  }
  @ViewChild('itemTemplate', { static: true }) itemTemplate: TemplateRef<any>;

  ngOnInit() {

    this.store.dispatch(new CheckRestitutionPassword());

    this.store.dispatch(new LoadBoxes());

    this.boxSlider.items$ = this.store.pipe(select(getBoxes));
    this.boxSlider.selectedItem$ = this.store.pipe(select(getSelectedFolder));

    this.boxSlider.itemTemplate = this.itemTemplate;

    this.boxMenuItems = this.boxMenuService.getMenuItems();
  }

  ngOnDestroy(): void {
    this.msmAccountService.dispose();
  }

  onItemClick(item: Box) {
    this.store.dispatch(new SelectBox(item));
  }

  onMenuBox(event: any) {
    this.boxMenuService.onMenuBox(event.item, event.row);
  }


}
