import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TcCoreModule } from '@tc/core';
import { GenericHeaderComponent } from './components/dumb/generic-header/generic-header.component';
import { GenericMenuComponent } from './components/dumb/generic-menu/generic-menu.component';
import { BreadcrumbHeaderComponent } from './components/dumb/breadcrumb-header/breadcrumb-header.component';

// components
const components = [
  GenericHeaderComponent,
  GenericMenuComponent,
  BreadcrumbHeaderComponent
];

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    CommonModule,
    TcCoreModule
  ],
  exports: [
    ...components
  ]
})
export class SharedModule { }
