import { TcAction } from '@tc/core';
import { User, Succession, Email } from 'src/generated/api/stockmemoriss';

export enum AccountActionTypes {
    // user actions
    ADD_USER = '[Account] Add User',
    ADD_USER_SUCCESS = '[Account] Add User Success',
    LOAD_USER = '[Account] Load User',
    LOAD_USER_SUCCESS = '[Account] Load User Success',
    EDIT_USER = '[Account] Edit User',
    EDIT_USER_SUCCESS = '[Account] Edit User Success',
    DELETE_USER = '[Account] Delete User',
    DELETE_USER_SUCCESS = '[Account] Delete User Success',
    FORGET_PASSWORD = '[Account] Send Password',
    FORGET_PASSWORD_SUCCESS = '[Account] Send Password Success',
    PASSWORD_EXPIRED = '[Account] Send Password Expired',
    PASSWORD_EXPIRED_SUCCESS = '[Account] Send Password Expired Success',

    // confirm email actions
    CONFIRM_EMAIL = '[Account] Confirm Email',
    CONFIRM_EMAIL_SUCCESS = '[Account] Confirm Email Success',

    // Heir actions
    ADD_HEIR = '[Account] Add Heir',
    ADD_HEIR_SUCCESS = '[Account] Add Heir Success',
    LOAD_HEIR = '[Account] Load Heir',
    LOAD_HEIR_SUCCESS = '[Account] Load Heir Success',
    EDIT_HEIR = '[Account] Edit Heir',
    EDIT_HEIR_SUCCESS = '[Account] Edit Heir Success',
    DELETE_HEIR = '[Account] Delete Heir',
    DELETE_HEIR_SUCCESS = '[Account] Delete Heir Success',

    // CHECK RESTITUTION PASWORD
    CHECK_RESTITUTION_PASSWORD = '[Account] Check restitution password'

}

export class AddUser implements TcAction {
    readonly type = AccountActionTypes.ADD_USER;
    constructor(public payload: { user: User, sharedUrl?: string }) { }
}

export class AddUserSuccess implements TcAction {
    readonly type = AccountActionTypes.ADD_USER_SUCCESS;
    constructor(public payload: { user: User, password: string, sharedUrl?: string }) { }
}

export class EditUser implements TcAction {
    readonly type = AccountActionTypes.EDIT_USER;
    constructor(public payload: { user: User, actionType: string }) { }

}

export class EditUserSuccess implements TcAction {
    readonly type = AccountActionTypes.EDIT_USER_SUCCESS;
    constructor(public payload: { user: User, actionType: string }) { }
}

export class LoadUser implements TcAction {
    readonly type = AccountActionTypes.LOAD_USER;
    constructor(public payload: number) { }
}

export class LoadUserSuccess implements TcAction {
    readonly type = AccountActionTypes.LOAD_USER_SUCCESS;
    constructor(public payload: User) { }
}

export class ConfirmEmail implements TcAction {
    readonly type = AccountActionTypes.CONFIRM_EMAIL;
    constructor(public payload: string) { }
}

export class ConfirmEmailSuccess implements TcAction {
    readonly type = AccountActionTypes.CONFIRM_EMAIL_SUCCESS;
    constructor() { }
}

export class DeleteUser implements TcAction {
    readonly type = AccountActionTypes.DELETE_USER;
    constructor(public payload: number) { }
}

export class DeleteUserSuccess implements TcAction {
    readonly type = AccountActionTypes.DELETE_USER_SUCCESS;
    constructor() { }
}

export class AddHeir implements TcAction {
    readonly type = AccountActionTypes.ADD_HEIR;
    constructor(public payload: Succession) { }
}

export class AddHeirSuccess implements TcAction {
    readonly type = AccountActionTypes.ADD_HEIR_SUCCESS;
    constructor(public payload: Succession) { }
}

export class EditHeir implements TcAction {
    readonly type = AccountActionTypes.EDIT_HEIR;
    constructor(public payload: Succession) { }

}

export class EditHeirSuccess implements TcAction {
    readonly type = AccountActionTypes.EDIT_HEIR_SUCCESS;
    constructor(public payload: Succession) { }
}

export class LoadHeir implements TcAction {
    readonly type = AccountActionTypes.LOAD_HEIR;
    constructor(public payload: number) { }
}

export class LoadHeirSuccess implements TcAction {
    readonly type = AccountActionTypes.LOAD_HEIR_SUCCESS;
    constructor(public payload: Succession) { }
}

export class DeleteHeir implements TcAction {
    readonly type = AccountActionTypes.DELETE_HEIR;
    constructor(public payload: number) { }
}

export class DeleteHeirSuccess implements TcAction {
    readonly type = AccountActionTypes.DELETE_HEIR_SUCCESS;
    constructor(public payload: number) { }
}

export class CheckRestitutionPassword implements TcAction {
    readonly type = AccountActionTypes.CHECK_RESTITUTION_PASSWORD;
}

export class ForgetPassword implements TcAction {
    readonly type = AccountActionTypes.FORGET_PASSWORD;
    constructor(public payload: { email: string, sharedUrl?: string }) { }
}

export class ForgetPasswordSuccess implements TcAction {
    readonly type = AccountActionTypes.FORGET_PASSWORD_SUCCESS;
    constructor() { }
}

export class PasswordExpired implements TcAction {
    readonly type = AccountActionTypes.PASSWORD_EXPIRED;
    constructor(public payload: { email: string, motdepasse: string, motdepasseold: string, sharedUrl?: string }) { }
}

export class PasswordExpiredSuccess implements TcAction {
    readonly type = AccountActionTypes.PASSWORD_EXPIRED_SUCCESS;
    constructor() { }
}

export type AccountActions = AddUser
    | AddUserSuccess
    | LoadUser
    | LoadUserSuccess
    | EditUser
    | EditUserSuccess
    | ConfirmEmail
    | ConfirmEmailSuccess
    | DeleteUser
    | DeleteUserSuccess
    | AddHeir
    | AddHeirSuccess
    | LoadHeir
    | LoadHeirSuccess
    | EditHeir
    | EditHeirSuccess
    | DeleteHeir
    | DeleteHeirSuccess
    | CheckRestitutionPassword
    | ForgetPassword
    | ForgetPasswordSuccess
    | PasswordExpired
    | PasswordExpiredSuccess;
    
