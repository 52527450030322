
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FileType } from 'src/generated/api/stockmemoriss';
import { ContentState } from '../content.state';

const getContentState = createFeatureSelector<ContentState>(
    'content'
);

export const getSelectedRows = createSelector(
    getContentState,
    (state: ContentState) => state.selectedRows
);


export const getFilesForCertificate = createSelector(
    getContentState,
    (state: ContentState) => state.filesForCertificate ? state.filesForCertificate.filter(c => c.type === FileType.File) : []
);
