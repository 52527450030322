import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AccountService, User } from 'src/generated/api/stockmemoriss';
import { LoadUser } from 'src/app/modules/account/store/account.actions';
import { getAccountUser } from 'src/app/modules/account/store/account.selectors';
import { Observable, Subscription, interval } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { takeWhile } from 'rxjs/operators';
import { TcTranslateService, TcNotificationService, TcSmartComponent } from '@tc/core';
import { RouterService } from 'src/app/services/router.service';
import { PartnerProvider } from 'src/app/modules/core/providers/partner-provider';

@Component({
  selector: 'email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent extends TcSmartComponent implements OnInit, OnDestroy {

  sharedUrl: string;

  alive = true;

  user: User;
  user$: Observable<User>;
  userSubscription: Subscription;

  constructor(
    private store: Store<AccountService>,
    private translateService: TcTranslateService,
    private notificationService: TcNotificationService,
    private route: ActivatedRoute,
    private routerService: RouterService,
    private partnerProvider: PartnerProvider) {
    super();
  }
  ngOnInit() {

    const sharedUrl = this.route.snapshot.queryParams.sharedUrl;

    if (sharedUrl) {
      this.sharedUrl = sharedUrl;
    }

    // todo: check this code
    interval(1000).pipe(
      takeWhile(() => this.alive))
      .subscribe(() => {
        if (this.user) {
          this.store.dispatch(new LoadUser(this.user.id));
        }
      });

    // todo: check this code
    this.userSubscription = this.store.pipe(select(getAccountUser)).subscribe(user => {
      if (user) {
        this.user = user;
        if (user.emailConfirmation) {
          this.alive = false;
          this.notificationService.success(this.translateService.instant('email-verification.success'));

          // if user role === normal => continue to payment screen (if partner have configured payment)
          // if user role === lecteur => continue to cgu accept screen
          if (user.role === User.RoleEnum.Normal) {

            const partnerConfig = this.partnerProvider.getPartnerConfig();

            console.log('partner config payment: ', partnerConfig.payement);
//ARA 3308
            if (partnerConfig && partnerConfig.payement === false) {
              if (user && user.msmPaiement === true){
                this.routerService.goToPaymentPage();
              }else{
                this.routerService.goToCguPage();
              }
            } else {
              if (user && user.msmPaiement === false){
                this.routerService.goToCguPage();
              }else{
                this.routerService.goToPaymentPage();
              }
            }

          } else {
            // if sharedUrl exists => navigate to cgu page with shared url, else navigate to login page
            if (this.sharedUrl) {
              this.routerService.goToCguPageWithSharedUrl(this.sharedUrl);
            } else {
              this.routerService.goToLoginPage();
            }

          }

        }
      }
    });
  }

  ngOnDestroy(): void {

    this.alive = false;

    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }

  }

}
