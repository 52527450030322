import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactComponent } from './components/smart/contact/contact.component';
import { TcCoreModule } from '@tc/core';
import { SupportConfirmationComponent } from './components/dumb/support-confirmation/support-confirmation.component';
import { EffectsModule } from '@ngrx/effects';
import { ContactEffects } from './store/contact.effects';


const components = [
  ContactComponent
];
@NgModule({
  declarations: [
    ...components,
    SupportConfirmationComponent
  ],
  imports: [
    CommonModule,
    TcCoreModule,
    EffectsModule.forFeature([ContactEffects])
  ],
  exports: [
    ...components
  ],
  entryComponents: [
    SupportConfirmationComponent
  ]
})
export class ContactModule { }
