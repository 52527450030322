import { Component, OnInit, ElementRef, Optional, Inject } from '@angular/core';
import { TcFormComponent, TcTranslateService, TcNotificationService } from '@tc/core';
import { User } from 'src/generated/api/stockmemoriss';
import { Store } from '@ngrx/store';
import { AccountState } from 'src/app/modules/account/store/account.reducer';
import { Router } from '@angular/router';
import { EditUser } from 'src/app/modules/account/store/account.actions';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Utils } from 'src/app/modules/core/utils';

@Component({
  selector: 'modify-password',
  templateUrl: './modify-password.component.html',
  styleUrls: ['./modify-password.component.scss']
})
// pop-up component (EDIT Password)
export class ModifyPasswordComponent extends TcFormComponent<User> implements OnInit {

  constructor(
    private store: Store<AccountState>,
    public translateService: TcTranslateService,
    private notificationService: TcNotificationService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: User,
    public dialogRef: MatDialogRef<ModifyPasswordComponent>,
    element: ElementRef) {

    super(translateService, element);

    this.fields = [
      {
        key: 'password',
        type: 'input',
        templateOptions: {
          type: 'password',
          required: true,
        },
      },
      {
        key: 'confirmPassword',
        type: 'input',
        templateOptions: {
          type: 'password',
          required: true,
        },
        validators: {
          fieldMatch: {
            expression: (control) => control.value === this.model.password,
            message: this.translate.instant('sign-up.validations.confirmPasswordMessage')
          },
        }
      },
    ];
  }
  ngOnInit() {
    if (this.data) {
      this.model = Object.assign({}, this.data);
    }
    super.ngOnInit();
  }

  submit(model: User) {
    if (this.form.valid && this.validatePassword(model.password)) {
      model.coachingMode = this.data.coachingMode;
      model.role = this.data.role;

      delete (model as any).confirmPassword;

      model.emailConfirmation = this.data.emailConfirmation;
      this.store.dispatch(new EditUser({ user: model, actionType: 'modify-password' }));
      this.dialogRef.close(model);
    }

  }

  // password must contains 1 uppercase, 1 lowercase, 1 digit, 1 special character and minim 12 characters
  validatePassword(password: string): boolean {
    if (!Utils.isValidPassword(password)) {
      this.notificationService.error(this.translate.instant('account-detail.password-format-error'));
      return false;
    }
    return true;
  }

  onClose() {
    this.dialogRef.close();
  }

}
