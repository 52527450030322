import { Injectable, OnDestroy } from '@angular/core';
import { TcAuthenticationService, TcNotificationService, TcTranslateService, Logout, TcService } from '@tc/core';
import { AppState } from '../modules/core/store/app.state';
import { Store, select } from '@ngrx/store';
import { AccountService, User } from 'src/generated/api/stockmemoriss';
import { getAccountUser } from '../modules/account/store/account.selectors';
import { RouterService } from './router.service';
import { Subscription } from 'rxjs';
import { PartnerProvider } from '../modules/core/providers/partner-provider';

@Injectable({
    providedIn: 'root'
})
export class MSMAccountService extends TcService {

    currentUserSubscription: Subscription;

    constructor(
        private store: Store<AppState>,
        private accountService: AccountService,
        private authenticationService: TcAuthenticationService,
        private notificationService: TcNotificationService,
        private translateService: TcTranslateService,
        private routerService: RouterService,
        private partnerProvider: PartnerProvider) {
        super();
    }

    loginUserAfterRegister(username: string, password: string, sharedUrl?: string) {

        this.authenticationService.login(username, password, false);

        if (sharedUrl) {
            this.routerService.goToEmailVerificationPageWithSharedUrl(sharedUrl);
        } else {
            this.routerService.goToEmailVerificationPage();
        }

    }

    confirmEmailSuccess() {

        this.notificationService.success(this.translateService.instant('confirm-email.success'));

        // todo: move this subscription/ find another way
        this.currentUserSubscription = this.store.pipe(select(getAccountUser)).subscribe(user => {

            const authUserId = this.authenticationService.getAuthUserId();

            if (user && user.id + '' === authUserId) {
                // if user role === normal => continue to payment screen (if partner have configured payment)
                // if user role === lecteur => continue to cgu accept screen
                if (user.role === User.RoleEnum.Normal) {
                    const partnerConfig = this.partnerProvider.getPartnerConfig();

                    console.log('partner config payment: ', partnerConfig.payement);
//ARA 3308
                    if (partnerConfig && partnerConfig.payement === false) {
                        if (user && user.msmPaiement === true){
                          this.routerService.goToPaymentPage();
                        }else{
                          this.routerService.goToCguPage();
                        }
                      } else {
                        if (user && user.msmPaiement === false){
                          this.routerService.goToCguPage();
                        }else{
                          this.routerService.goToPaymentPage();
                        }
                      }

                } else {
                    this.routerService.goToLoginPage();
                }
            } else {
                this.routerService.goToLoginPage();
            }
        });
    }


    deleteAccountSuccess() {
        this.notificationService.success(this.translateService.instant('manage-account.delete-success'));
        this.store.dispatch(new Logout());
    }

    checkRestitutionPassword(currentUser: User) {
        if (this.authenticationService.isAuthenticated() && currentUser && currentUser.role === User.RoleEnum.Normal && currentUser.dateAcceptationCGU && currentUser.passwordRestitutionUse) {
            this.accountService.getUserRestitutionChallenge(this.authenticationService.getAuthUserId()).subscribe();
        }
    }

    dispose() {
        if (this.currentUserSubscription) {
            this.currentUserSubscription.unsubscribe();
        }
    }

    editUserSuccess(type: string) {
        if (type === 'password-restitution') {
            this.notificationService.success(this.translateService.instant('password-restitution.validations.success'));
        }
        if (type === 'password-restitution-use') {
            this.notificationService.success(this.translateService.instant('password-restitution-use.validations.success'));
        }
        if (type === 'modify-password') {
            this.notificationService.success(this.translateService.instant('modify-password.validations.success'));
        }
        if (type === 'account-detail') {
            this.notificationService.success(this.translateService.instant('manage-account.success'));
        }
    }

    forgetPasswordSuccess() {
        this.notificationService.success(this.translateService.instant('forget-password.success-message'));
    }
    forgetPasswordError() {
        this.notificationService.success(this.translateService.instant('forget-password.error-message'));
    }
    passwordExpiredSuccess() {
        this.notificationService.success(this.translateService.instant('password-expired.success-message'));
        this.routerService.goToLoginPage();
    }
    passwordExpiredError() {
        this.notificationService.success(this.translateService.instant('password-expired.error-message'));
    }
}
