import { Component, OnInit } from '@angular/core';
import { TcTranslateService, TcPage } from '@tc/core';

@Component({
  selector: 'F05-set-heir-account',
  templateUrl: './F05-set-heir-account.component.html',
  styleUrls: ['./F05-set-heir-account.component.scss']
})
export class F05SetHeirAccountComponent extends TcPage implements OnInit {

  title: string;
  constructor() {
    super();
  }

  ngOnInit() {
  }

}
