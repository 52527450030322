import { Injectable } from '@angular/core';
import { SimpleFile, AccountService, Folder, AbstractFile, FileType, FilesAndFoldersModel, User } from 'src/generated/api/stockmemoriss';
import { TcAuthenticationService, TcNotificationService, TcTranslateService, TcConfigService, TcSpinnerService, TcUploadFileService, RouterStateUrl, TcService } from '@tc/core';
import { MatDialog } from '@angular/material';
import { Utils } from '../modules/core/utils';
import { RestitutionPasswordDialogComponent } from '../modules/content/components/smart/restitution-password-dialog/restitution-password-dialog.component';
import { Store } from '@ngrx/store';
import { AppState } from '../modules/core/store/app.state';
import { SendFolderByEmail, EditFolder } from '../modules/content/store/actions/folder.actions';
import { UploadResizeDialogComponent } from '../modules/content/components/dumb/upload-resize-dialog/upload-resize-dialog.component';
import { TcImageService } from '@tc/image';
import { forkJoin } from 'rxjs';
import { AddScannedImageSuccess, EditFile } from '../modules/content/store/actions/file.actions';
import { Location } from '@angular/common';
import { ShareDocumentsOptionsDialogComponent } from '../modules/content/components/smart/share-documents-options-dialog/share-documents-options-dialog.component';
import { ShareDocumentsWarningDialogComponent } from '../modules/content/components/dumb/share-documents-warning-dialog/share-documents-warning-dialog.component';
import { RouteOptions } from '../modules/core/enums/route-options';
import { RouterService } from './router.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class MSMContentService extends TcService {
    constructor(
        private store: Store<AppState>,
        private dialog: MatDialog,
        private accountService: AccountService,
        private authService: TcAuthenticationService,
        private notificationService: TcNotificationService,
        private translateService: TcTranslateService,
        private configService: TcConfigService,
        private tcSpinnerService: TcSpinnerService,
        private tcImageService: TcImageService,
        private tcUploadFileService: TcUploadFileService,
        private routerService: RouterService,
        private router: Router,
        private location: Location) {
        super();
    }

    selectBox() {
        this.routerService.goToPage(RouteOptions.ListDocuments);
    }

    changeBoxEmailAddress() {
        this.routerService.goToPage(RouteOptions.BoxAddress);
    }

    // #3603
    selectFile(file: SimpleFile, isSharedMode: boolean, user: User) {

        const extension = Utils.getFileExtension(file.name);

        if (Utils.isAllowedFileExtension(extension)) {
            if (isSharedMode) {
                this.routerService.goToPage(RouteOptions.FileViewer);
            } else {
                if(user.passwordRestitutionUse){
                    this.openRestitutionPasswordPopup(() => {
                        this.routerService.goToPage(RouteOptions.FileViewer);
                    });
                }else{
                    this.routerService.goToPage(RouteOptions.FileViewer);
                }
            }

        } else {
            this.notificationService.warning(this.translateService.instant('select-file.restriction-message'));
        }
    }
    // #3603
    sendFolder(folderId: number, user: User) {
        if(user.passwordRestitutionUse){
            this.openRestitutionPasswordPopup(() => {
                this.store.dispatch(new SendFolderByEmail(folderId));
            });
        }else{
            this.store.dispatch(new SendFolderByEmail(folderId));
        }
    }
    // #3603
    downloadFile(fileId: number, user: User) {
        const token = this.authService.getAccessToken();
        if(user.passwordRestitutionUse){
            this.openRestitutionPasswordPopup(() => {
                const url = (this.configService.config as any).API_BASE_PATH + '/content/download-file/' + fileId + '?token=' + token;
                // window.open(url, '_blank');
                window.location.assign(url);
            });
        }else{
            const url = (this.configService.config as any).API_BASE_PATH + '/content/download-file/' + fileId + '?token=' + token;
            // window.open(url, '_blank');
            window.location.assign(url);
        }
    }

    downloadLink(code: string) {
        const url = (this.configService.config as any).API_BASE_PATH + '/content/download-link/' + code;
        window.location.assign(url);
        this.routerService.goToHomePage();
    }

    private openRestitutionPasswordPopup(afterClosed: () => void) {
        if (this.authService.isAuthenticated()) {
            const authUserId = this.authService.getAuthUserId();
            // TO DO
            this.accountService.getUserRestitutionChallenge(authUserId).subscribe(
                result => {
                    const dialog = this.dialog.open(RestitutionPasswordDialogComponent, {
                        data: { model: result },
                        disableClose: true
                    });
                    dialog.afterClosed().subscribe(s => {
                        if (s === 'ok') {
                            afterClosed();
                        }
                    });
                });
        }
    }

    addScannedImage(image: File, parentFolderId: number) {

        console.log('image size: ' + image.size);

        const imageSizeInMb = image.size / Math.pow(1024, 2);

        console.log('image size in MB: ' + imageSizeInMb);

        const scannedImageExceedsSizeInMb = (this.configService.config as any).scannedImageExceedsSizeInMb;
        const scannedImageReducedQuality = (this.configService.config as any).scannedImageReducedQuality;

        if (scannedImageExceedsSizeInMb && imageSizeInMb > scannedImageExceedsSizeInMb) {

            const dialog = this.dialog.open(UploadResizeDialogComponent, { disableClose: true });

            dialog.afterClosed().subscribe(response => {

                if (response === 'yes') {
                    this.tcSpinnerService.showSpinner('reduce-image-quality');
                    this.tcImageService.reduceImageQuality(image, scannedImageReducedQuality).subscribe(reducedImage => {
                        if (reducedImage) {
                            console.log('image size after reduce quality: ' + reducedImage.size);
                            console.log('image size in MB after reduce quality: ' + reducedImage.size / Math.pow(1024, 2));
                            this.uploadScannedImage(reducedImage, parentFolderId, true);
                        }
                    });
                }

                if (response === 'no') {
                    this.uploadScannedImage(image, parentFolderId);
                }

            });

        } else {
            this.uploadScannedImage(image, parentFolderId);
        }

    }


    uploadScannedImage(image: File, parentFolderId: number, isReduced = false) {

        const uploadUrl = (this.configService.config as any).API_BASE_PATH + '/content/files?parentId=' + parentFolderId;
        const uploadStatus = this.tcUploadFileService.upload(image, uploadUrl);

        forkJoin(uploadStatus.body).subscribe(body => {
            if (body) {
                this.onScannedImageUploaded(body[0], isReduced);
            }
        });
    }

    onScannedImageUploaded = (file: SimpleFile, isReduced = false) => {
        if (isReduced) {
            this.tcSpinnerService.hideSpinner('reduce-image-quality');
        }
        this.store.dispatch(new AddScannedImageSuccess(file));
    }

    editFolderSuccess(type: string) {

        if (type === 'edit-folder') {
            this.notificationService.success(this.translateService.instant('folder-detail.edit.success'));
        }

        if (type === 'move-folder') {
            this.notificationService.success(this.translateService.instant('move.folder.success'));
            this.location.back();
        }

        if (type === 'delete-folder') {
            this.notificationService.success(this.translateService.instant('folder-detail.delete.success'));
        }

    }

    editFileSuccess(type: string) {

        if (type === 'move-file') {

            this.notificationService.success(this.translateService.instant('move.file.success'));
            this.location.back();
        }

        if (type === 'delete-file') {

            const currentUrl = this.router.url;
            if (currentUrl.includes('/file-viewer')) {
                this.location.back();
            }

            this.notificationService.success(this.translateService.instant('file.delete.success'));
        }
    }

    // #3603
    shareFolder(folderId: number, user: User) {
        if(user.passwordRestitutionUse){
            this.openRestitutionPasswordPopup(() => {
                this.openShareDialog(folderId, FileType.Folder);
            });
        }else{
            this.openShareDialog(folderId, FileType.Folder); 
        }
    }

    openShareDialog(entityId: number, entityType: FileType) {
        this.dialog.open(ShareDocumentsOptionsDialogComponent,
            {
                position: { left: '10px', top: '16%', right: '10px' },
                width: '100%',
                maxWidth: '100vw',
                panelClass: 'share-dialog-component',
                data: {
                    entityId,
                    entityType
                }
            });
    }

    // #3603
    shareFile(file: SimpleFile, user: User) {

        const extension = Utils.getFileExtension(file.name);

        if (Utils.isAllowedFileExtension(extension)) {
            if(user.passwordRestitutionUse){
                this.openRestitutionPasswordPopup(() => {
                    this.openShareDialog(file.id, FileType.File);
                });
            }else{
                this.openShareDialog(file.id, FileType.File); 
            }
        } else {
            this.dialog.open(ShareDocumentsWarningDialogComponent);
        }
    }

    shareFolderByLinkSuccess(link: string) {
        Utils.copyToClipboard(link);
        this.notificationService.success(this.translateService.instant('share-documents.info'));
    }

    shareFolderByEmailSuccess(email: string) {
        const mailText = 'mailto:?body=' + email.replace(/<br\s*\/?>/mg, '%0D%0A');
        window.location.href = mailText;
    }


    shareFileByLinkSuccess(link: string) {
        Utils.copyToClipboard(link);
        this.notificationService.success(this.translateService.instant('share-documents.info'));
    }

    shareFileByEmailSuccess(email: string) {
        const mailText = 'mailto:?body=' + email;
        window.location.href = mailText;
    }

    requestDeleteFile(file: SimpleFile, binBoxId: number) {

        // if binBoxId exists => change parentId = binBoxId (move file to recycle bin)
        if (binBoxId) {

            file.prevParentId = file.parentId;
            file.parentId = binBoxId;

            this.store.dispatch(new EditFile({ file, type: 'delete-file' }));
        }

    }

    requestDeleteFolder(folder: Folder, binBoxId: number) {

        // if binBoxId exists => change parentId = binBoxId (move folder to recycle bin)
        if (binBoxId) {

            folder.prevParentId = folder.parentId;
            folder.parentId = binBoxId;

            this.store.dispatch(new EditFolder({ folder, type: 'delete-folder' }));
        }

    }

    addBoxSuccess() {
        this.notificationService.success(this.translateService.instant('box-detail.add.success'));
    }

    deleteBoxSuccess() {
        this.notificationService.success(this.translateService.instant('box-detail.delete.success'));
    }

    editBoxSuccess() {
        this.notificationService.success(this.translateService.instant('box-detail.edit.success'));
    }

    addFileSuccess() {
        this.notificationService.success(this.translateService.instant('file.add.success'));
    }

    addFolderSuccess() {
        this.notificationService.success(this.translateService.instant('folder-detail.add.success'));
    }

    deleteFolderSuccess() {
        this.notificationService.success(this.translateService.instant('folder-detail.delete.success'));
    }

    sendFolderByEmailSuccess() {
        this.notificationService.success(this.translateService.instant('send-folder-by-email.success'));
    }


    // #3984
    archiveAttestation(fileId: number) {
        const token = this.authService.getAccessToken();
        const url = (this.configService.config as any).API_BASE_PATH + '/content/archive-attestation/' + fileId + '?token=' + token;
        window.location.assign(url);
    }

    // #3984
    controleAttestation(fileId: number) {
        const token = this.authService.getAccessToken();
        const url = (this.configService.config as any).API_BASE_PATH + '/content/controle-attestation/' + fileId + '?token=' + token;
        window.location.assign(url);
    }
}
