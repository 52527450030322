import { Component, OnInit } from '@angular/core';
import { TcTranslateService, TcPage } from '@tc/core';

@Component({
  selector: 'F30-contact-team',
  templateUrl: './F30-contact-team.component.html',
  styleUrls: ['./F30-contact-team.component.scss']
})
export class F30ContactTeamComponent extends TcPage implements OnInit {

  screen: string;
  constructor() {
    super();
  }

  ngOnInit() {
    this.screen = 'team';
  }
}
