import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'F32-faq',
  templateUrl: './F32-faq.component.html',
  styleUrls: ['./F32-faq.component.scss']
})
export class F32FaqComponent implements OnInit {

  title: string;

  constructor() { }

  ngOnInit() {
    // todo: move title in html like in f39-certificate
    this.title = 'FAQ';
  }

}
