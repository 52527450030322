import { TcAction } from '@tc/core';
import { AbstractFile, FilesAndFoldersModel, Box } from 'src/generated/api/stockmemoriss';

export enum ContentActionTypes {

    SELECT_ROWS = '[Content] Select Rows',
    DESELECT_ROWS = '[Content] Deselect Rows',
    DESELECT_ALL_ROWS = '[Content] Deselect All Rows',

    DELETE_FOLDERS_AND_FILES = '[Content] Delete folders and files',
    DELETE_ALL_FOLDERS_AND_FILES = '[Content] Delete all folders and files',

    REQUEST_FINAL_DELETE_CONFIRMATION = '[Content] Request final delete confirmation',
    REQUEST_FINAL_DELETE_CONFIRMATION_SUCCESS = '[Content] Request final delete confirmation success',

    FINAL_DELETE = '[Content] Final delete',
    FINAL_DELETE_SUCCESS = '[Content] Final delete success',

    RESTORE_SELECTED_ROWS = '[Content] Restore selected rows',
    RESTORE_FILES_AND_FOLDERS = '[Content] Restore files and folders',
    RESTORE_FILES_AND_FOLDERS_SUCCESS = '[Content] Restore files and folders success',

    RESTORE_ORPHANS = '[Content] Restore orphans',
    RESTORE_ORPHANS_SUCCESS = '[Content] Restore orphans success',

    DOWNLOAD_LINK = '[Content] Download Link',

    ATTESTATION_ARCHIVE = '[Content] Attestation archive',
    ATTESTATION_CONTROLE = '[Content] Attestation controle'

}

export class SelectRows implements TcAction {
    readonly type = ContentActionTypes.SELECT_ROWS;
    constructor(public payload: AbstractFile[]) { }
}

export class DeselectRows implements TcAction {
    readonly type = ContentActionTypes.DESELECT_ROWS;
    constructor(public payload: AbstractFile[]) { }
}

export class DeselectAllRows implements TcAction {
    readonly type = ContentActionTypes.DESELECT_ALL_ROWS;
}

export class DeleteFoldersAndFiles implements TcAction {
    readonly type = ContentActionTypes.DELETE_FOLDERS_AND_FILES;
}

export class DeleteAllFoldersAndFiles implements TcAction {
    readonly type = ContentActionTypes.DELETE_ALL_FOLDERS_AND_FILES;
}

export class RequestFinalDeleteConfirmation implements TcAction {
    readonly type = ContentActionTypes.REQUEST_FINAL_DELETE_CONFIRMATION;
    constructor(public payload: { folders: number[], files: number[], removeAll: boolean }) { }
}

export class RequestFinalDeleteConfirmationSuccess implements TcAction {
    readonly type = ContentActionTypes.REQUEST_FINAL_DELETE_CONFIRMATION_SUCCESS;
    constructor(public payload: { selectedRows: AbstractFile[], removeAll: boolean }) { }
}

export class FinalDelete implements TcAction {
    readonly type = ContentActionTypes.FINAL_DELETE;
    constructor(public payload: string) { }
}

export class FinalDeleteSuccess implements TcAction {
    readonly type = ContentActionTypes.FINAL_DELETE_SUCCESS;
    constructor(public payload: number) { }
}

export class FinalDeleteError implements TcAction {
    readonly type = ContentActionTypes.FINAL_DELETE_SUCCESS;
    constructor(public payload: number) { }
}

export class RestoreSelectedRows implements TcAction {
    readonly type = ContentActionTypes.RESTORE_SELECTED_ROWS;
}

export class RestoreFilesAndFolders implements TcAction {
    readonly type = ContentActionTypes.RESTORE_FILES_AND_FOLDERS;
    constructor(public payload: FilesAndFoldersModel) { }
}

export class RestoreFilesAndFoldersSuccess implements TcAction {
    readonly type = ContentActionTypes.RESTORE_FILES_AND_FOLDERS_SUCCESS;
    constructor(public payload: { selectedRows: AbstractFile[], result: FilesAndFoldersModel }) { }
}

export class RestoreOrphans implements TcAction {
    readonly type = ContentActionTypes.RESTORE_ORPHANS;
    constructor(public payload: { newParentId: number, model: FilesAndFoldersModel }) { }
}

export class RestoreOrphansSuccess implements TcAction {
    readonly type = ContentActionTypes.RESTORE_ORPHANS_SUCCESS;
}

export class DownloadLink implements TcAction {
    readonly type = ContentActionTypes.DOWNLOAD_LINK;
    constructor(public payload: string) { }
}

export class ArchiveAttestation implements TcAction {
    readonly type = ContentActionTypes.ATTESTATION_ARCHIVE;
    constructor(public payload: number) { }
}

export class ControleAttestation implements TcAction {
    readonly type = ContentActionTypes.ATTESTATION_CONTROLE;
    constructor(public payload: number) { }
}

export type ContentActions =
    | SelectRows
    | DeselectRows
    | DeselectAllRows
    | DeleteFoldersAndFiles
    | DeleteAllFoldersAndFiles
    | RequestFinalDeleteConfirmation
    | RequestFinalDeleteConfirmationSuccess
    | FinalDelete
    | FinalDeleteSuccess
    | RestoreSelectedRows
    | RestoreFilesAndFolders
    | RestoreFilesAndFoldersSuccess
    | RestoreOrphans
    | RestoreOrphansSuccess
    | DownloadLink
    | ArchiveAttestation
    | ControleAttestation;
