import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { TcMenuItem, TcMenuComponent, TcDumbComponent } from '@tc/core';
import { Router } from '@angular/router';
import { timingSafeEqual } from 'crypto';

@Component({
  selector: 'generic-menu',
  templateUrl: './generic-menu.component.html',
  styleUrls: ['./generic-menu.component.scss']
})
export class GenericMenuComponent extends TcDumbComponent implements OnInit {

  @Input() hasLogo: boolean;
  @Input() hasBack: boolean;
  @Input() title = '';
  @Input() menuName = '';
  @Input() scrollOffset = 0;
  @Input() hasSearch: boolean;
  @Input() hasMenu: boolean;
  @Input() icon: string;
  @Input() color: string;
  @Input() menuItems: TcMenuItem[] = [];
  @Output() menuItemClick: EventEmitter<TcMenuItem> = new EventEmitter();
  @Output() searchClick: EventEmitter<any> = new EventEmitter();
  @Output() backClick: EventEmitter<any> = new EventEmitter();

  genericMenu: TcMenuComponent;
  @ViewChild('genericMenu', { static: true }) set appGenericMenu(value: TcMenuComponent) {
    this.genericMenu = value;
  }
  constructor() {
    super();
  }

  ngOnInit() {
    this.genericMenu.menuName = this.menuName;
    this.genericMenu.items = this.menuItems;
    this.genericMenu.onMenuItemClick = (item: TcMenuItem) => {
      this.menuItemClick.emit(item);
    };
  }
  goToSearch() {
    this.searchClick.emit();
  }
  onNavigationBack() {
    this.backClick.emit();
  }
}
