import { Injectable } from '@angular/core';
import { SimpleFile, Folder, AbstractFile, FileType, Box, FilesAndFoldersModel } from 'src/generated/api/stockmemoriss';
import { TcNotificationService, TcTranslateService, Logout, TcService } from '@tc/core';
import { MatDialog } from '@angular/material';
import { Store } from '@ngrx/store';
import { AppState } from '../modules/core/store/app.state';
import { RequestFinalDeleteConfirmation, RestoreFilesAndFolders } from '../modules/content/store/actions/content.actions';
import { RouterService } from './router.service';
import { ConfirmDeleteDialogComponent } from '../modules/content/components/dumb/confirm-delete-dialog/confirm-delete-dialog.component';
import { RestoreDialogComponent } from '../modules/content/components/smart/restore-dialog/restore-dialog.component';
import { Location } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class RecycleBinService extends TcService {

    constructor(
        private store: Store<AppState>,
        private dialog: MatDialog,
        private notificationService: TcNotificationService,
        private translateService: TcTranslateService,
        private routerService: RouterService,
        private location: Location) {
        super();

    }

    deleteAllFoldersAndFiles(binBox: Box) {
        this.store.dispatch(new RequestFinalDeleteConfirmation({ folders: [binBox.id], files: [], removeAll: true }));
    }

    deleteFoldersAndFiles(binBox: Box, selectedRows: AbstractFile[], folders: Folder[], files: SimpleFile[]) {

        if (selectedRows.length === 0) {
            this.showNoItemsWarning();
        } else {
            if (this.isAllSelected(selectedRows, folders, files)) {
                this.deleteAllFoldersAndFiles(binBox);
            } else {

                const fileIds = selectedRows.filter(i => i.type === FileType.File).map(i => i.id);
                const folderIds = selectedRows.filter(i => i.type === FileType.Folder).map(i => i.id);

                this.store.dispatch(new RequestFinalDeleteConfirmation({ folders: folderIds, files: fileIds, removeAll: false }));
            }
        }
    }

    private isAllSelected(selectedRows: AbstractFile[], folders: Folder[], files: SimpleFile[]): boolean {

        const itemIds = selectedRows.map(i => i.id);

        const folderIds = folders.map(f => f.id);
        const fileIds = files.map(f => f.id);

        return itemIds.length === folderIds.length + fileIds.length;

    }

    private showNoItemsWarning() {
        this.notificationService.warning(this.translateService.instant('recycle-bin.no-items.warning'));
    }

    finalDeleteConfirmationSuccess() {
        this.notificationService.success(this.translateService.instant('recycle-bin.delete-confirmation'));
    }

    finalDeleteSuccess(items: number) {
        this.routerService.goToHomePage();
        this.dialog.open(ConfirmDeleteDialogComponent, {
            data: items
        });
    }

    finalDeleteError() {
        this.store.dispatch(new Logout());
    }

    restoreSelectedRows(selectedRows: AbstractFile[]) {

        if (selectedRows.length === 0) {
            this.showNoItemsWarning();
        } else {

            const model: FilesAndFoldersModel = {
                files: selectedRows.filter(f => f.type === FileType.File).map(f => f.id),
                folders: selectedRows.filter(f => f.type === FileType.Folder).map(f => f.id),
            };

            this.store.dispatch(new RestoreFilesAndFolders(model));
        }
    }

    restoreFilesAndFoldersSuccess(selectedRows: AbstractFile[], result: FilesAndFoldersModel) {
        if ((!result.files || result.files.length === 0) && (!result.folders || result.folders.length === 0)) {
            this.notificationService.success(this.translateService.instant('recycle-bin.restore-confirmation'));
        } else {

            const files = selectedRows.filter(r => r.type === FileType.File);
            const orphanFiles = files.filter(r => result.files.includes(r.id));

            const folders = selectedRows.filter(r => r.type === FileType.Folder);
            const orphanFolders = folders.filter(r => result.folders.includes(r.id));

            this.dialog.open(RestoreDialogComponent, {
                data: orphanFiles.concat(orphanFolders)
            });
        }
    }

    restoreOrphansSuccess() {
        this.routerService.goToHomePage();
    }

}
