import { createSelector, createFeatureSelector } from '@ngrx/store';
import { JournalState } from './journal.reducer';

export const getJournalState = createFeatureSelector<JournalState>(
    'journal'
);
export const getJournalResults = createSelector(
    getJournalState,
    (state: JournalState) => {
        return state.results;
    }
);

