import { FileExtension } from './file-extension';

export class Utils {

    /**
     * format a date of type Date to string '2009-12-15 12:13:14'
     */
    static formatDateToString(date: Date): string {

        const aaaa = date.getFullYear();
        const dd = date.getDate();
        const mm = (date.getMonth() + 1);

        const ddString = dd < 10 ? '0' + dd : '' + dd;
        const mmString = mm < 10 ? '0' + mm : '' + mm;

        const currentDay = aaaa + '-' + mmString + '-' + ddString;

        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        const hoursString = hours < 10 ? '0' + hours : '' + hours;
        const minutesString = minutes < 10 ? '0' + minutes : '' + minutes;
        const secondsString = seconds < 10 ? '0' + seconds : '' + seconds;

        return currentDay + ' ' + hoursString + ':' + minutesString + ':' + secondsString;

    }

    /**
     * getFileExtension from a fileName
     */
    static getFileExtension(fileName: string): string {

        const extension = fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase();
        if (extension === FileExtension.pdf) {
            return FileExtension.pdf;
        }
        if (extension === 'doc' || extension === 'docx') {
            return FileExtension.doc;
        }
        if (extension === 'jpg' || extension === 'jpeg') {
            return FileExtension.jpg;
        }
        if (extension === 'png') {
            return FileExtension.png;
        }
        if (extension === 'xls' || extension === 'xlsx') {
            return FileExtension.xls;
        }
        if (extension === 'htm' || extension === 'html') {
            return FileExtension.html;
        }
        return FileExtension.any;
    }

    /**
     * check if password contains one uppercase, one digit, one number, one special chararcter and minim 12 characters
     */
    static isValidPassword(password: string): boolean {
        // if (password && !password.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9]{8,})$/)) {
        //     return false; 
        // }
        if (password && !password.match(/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W)(?=.*[0-9]).{12,}$/)) {
            return false;
        }
        return true;
    }

    /**
     * check if email has the correct format
     */
    static isValidEmail(email: string): boolean {
        if (email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
            return true;
        }
        return false;
    }

    static copyToClipboard(val: string) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }

    static getShareKeyAuth(shareId: string, sharePassword: string) {
        return shareId + '#' + sharePassword;
    }

    static isAllowedFileExtension(extension: string) {
        return extension === FileExtension.pdf
            || extension === FileExtension.png
            || extension === FileExtension.jpg
            || extension === FileExtension.doc;
    }

    static isSharedUrl(currentUrl: string): boolean {
        if (currentUrl.includes('shared-file') || currentUrl.includes('shared-folder')) {
            return true;
        }
        return false;
    }
}
