import { Component, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ContentService, SimpleFile } from 'src/generated/api/stockmemoriss';
import { TcSmartComponent, TcMenuItem } from '@tc/core';
import { Observable, Subscription } from 'rxjs';
import { MSMAbstractFilesOnlyList } from 'src/app/modules/core/interfaces/msm-abstract-files-only-list';
import { Location } from '@angular/common';
import { SelectFile, SearchFiles, DownloadFile, ShareFile } from '../../../store/actions/file.actions';
import { getSearchResults, getSearchText } from '../../../store/selectors/file.selectors';
import { FileMenuService } from 'src/app/services/file-menu.service';

@Component({
  selector: 'simple-search',
  templateUrl: './simple-search.component.html',
  styleUrls: ['./simple-search.component.scss']
})
// search only in files
export class SimpleSearchComponent extends TcSmartComponent implements MSMAbstractFilesOnlyList, OnInit, OnDestroy {

  scrollOffset = 0;

  selectFile: EventEmitter<SimpleFile>;
  files$: Observable<SimpleFile[]>;

  step = 'initial';
  filesSubscription: Subscription;
  hasResults: boolean;

  searchText: string;
  searchTextSubscription: Subscription;

  menuItems: TcMenuItem[];

  constructor(
    private store: Store<ContentService>,
    private fileMenuService: FileMenuService,
    private location: Location
  ) {
    super();
  }

  scrollEvent = (event: any): void => {
    if (event.srcElement && event.srcElement.activeElement && event.srcElement.activeElement.nodeName === 'BODY') {
      this.scrollOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    }
  }

  ngOnInit() {
    window.scrollTo(0, 0);

    window.addEventListener('scroll', this.scrollEvent, true);

    this.menuItems = this.fileMenuService.getMenuItems();

    this.files$ = this.store.pipe(select(getSearchResults));

    this.searchTextSubscription = this.store.pipe(select(getSearchText)).subscribe(text => {
      if (text) {
        this.searchText = text;
      }
    });

    this.filesSubscription = this.files$.subscribe(files => {
      if (files.length === 0 && this.step !== 'initial') {
        this.step = 'noResults';
      }
      if (files.length > 0) {
        this.step = 'resultsFound';
      }
    });
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scrollEvent, true);

    if (this.searchTextSubscription) {
      this.searchTextSubscription.unsubscribe();
    }
    if (this.filesSubscription) {
      this.filesSubscription.unsubscribe();
    }

  }

  clear() {
    this.searchText = '';
    this.store.dispatch(new SearchFiles(this.searchText));
    this.step = 'initial';
  }

  onNavigationBack() {
    this.location.back();
  }

  onSelectFile(file: SimpleFile) {
    this.store.dispatch(new SelectFile(file));
  }

  searchFile() {
    this.step = 'search';
    this.store.dispatch(new SearchFiles(this.searchText));
  }

  onMenuFile(event: any) {

    this.fileMenuService.onMenuFile(event.item, event.row);

  }

}
