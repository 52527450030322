import { Component, OnInit, Optional, Inject, ElementRef } from '@angular/core';
import { TcFormComponent, TcTranslateService, TcNotificationService } from '@tc/core';
import { Succession } from 'src/generated/api/stockmemoriss';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Store } from '@ngrx/store';
import { AccountState } from 'src/app/modules/account/store/account.reducer';
import { AddHeir, EditHeir } from 'src/app/modules/account/store/account.actions';
import { Utils } from 'src/app/modules/core/utils';

@Component({
  selector: 'heir-detail',
  templateUrl: './heir-detail.component.html',
  styleUrls: ['./heir-detail.component.scss']
})
// pop-up component (CREATE/EDIT HEIR Account)
export class HeirDetailComponent extends TcFormComponent<Succession> implements OnInit {

  oldPassword = '';

  constructor(
    private store: Store<AccountState>,
    public translateService: TcTranslateService,
    private notificationService: TcNotificationService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Succession,
    public dialogRef: MatDialogRef<HeirDetailComponent>,
    element: ElementRef) {

    super(translateService, element);

    this.fields = [
      {
        key: 'civility',
        type: 'radio',
        templateOptions: {
          options: [
            {
              value: 'M.',
              key: 'M.'
            },
            {
              value: 'Mme',
              key: 'Mme'
            },
          ],
          required: true
        },
      },
      {
        key: 'birthDate',
        type: 'datepicker',
        templateOptions: {
          required: true,
          type: 'text',
          datepickerPopup: 'dd-MMMM-yyyy',
          datepickerOptions: {
            format: 'dd-MM-yyyy hh:mm:ss'
          }
        }
      },
      {
        key: 'email',
        type: 'input',
        templateOptions: {
          type: 'text',
          required: true
        }
      },
      {
        key: 'lastName',
        type: 'input',
        templateOptions: {
          type: 'text',
          required: true
        },
      },
      {
        key: 'firstName',
        type: 'input',
        templateOptions: {
          type: 'text',
          required: true
        },
      },
      {
        key: 'officialDocumentNumber',
        type: 'input',
        templateOptions: {
          type: 'text',
          required: true
        },
      },
      {
        key: 'address1',
        type: 'input',
        templateOptions: {
          type: 'text',
          required: true
        },
      },
      {
        key: 'address2',
        type: 'input',
        templateOptions: {
          type: 'text'
        },
      },
      {
        key: 'codePostal',
        type: 'input',
        templateOptions: {
          type: 'text',
          required: true
        },
      },
      {
        key: 'city',
        type: 'input',
        templateOptions: {
          type: 'text',
          required: true
        },
      },
      {
        key: 'country',
        type: 'input',
        templateOptions: {
          type: 'text',
          required: true
        },
      },
      {
        key: 'password',
        type: 'input',
        templateOptions: {
          type: 'password',
          required: true
        }
      },
      {
        key: 'confirmPassword',
        type: 'input',
        templateOptions: {
          type: 'password',
          required: true,
        },
        validators: {
          fieldMatch: {
            expression: (control) => control.value === this.model.password,
            message: this.translate.instant('sign-up.validations.confirmPasswordMessage')
          },
        },
      }
    ];
  }
  ngOnInit() {
    window.scrollTo(0, 0);
    if (this.data) {
      this.model = Object.assign({}, this.data);

      (this.model as any).confirmPassword = (this.data as any).password;
      this.oldPassword = this.model.password;

    }
    super.ngOnInit();
  }

  // create heir account
  submit(model: Succession) {

    delete (model as any).confirmPassword;

    const formatedDate = model.birthDate;
    const date = new Date(formatedDate);
    model.birthDate = Utils.formatDateToString(date);

    if (this.form.valid && this.validateEmail(model.email) && this.validatePassword(model.password)) {
      if (model.id === 0 || model.id === undefined) {
        this.store.dispatch(new AddHeir(model));
      } else {
        this.store.dispatch(new EditHeir(model));
      }
      this.dialogRef.close(model);
    }
  }

  validateEmail(email: string): boolean {
    if (!Utils.isValidEmail(email)) {
      this.notificationService.error(this.translateService.instant('account-detail.email-format-error'));
      return false;
    }
    return true;
  }

  validatePassword(password: string): boolean {
    if (this.oldPassword !== password) {
      // password must contains one uppercase, one digit and minim 8 characters
      if (!Utils.isValidPassword(password)) {
        this.notificationService.error(this.translateService.instant('modify-password.validations.error'));
        return false;
      }
    }
    return true;
  }

  onClose() {
    this.dialogRef.close();
  }

}
