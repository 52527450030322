import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/modules/core/store/app.state';
import { SimpleFile, Box } from 'src/generated/api/stockmemoriss';
import { Subscription, Observable } from 'rxjs';
import { Location } from '@angular/common';
import { TcSmartComponent, TcMenuComponent, TcMenuItem, Logout } from '@tc/core';
import { getSelectedBox } from 'src/app/modules/content/store/selectors/box.selectors';
import { getSelectedFile } from 'src/app/modules/content/store/selectors/file.selectors';
import { isSharedMode, getSharedFolderId } from 'src/app/modules/content/store/selectors/share.selectors';
import { FileMenuService } from 'src/app/services/file-menu.service';

@Component({
  selector: 'file-viewer-header',
  templateUrl: './file-viewer-header.component.html',
  styleUrls: ['./file-viewer-header.component.scss']
})
export class FileViewerHeaderComponent extends TcSmartComponent implements OnInit, OnDestroy {

  isSharedMode$: Observable<boolean>;
  isSharedMode: boolean;
  sharedModeSubscription: Subscription;

  fileName = '';

  selectedFile: SimpleFile = null;
  selectedFileSubscription: Subscription;

  selectedBox$: Observable<Box>;


  sharedFolderId$: Observable<number>;

  fileViewerMenu: TcMenuComponent;
  @ViewChild('fileViewerMenu', { static: true }) set appTcMenuComponent(value: TcMenuComponent) {
    this.fileViewerMenu = value;
  }

  constructor(
    private store: Store<AppState>,
    private location: Location,
    private fileMenuService: FileMenuService) {
    super();
  }

  ngOnInit() {

    this.isSharedMode$ = this.store.pipe(select(isSharedMode));
    this.sharedModeSubscription = this.isSharedMode$.subscribe(sharedMode => {
      this.isSharedMode = sharedMode;
    });
    this.sharedFolderId$ = this.store.pipe(select(getSharedFolderId));

    this.selectedBox$ = this.store.pipe(select(getSelectedBox));
    this.selectedFileSubscription = this.store.pipe(select(getSelectedFile)).subscribe(selectedFile => {
      if (selectedFile) {
        this.selectedFile = selectedFile;
        this.fileName = selectedFile.name;
      }
    });

    if (!this.isSharedMode) {
      this.fileViewerMenu.menuName = 'file-menu';
      this.fileViewerMenu.items = this.fileMenuService.getMenuItems();
    } else {
      this.fileViewerMenu.menuName = 'home-page-menu';
      this.fileViewerMenu.items = [
        {
          name: 'logout',
        }];

    }
    this.fileViewerMenu.onMenuItemClick = (item: TcMenuItem) => {
      if (item.name === 'logout') {
        this.store.dispatch(new Logout());
      } else {
        this.fileMenuService.onMenuFile(item, this.selectedFile);
      }
    };

  }

  ngOnDestroy() {
    if (this.selectedFileSubscription) {
      this.selectedFileSubscription.unsubscribe();
    }

    if (this.sharedModeSubscription) {
      this.sharedModeSubscription.unsubscribe();
    }
  }

  onNavigationBack() {
    this.location.back();
  }

}
