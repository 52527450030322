import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'confirm-conservation-duration-dialog',
  templateUrl: './confirm-conservation-duration-dialog.component.html',
  styleUrls: ['./confirm-conservation-duration-dialog.component.scss']
})
export class ConfirmConservationDurationDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<ConfirmConservationDurationDialogComponent>
  ) { }

  ngOnInit() {
  }

  onClose() {
    this.dialogRef.close();
  }
}
