import { Component, OnInit } from '@angular/core';
import { TcLoginFormComponent, TcAuthenticationService, Logout } from '@tc/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TcAuthState } from '@tc/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RouterService } from 'src/app/services/router.service';
import { Utils } from 'src/app/modules/core/utils';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'login-component',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends TcLoginFormComponent implements OnInit {

  sharedUrl: string;

  form: FormGroup;

  constructor(
    formBuilder: FormBuilder,
    store: Store<TcAuthState>,
    authService: TcAuthenticationService,
    router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private routerService: RouterService) {
    super(formBuilder, store, authService, router);
  }

  ngOnInit() {

    super.ngOnInit();

    const returnUrl = this.route.snapshot.queryParams.returnUrl;

    // if url contains returnUrl => check if the return url contains share-file or share-folder
    if (returnUrl) {
      // if returnUrl contains shared-file or shared-folder => when create a new user, the user should be of type lecture
      if (Utils.isSharedUrl(returnUrl)) {
        this.sharedUrl = returnUrl;
      }
    }
  }

  register() {
    // if sharedUrl exists => keep sharedUrl during account creation => send sharedUrl as param to sign-up route
    if (this.sharedUrl) {
      this.routerService.goToSignUpPageWithSharedUrl(this.sharedUrl);
    } else {
      this.routerService.goToSignUpPage();
    }
  }

  forget() {
    // if sharedUrl exists => keep sharedUrl during account creation => send sharedUrl as param to sign-up route
    if (this.sharedUrl) {
      this.routerService.goToForgetPasswordPageWithSharedUrl(this.sharedUrl);
    } else {
      this.routerService.goToForgetPasswordPage();
    }
  }

  version = environment.version;

  
  // 3959
  openChangelog() {
    window.open('/assets/changelog/changelog-'+environment.version+'.pdf', '_blank');
  }

}
