import { Component, OnInit } from '@angular/core';
import { TcTranslateService, TcPage } from '@tc/core';

@Component({
  selector: 'F18-journal',
  templateUrl: './F18-journal.component.html',
  styleUrls: ['./F18-journal.component.scss']
})
export class F18JournalComponent extends TcPage implements OnInit {

  constructor() {
    super();
  }
  ngOnInit() {
  }

}
