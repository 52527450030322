import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { TcSmartComponent, TcMenuItem } from '@tc/core';
import { Observable, Subscription } from 'rxjs';
import { Folder, SimpleFile, User, Box, AbstractFile } from 'src/generated/api/stockmemoriss';
import { Store, select } from '@ngrx/store';
import { LoadFolderContent, SelectFolder } from '../../../store/actions/folder.actions';
import { SelectFile } from '../../../store/actions/file.actions';
import { getFolders, getSelectedFolderId } from '../../../store/selectors/folder.selectors';
import { getFiles } from '../../../store/selectors/file.selectors';
import { getAccountUser } from 'src/app/modules/account/store/account.selectors';
import { isSharedMode } from '../../../store/selectors/share.selectors';
import { getSelectedBox } from '../../../store/selectors/box.selectors';
import { FileMenuService } from 'src/app/services/file-menu.service';
import { FolderMenuService } from 'src/app/services/folder-menu.service';
import { SelectRows, DeselectRows } from '../../../store/actions/content.actions';
import { getSelectedRows } from '../../../store/selectors/content.selectors';
import { CheckRestitutionPassword } from 'src/app/modules/account/store/account.actions';
import { ContentState } from '../../../store/content.state';

@Component({
  selector: 'navigate-folders-and-files',
  templateUrl: './navigate-folders-and-files.component.html',
  styleUrls: ['./navigate-folders-and-files.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigateFoldersAndFilesComponent extends TcSmartComponent implements OnInit, OnDestroy {


  isSharedMode$: Observable<boolean>;
  isSharedModeSubscription: Subscription;
  isSharedMode: boolean;

  selectedBox$: Observable<Box>;
  selectedBoxSubscription: Subscription;
  selectedBox: Box;

  folders$: Observable<Folder[]>;
  files$: Observable<SimpleFile[]>;

  folderMenuItems: TcMenuItem[];
  fileMenuItems: TcMenuItem[];

  selectedFolderId$: Observable<number>;

  private selectedFolderIdSubscription: Subscription;

  selectedRows$: Observable<AbstractFile[]>;

  user: User;
  userSubscription: Subscription;

  constructor(
    private store: Store<ContentState>,
    private fileMenuService: FileMenuService,
    private folderMenuService: FolderMenuService) {
    super();
  }

  ngOnInit() {

    window.scrollTo(0, 0);

    this.fileMenuItems = this.fileMenuService.getMenuItems();
    this.folderMenuItems = this.folderMenuService.getMenuItems();

    this.store.dispatch(new CheckRestitutionPassword());

    this.isSharedMode$ = this.store.pipe(select(isSharedMode));

    this.selectedBox$ = this.store.pipe(select(getSelectedBox));

    this.folders$ = this.store.pipe(select(getFolders));
    this.files$ = this.store.pipe(select(getFiles));

    this.selectedFolderId$ = this.store.pipe(select(getSelectedFolderId));

    this.selectedFolderIdSubscription = this.selectedFolderId$.subscribe(selectedFolderId => {
      if (selectedFolderId) {
        this.store.dispatch(new LoadFolderContent(selectedFolderId));
      }
    });

    this.userSubscription = this.store.pipe(select(getAccountUser)).subscribe(user => {
      if (user) {
        this.user = user;
      }
    });

    this.selectedBoxSubscription = this.selectedBox$.subscribe(box => {
      if (box) {
        this.selectedBox = box;
      }
    });

    this.selectedRows$ = this.store.pipe(select(getSelectedRows));

    this.isSharedModeSubscription = this.isSharedMode$.subscribe(sharedMode => {
      this.isSharedMode = sharedMode;
    });

  }

  ngOnDestroy() {
    if (this.selectedFolderIdSubscription) {
      this.selectedFolderIdSubscription.unsubscribe();
    }

    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }

    if (this.selectedBoxSubscription) {
      this.selectedBoxSubscription.unsubscribe();
    }

    if (this.isSharedModeSubscription) {
      this.isSharedModeSubscription.unsubscribe();
    }

  }

  onSelectFolder(folder: Folder) {
    if ((this.selectedBox && !this.selectedBox.isBin) || this.isSharedMode) {
      this.store.dispatch(new SelectFolder(folder.id));
    }
  }

  onSelectFile(file: SimpleFile) {
    if ((this.selectedBox && !this.selectedBox.isBin) || this.isSharedMode) {
      this.store.dispatch(new SelectFile(file));
    }
  }

  onMenuFolder(event: any) {
    this.folderMenuService.onMenuFolder(event.item, event.row);
  }

  onMenuFile(event: any) {
    this.fileMenuService.onMenuFile(event.item, event.row);
  }

  onSelectRows(rows: AbstractFile[]) {
    this.store.dispatch(new SelectRows(rows));
  }

  onDeselectRows(rows: AbstractFile[]) {
    this.store.dispatch(new DeselectRows(rows));
  }

}


