import { TcAction } from '@tc/core';
import { AbstractFile } from 'src/generated/api/stockmemoriss';

export enum CertificateActionTypes {

    SELECT_FILES_FOR_CERTIFICATE = '[Content] Select Files For Certificate',
    SELECT_FILES_FOR_CERTIFICATE_CANCEL = '[Content] Select Files For Certificate Cancel',
    SELECT_FILES_FOR_CERTIFICATE_DONE = '[Content] Select Files For Certificate Done',
    DELETE_FILE_FROM_CERTIFICATE_FILES = '[Content] Delete File From Certificate Files',
    PAY_CERTIFICATE = '[Content] Pay Certificate',
    GENERATE_CERTIFICATE = '[Content] Generate Certificate',
    GENERATE_CERTIFICATE_SUCCESS = '[Content] Generate Certificate Success'

}

export class SelectFilesForCertificate implements TcAction {
    readonly type = CertificateActionTypes.SELECT_FILES_FOR_CERTIFICATE;
}

export class SelectFilesForCertificateCancel implements TcAction {
    readonly type = CertificateActionTypes.SELECT_FILES_FOR_CERTIFICATE_CANCEL;
}

export class SelectFilesForCertificateDone implements TcAction {
    readonly type = CertificateActionTypes.SELECT_FILES_FOR_CERTIFICATE_DONE;
}

export class DeleteFileFromCertificateFiles implements TcAction {
    readonly type = CertificateActionTypes.DELETE_FILE_FROM_CERTIFICATE_FILES;
    constructor(public payload: AbstractFile) { }
}

export class GenerateCertificate implements TcAction {
    readonly type = CertificateActionTypes.GENERATE_CERTIFICATE;
}

export class GenerateCertificateSuccess implements TcAction {
    readonly type = CertificateActionTypes.GENERATE_CERTIFICATE_SUCCESS;
}

export class PayCertificate implements TcAction {
    readonly type = CertificateActionTypes.PAY_CERTIFICATE;
}

export type CertificateActions =
    | SelectFilesForCertificate
    | SelectFilesForCertificateCancel
    | SelectFilesForCertificateDone
    | DeleteFileFromCertificateFiles
    | GenerateCertificate
    | GenerateCertificateSuccess
    | PayCertificate;
