import { Component, OnInit, OnDestroy } from '@angular/core';
import { TcAppComponent, TcConfigService, TcAuthenticationService, TcAppState, getAuthenticatedUserId } from '@tc/core';
import { Store, select } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadUser } from './modules/account/store/account.actions';
import { OAuthErrorEvent } from 'angular-oauth2-oidc';
import { AppState } from './modules/core/store/app.state';
import { Subscription } from 'rxjs';
import { getAccountUser } from './modules/account/store/account.selectors';
import { UpdateService } from './services/update.service';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material';
import { CfSubscriptionDialogComponent } from './modules/content/components/dumb/cf-subscription-dialog/cf-subscription-dialog.component';
import { User, PartnerService } from 'src/generated/api/stockmemoriss';
import { RouterService } from './services/router.service';
import { PartnerProvider } from './modules/core/providers/partner-provider';
import { Utils } from './modules/core/utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends TcAppComponent implements OnInit, OnDestroy {

  authUserIdSubscription: Subscription;
  authStepsSubscription: Subscription;
  authUserSubscription: Subscription;

  // customCssUrl: string;

  constructor(
    store: Store<AppState>,
    configService: TcConfigService,
    authService: TcAuthenticationService,
    router: Router,
    private swUpdate: UpdateService,
    private dialog: MatDialog,
    private routerService: RouterService,
    public sanitizer: DomSanitizer,
    private titleService: Title,
    private partnerProvider: PartnerProvider) {

    super(store, configService, authService, router);
    this.swUpdate.setup();
  }


  ngOnInit() {

    super.ngOnInit();

    const partnerConfig = this.partnerProvider.getPartnerConfig();

    if (partnerConfig && partnerConfig.applicationName) {
      this.titleService.setTitle(partnerConfig.applicationName);
    }

    // this.customCssUrl = '/assets/custom/' + window.location.hostname + '/custom.css';


    // if (this.authService.isAuthenticated()) {
    //   this.authService.oauthService.loadDiscoveryDocument().then(() => {

    //     if (this.authService.oauthService.getAccessToken() !== null) {
    //       this.authService.oauthService.refreshToken();
    //     }

    //   });
    // }

    // this.authService.oauthService.events.subscribe(event => {
    //   if (event instanceof OAuthErrorEvent) {
    //     console.error(event);
    //   } else {
    //     console.log(event);
    //     if (event.type === 'token_expires') {
    //       if (this.authService.isAuthenticated()) {
    //         this.authService.oauthService.refreshToken();
    //       }

    //     }
    //   }
    // });

    this.authUserIdSubscription = this.store.pipe(select(getAuthenticatedUserId)).subscribe(authUserId => {
      if (authUserId) {
        this.store.dispatch(new LoadUser(this.authService.getAuthUserId()));
      }
    });


    this.authStepsSubscription = this.authService.getAuthStep().subscribe(authStep => {



      if (authStep === 'LOGIN_SUCCESS') {

        this.authUserSubscription = this.store.pipe(select(getAccountUser)).subscribe(currentUser => {
          if (currentUser) {

            const currentUrl = this.router.url;

            if (currentUser.role === User.RoleEnum.Lecteur) {

              if (currentUser.dateAcceptationCGU && Utils.isSharedUrl(currentUrl)) {
                this.dialog.open(CfSubscriptionDialogComponent);
              }

            }

          }

        });

      }
    });
  }

  // getCustomUrl() {
  //   return this.sanitizer.bypassSecurityTrustResourceUrl(this.customCssUrl);
  // }

  ngOnDestroy() {

    if (this.authUserIdSubscription) {
      this.authUserIdSubscription.unsubscribe();
    }

    if (this.authStepsSubscription) {
      this.authStepsSubscription.unsubscribe();
    }

    if (this.authUserSubscription) {
      this.authUserSubscription.unsubscribe();
    }

  }

}
