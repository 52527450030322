import { boxReducer } from './reducers/box.reducer';
import { BoxActionTypes } from './actions/box.actions';
import { FileActionTypes } from './actions/file.actions';
import { fileReducer } from './reducers/file.reducer';
import { FolderActionTypes } from './actions/folder.actions';
import { folderReducer } from './reducers/folder.reducer';
import { CertificateActionTypes } from './actions/certificate.actions';
import { certificateReducer } from './reducers/certificate.reducer';
import { ContentActionTypes } from './actions/content.actions';
import { commonReducer } from './reducers/common.reducer';
import { ShareActionTypes } from './actions/share.actions';
import { shareReducer } from './reducers/share.reducer';
import { SelectFoldersAndFilesActionTypes } from './actions/select-folders-and-files.actions';
import { selectFoldersAndFilesReducer } from './reducers/select-folders-and-files.reducer';
import { MoveActionTypes } from './actions/move.actions';
import { moveReducer } from './reducers/move.reducer';
import { ContentState, initialContentState } from './content.state';
import { ExpiredFilesActionTypes } from './actions/expired-files.actions';
import { expiredFilesReducer } from './reducers/expired-files.reducer';

export function contentReducer(
    state = initialContentState,
    action: any
): ContentState {
    if (Object.values(BoxActionTypes).includes(action.type)) {
        return boxReducer(state, action); // boxReducer
    } else if (Object.values(FileActionTypes).includes(action.type)) {
        return fileReducer(state, action); // fileReducer
    } else if (Object.values(FolderActionTypes).includes(action.type)) {
        return folderReducer(state, action); // folderReducer
    } else if (Object.values(CertificateActionTypes).includes(action.type)) {
        return certificateReducer(state, action); // certificateReducer
    } else if (Object.values(MoveActionTypes).includes(action.type)) {
        return moveReducer(state, action); // moveReducer
    } else if (Object.values(ExpiredFilesActionTypes).includes(action.type)) {
        return expiredFilesReducer(state, action); // expiredFilesReducer
    } else if (Object.values(SelectFoldersAndFilesActionTypes).includes(action.type)) {
        return selectFoldersAndFilesReducer(state, action); // selectFoldersAndFilesReducer
    } else if (Object.values(ShareActionTypes).includes(action.type)) {
        return shareReducer(state, action); // shareReducer
    } else if (Object.values(ContentActionTypes).includes(action.type)) {
        return commonReducer(state, action); // commonReducer
    } else {
        return state;
    }
}
