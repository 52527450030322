import { TcAction } from '@tc/core';
import { Message } from 'src/generated/api/stockmemoriss/model/message';

export enum ContactActionTypes {
    SEND_CONTACT_SUGGESTION_EMAIL = '[Contact] Send Contact Suggestion Email',
    SEND_CONTACT_SUGGESTION_EMAIL_SUCCESS = '[Contact] Send Contact Suggestion Email Success',
    SEND_CONTACT_SUPPORT_EMAIL = '[Contact] Send Contact Support Email',
    SEND_CONTACT_SUPPORT_EMAIL_SUCCESS = '[Contact] Send Contact Support Email Success',
}

export class SendContactSuggestionEmail implements TcAction {
    readonly type = ContactActionTypes.SEND_CONTACT_SUGGESTION_EMAIL;
    constructor(public payload: Message) { }
}

export class SendContactSuggestionEmailSuccess implements TcAction {
    readonly type = ContactActionTypes.SEND_CONTACT_SUGGESTION_EMAIL_SUCCESS;
    constructor(public payload: Message) { }
}

export class SendContactSupportEmail implements TcAction {
    readonly type = ContactActionTypes.SEND_CONTACT_SUPPORT_EMAIL;
    constructor(public payload: Message) { }
}

export class SendContactSupportEmailSuccess implements TcAction {
    readonly type = ContactActionTypes.SEND_CONTACT_SUPPORT_EMAIL_SUCCESS;
    constructor(public payload: Message) { }
}

export type ContactActions =
    SendContactSuggestionEmail
    | SendContactSuggestionEmailSuccess
    | SendContactSupportEmail
    | SendContactSupportEmailSuccess;
