import { Component, OnInit } from '@angular/core';
import { TcPage } from '@tc/core';

@Component({
  selector: 'F28-CGU-accept',
  templateUrl: './F28-CGU-accept.component.html',
  styleUrls: ['./F28-CGU-accept.component.scss']
})
export class F28CGUAcceptComponent extends TcPage implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
  }

}
