import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Journal } from 'src/generated/api/stockmemoriss';
import { TcDumbComponent } from '@tc/core';

@Component({
  selector: 'journal-item',
  templateUrl: './journal-item.component.html',
  styleUrls: ['./journal-item.component.scss']
})
export class JournalItemComponent extends TcDumbComponent implements OnInit {

  item: Journal;
  constructor(
    private dialogRef: MatDialogRef<JournalItemComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Journal
  ) {
    super();
   }

  ngOnInit() {
    this.item = this.data;
  }

  onClose() {
    this.dialogRef.close();
  }

}
