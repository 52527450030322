import { FileActions, FileActionTypes } from '../actions/file.actions';
import { initialContentState, ContentState } from '../content.state';

export function fileReducer(
    state = initialContentState,
    action: FileActions
): ContentState {
    switch (action.type) {
        case FileActionTypes.SEARCH_FILES: {
            return {
                ...state,
                searchText: action.payload,
                searchFiles: []
            };
        }
        case FileActionTypes.SEARCH_FILES_SUCCESS: {
            return {
                ...state,
                searchFiles: action.payload,
            };
        }
        case FileActionTypes.ADD_SCANNED_IMAGE_SUCCESS:
        case FileActionTypes.ADD_FILE_SUCCESS: {
            return {
                ...state,
                files: state.files.concat(action.payload)
            };
        }
        case FileActionTypes.EDIT_FILE_SUCCESS: {

            const newFiles = state.files.filter(file => file.parentId === state.selectedFolderId);

            const newSelectedRows = state.selectedRows.filter(row => row.id !== action.payload.file.id);

            return Object.assign({
                ...state,
                files: newFiles,
                selectedRows: newSelectedRows

            });

        }
        case FileActionTypes.DELETE_FILE_SUCCESS:
            return Object.assign({
                ...state,
                files: state.files.filter(file => file.id !== action.payload)
            });
        case FileActionTypes.SELECT_FILE: {
            return {
                ...state,
                selectedFile: action.payload
            };
        }
        default: {
            return state;
        }
    }
}


