import { Component, OnInit } from '@angular/core';
import { TcPage } from '@tc/core';

@Component({
  selector: 'F07-simple-search',
  templateUrl: './F07-simple-search.component.html',
  styleUrls: ['./F07-simple-search.component.scss']
})
export class F07SimpleSearchComponent extends TcPage implements OnInit {

  constructor() { super(); }

  ngOnInit() {
  }

}
