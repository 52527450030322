import { Faq } from 'src/generated/api/stockmemoriss';
import { FaqActionTypes } from './faq.actions';

export interface FaqState {
    faq: Faq[];
}


export const initialFaqState: FaqState = {
    faq: []
};

export function faqReducer(
    state = initialFaqState,
    action
): FaqState {
    switch (action.type) {
        case FaqActionTypes.LOAD_FAQ_SUCCESS: {
            return {
                ...state,
                faq: action.payload
            };
        }
        default:
            return state;
    }
}
