import { Component, OnInit, ElementRef, Optional, Inject } from '@angular/core';
import { TcFormComponent, TcTranslateService, TcNotificationService } from '@tc/core';
import { User } from 'src/generated/api/stockmemoriss';
import { Store } from '@ngrx/store';
import { AccountState } from 'src/app/modules/account/store/account.reducer';
import { EditUser } from 'src/app/modules/account/store/account.actions';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Utils } from 'src/app/modules/core/utils';

@Component({
  selector: 'account-detail',
  templateUrl: './account-detail.component.html',
  styleUrls: ['./account-detail.component.scss']
})
/**
 * edit account details popup
 */
export class AccountDetailComponent extends TcFormComponent<User> implements OnInit {

  constructor(
    private store: Store<AccountState>,
    public translateService: TcTranslateService,
    private notificationService: TcNotificationService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: User,
    public dialogRef: MatDialogRef<AccountDetailComponent>,
    element: ElementRef) {

    super(translateService, element);

    this.fields = [
      {
        key: 'civility',
        type: 'radio',
        templateOptions: {
          options: [
            {
              value: 'M',
              key: 'M.'
            },
            {
              value: 'Mme',
              key: 'Mme'
            },
          ],
          required: true
        },
      },
      {
        key: 'lastName',
        type: 'input',
        templateOptions: {
          type: 'text',
          required: true
        },
      },
      {
        key: 'firstName',
        type: 'input',
        templateOptions: {
          type: 'text',
          required: true
        },
      },
      {
        key: 'username',
        type: 'input',
        templateOptions: {
          type: 'email',
          readonly: true
        },

      },
      {
        key: 'address',
        type: 'input',
        templateOptions: {
          type: 'text',
        },
      },
      {
        key: 'telephone',
        type: 'input',
        templateOptions: {
          type: 'text',
        },
      },
      {
        key: 'language',
        type: 'select',
        defaultValue: 1,
        templateOptions: {
          required: true,
          options: [
            { value: 1, label: this.translate.instant('account-detail.labels.languageOptions') },
          ]
        },
      },
    ];
  }
  ngOnInit() {
    if (this.data) {
      this.model = Object.assign({}, this.data);
    }
    super.ngOnInit();
  }

  submit(model: User) {
    if (this.form.valid &&
      this.validateRequiredFields(model.firstName) && this.validateRequiredFields(model.lastName) &&
      this.validatePassword(model.password)) {

      this.store.dispatch(new EditUser({ user: model, actionType: 'account-detail' }));
      this.dialogRef.close(model);
    }
  }

  validatePassword(password: string): boolean {
    if (!Utils.isValidPassword(password)) {
      this.notificationService.error(this.translateService.instant('account-detail.password-format-error'));
      return false;
    }
    return true;
  }

  validateRequiredFields(name: string): boolean {

    let ok = true;

    const nameSpaces = name.split(' ').length - 1;
    if (nameSpaces === name.length) {
      ok = false;
    }

    if (!ok) {
      this.notificationService.error(this.translateService.instant('account-detail.error'));
      return false;
    }

    return ok;
  }

  onClose() {
    this.dialogRef.close();
  }

}
