import { TcAction } from '@tc/core';
import { Folder, AbstractFile, Share } from 'src/generated/api/stockmemoriss';

export enum FolderActionTypes {

    // FOLDER ACTIONS
    ADD_FOLDER = '[Content] Add Folder',
    ADD_FOLDER_SUCCESS = '[Content] Add Folder Success',
    EDIT_FOLDER = '[Content] Edit Folder',
    EDIT_FOLDER_SUCCESS = '[Content] Edit Folder Success',
    DELETE_FOLDER = '[Content] Delete Folder',
    DELETE_FOLDER_SUCCESS = '[Content] Delete Folder Success',
    REQUEST_DELETE_FOLDER = '[Content] Request Delete Folder',
    REQUEST_DELETE_FOLDER_SUCCESS = '[Content] Request Delete Folder Success',
    SELECT_FOLDER = '[Content] Select Folder',

    // LOAD FOLDER CONTENT
    LOAD_FOLDER_CONTENT = '[Content] Load Folder Content',
    LOAD_FOLDER_CONTENT_SUCCESS = '[Content] Load Folder Content Success',

    // Back to parent folder
    BACK_TO_PARENT_FOLDER = '[Content] Back To Parent Folder',

    // SEND FOLDER
    SEND_FOLDER = '[Content] Send Folder',

    // SHARE FOLDER
    SHARE_FOLDER = '[Content] Share Folder',

    // SEND FOLDER BY EMAIL
    SEND_FOLDER_BY_EMAIL = '[Content] Send Folder By Email',
    SEND_FOLDER_BY_EMAIL_SUCCESS = '[Content] Send Folder By Email Success',

    // SHARE FOLDER BY LINK
    SHARE_FOLDER_BY_LINK = '[Content] Share Folder By Link',
    SHARE_FOLDER_BY_LINK_SUCCESS = '[Content] Share Folder By Link Success',

    // SHARE FOLDER BY EMAIL
    SHARE_FOLDER_BY_EMAIL = '[Content] Share Folder By Email',
    SHARE_FOLDER_BY_EMAIL_SUCCESS = '[Content] Share Folder By Email Success'

}

export class LoadFolderContent implements TcAction {
    readonly type = FolderActionTypes.LOAD_FOLDER_CONTENT;
    constructor(public payload: number) { }
}

export class LoadFolderContentSuccess implements TcAction {
    readonly type = FolderActionTypes.LOAD_FOLDER_CONTENT_SUCCESS;
    constructor(public payload: Folder) { }
}

export class AddFolder implements TcAction {
    readonly type = FolderActionTypes.ADD_FOLDER;
    constructor(public payload: Folder) { }
}

export class AddFolderSuccess implements TcAction {
    readonly type = FolderActionTypes.ADD_FOLDER_SUCCESS;
    constructor(public payload: Folder) { }
}

export class EditFolder implements TcAction {
    readonly type = FolderActionTypes.EDIT_FOLDER;
    constructor(public payload: { folder: Folder, type: string }) { }
}

export class EditFolderSuccess implements TcAction {
    readonly type = FolderActionTypes.EDIT_FOLDER_SUCCESS;
    constructor(public payload: { folder: Folder, type: string }) { }
}

export class DeleteFolder implements TcAction {
    readonly type = FolderActionTypes.DELETE_FOLDER;
    constructor(public payload: number) { }
}

export class DeleteFolderSuccess implements TcAction {
    readonly type = FolderActionTypes.DELETE_FOLDER_SUCCESS;
    constructor(public payload: number) { }
}

export class RequestDeleteFolder implements TcAction {
    readonly type = FolderActionTypes.REQUEST_DELETE_FOLDER;
    constructor(public payload: Folder) { }
}

export class SelectFolder implements TcAction {
    readonly type = FolderActionTypes.SELECT_FOLDER;
    constructor(public payload: number) { }
}

export class SendFolder implements TcAction {
    readonly type = FolderActionTypes.SEND_FOLDER;
    constructor(public payload: number) { }
}

export class ShareFolder implements TcAction {
    readonly type = FolderActionTypes.SHARE_FOLDER;
    constructor(public payload: number) { }
}

export class BackToParentFolder implements TcAction {
    readonly type = FolderActionTypes.BACK_TO_PARENT_FOLDER;
    constructor(public payload: number) { }
}

export class SendFolderByEmail implements TcAction {
    readonly type = FolderActionTypes.SEND_FOLDER_BY_EMAIL;
    constructor(public payload: number) { }
}

export class SendFolderByEmailSuccess implements TcAction {
    readonly type = FolderActionTypes.SEND_FOLDER_BY_EMAIL_SUCCESS;
    constructor() { }
}

export class ShareFolderByEmail implements TcAction {
    readonly type = FolderActionTypes.SHARE_FOLDER_BY_EMAIL;
    constructor(public payload: { folderId: number, duration?: number, password?: string }) { }
}

export class ShareFolderByEmailSuccess implements TcAction {
    readonly type = FolderActionTypes.SHARE_FOLDER_BY_EMAIL_SUCCESS;
    constructor(public payload: string) { }
}

export class ShareFolderByLink implements TcAction {
    readonly type = FolderActionTypes.SHARE_FOLDER_BY_LINK;
    constructor(public payload: { folderId: number, duration?: number, password?: string }) { }
}

export class ShareFolderByLinkSuccess implements TcAction {
    readonly type = FolderActionTypes.SHARE_FOLDER_BY_LINK_SUCCESS;
    constructor(public payload: string) { }
}

export type FolderActions =
    | BackToParentFolder
    | LoadFolderContent
    | LoadFolderContentSuccess
    | AddFolder
    | AddFolderSuccess
    | EditFolder
    | EditFolderSuccess
    | DeleteFolder
    | DeleteFolderSuccess
    | RequestDeleteFolder
    | SelectFolder
    | SendFolder
    | SendFolderByEmail
    | SendFolderByEmailSuccess
    | ShareFolder
    | ShareFolderByEmail
    | ShareFolderByEmailSuccess
    | ShareFolderByLink
    | ShareFolderByLinkSuccess;
