
import { ShareActions, ShareActionTypes } from '../actions/share.actions';
import { initialContentState, ContentState } from '../content.state';

export function shareReducer(
    state = initialContentState,
    action: ShareActions
): ContentState {
    switch (action.type) {

        case ShareActionTypes.OPEN_SHARED_FOLDER_OR_FILE: {
            return {
                ...state,

                isSharedMode: true,

                sharedId: action.payload.sharedId,
                sharedFileId: null,
                sharedFolderId: null,
                sharedPassword: null
            };
        }

        case ShareActionTypes.OPEN_SHARED_FOLDER: {
            return {
                ...state,

                isSharedMode: true,

                selectedBox: null,

                selectedFolderId: action.payload.share.folderId,

                sharedId: action.payload.share.id,
                sharedFolderId: action.payload.share.folderId,
                sharedFileId: action.payload.share.fileId,
                sharedPassword: action.payload.sharedPassword

            };
        }

        case ShareActionTypes.OPEN_SHARED_FILE: {
            return {
                ...state,

                isSharedMode: true,

                selectedFile: action.payload.file,
                selectedBox: null,

                sharedId: action.payload.share.id,
                sharedFolderId: action.payload.share.folderId,
                sharedFileId: action.payload.share.fileId,
                sharedPassword: action.payload.sharedPassword

            };
        }
        default: {
            return state;
        }
    }
}
