import { Component, OnInit, OnDestroy } from '@angular/core';
import { TcSmartComponent, TcMenuItem } from '@tc/core';
import { Box } from 'src/generated/api/stockmemoriss';
import { Store, select } from '@ngrx/store';
import { LoadBoxes, SelectBox } from '../../../store/actions/box.actions';
import { getBoxes } from '../../../store/selectors/box.selectors';
import { MSMAccountService } from 'src/app/services/msm-account.service';
import { Observable } from 'rxjs';
import { BoxMenuService } from 'src/app/services/box-menu.service';
import { CheckRestitutionPassword } from 'src/app/modules/account/store/account.actions';
import { ContentState } from '../../../store/content.state';


@Component({
  selector: 'box-list-component',
  templateUrl: './box-list.component.html',
  styleUrls: ['./box-list.component.scss']
})
export class BoxListComponent extends TcSmartComponent implements OnInit, OnDestroy {

  boxes$: Observable<Box[]>;

  menuItems: TcMenuItem[];

  constructor(
    private store: Store<ContentState>,
    private msmAccountService: MSMAccountService,
    private boxMenuService: BoxMenuService) {
    super();
  }

  ngOnInit() {

    this.store.dispatch(new CheckRestitutionPassword());

    this.menuItems = this.boxMenuService.getMenuItems();

    this.store.dispatch(new LoadBoxes());

    this.boxes$ = this.store.pipe(select(getBoxes));

  }

  onMenuBox(event: any) {
    this.boxMenuService.onMenuBox(event.menuItem, event.box);
  }

  onSelectBox(row: Box) {
    this.store.dispatch(new SelectBox(row));
  }

  ngOnDestroy() {
    this.msmAccountService.dispose();
  }

}
