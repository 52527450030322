import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ContactService, Message } from 'src/generated/api/stockmemoriss';
import { Observable } from 'rxjs';
import { TcAction, TcNotificationService, TcTranslateService } from '@tc/core';
import { mergeMap, map } from 'rxjs/operators';
import { MSMContactService } from 'src/app/services/msm-contact.service';
import { ContactActionTypes, SendContactSupportEmail, SendContactSupportEmailSuccess, SendContactSuggestionEmail, SendContactSuggestionEmailSuccess } from './contact.actions';

@Injectable()
export class ContactEffects {
    constructor(
        private actions$: Actions,
        private contactService: ContactService,
        private notificationService: TcNotificationService,
        private translateService: TcTranslateService,
        private msmContactService: MSMContactService
    ) { }


    @Effect()
    sendContactSupportEmail$: Observable<TcAction> = this.actions$.pipe(
        ofType<SendContactSupportEmail>(
            ContactActionTypes.SEND_CONTACT_SUPPORT_EMAIL
        ),
        mergeMap((action: SendContactSupportEmail) =>
            this.contactService.contact(action.payload).pipe(
                map(
                    (message: Message) =>
                        new SendContactSupportEmailSuccess(message)
                )
            )
        )
    );

    @Effect()
    sendContactSuggestionEmail$: Observable<TcAction> = this.actions$.pipe(
        ofType<SendContactSuggestionEmail>(
            ContactActionTypes.SEND_CONTACT_SUGGESTION_EMAIL
        ),
        mergeMap((action: SendContactSuggestionEmail) =>
            this.contactService.sendSuggestion(action.payload).pipe(
                map(
                    (message: Message) =>
                        new SendContactSuggestionEmailSuccess(message)
                )
            )
        )
    );

    @Effect({ dispatch: false })
    sendContactSupportEmailSuccess = this.actions$.pipe(
        ofType<SendContactSupportEmailSuccess>(ContactActionTypes.SEND_CONTACT_SUPPORT_EMAIL_SUCCESS),
        map((action: SendContactSupportEmailSuccess) => {
            this.notificationService.success(this.translateService.instant('contact.validations.success'));
            this.msmContactService.showModalAfterSendEmailWithSuccess();

        })
    );

    @Effect({ dispatch: false })
    sendContactSuggestionEmailSuccess = this.actions$.pipe(
        ofType<SendContactSuggestionEmailSuccess>(ContactActionTypes.SEND_CONTACT_SUGGESTION_EMAIL_SUCCESS),
        map((action: SendContactSuggestionEmailSuccess) => {
            this.notificationService.success(this.translateService.instant('contact.validations.success'));
            this.msmContactService.showModalAfterSendEmailWithSuccess();

        })
    );

}

