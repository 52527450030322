import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ContentService, AbstractFile, Folder } from 'src/generated/api/stockmemoriss';
import { Observable } from 'rxjs';
import { TcAction, TcNotificationService, TcTranslateService } from '@tc/core';
import { mergeMap, map, withLatestFrom } from 'rxjs/operators';
import {
    LoadFolderContent,
    FolderActionTypes,
    EditFolderSuccess,
    EditFolder,
    DeleteFolderSuccess,
    DeleteFolder,
    AddFolderSuccess,
    AddFolder,
    LoadFolderContentSuccess,
    SendFolder,
    SendFolderByEmail,
    SendFolderByEmailSuccess,
    ShareFolder,
    ShareFolderByLink,
    ShareFolderByLinkSuccess,
    ShareFolderByEmail,
    ShareFolderByEmailSuccess,
    RequestDeleteFolder
} from '../actions/folder.actions';
import { MSMContentService } from 'src/app/services/msm-content.service';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/modules/core/store/app.state';
import { getBinBoxId } from '../selectors/box.selectors';
import { getAccountUser } from '../../.././account/store/account.selectors';

@Injectable()
export class FolderEffects {

    constructor(
        private actions$: Actions,
        private contentService: ContentService,
        private msmContentService: MSMContentService,
        private store: Store<AppState>
    ) { }

    @Effect()
    loadFolderContent$: Observable<TcAction> = this.actions$.pipe(
        ofType<LoadFolderContent>(
            FolderActionTypes.LOAD_FOLDER_CONTENT
        ),
        mergeMap((action: LoadFolderContent) =>
            this.contentService.getFolder(action.payload).pipe(
                map(
                    (item: AbstractFile) =>
                        new LoadFolderContentSuccess(item)
                )
            )
        )
    );


    @Effect()
    addFolder$: Observable<TcAction> = this.actions$.pipe(
        ofType<AddFolder>(
            FolderActionTypes.ADD_FOLDER
        ),
        mergeMap((action: AddFolder) =>

            this.contentService.postFolder(action.payload).pipe(
                map(
                    (folder: Folder) =>
                        new AddFolderSuccess(folder)
                )
            )
        )
    );

    @Effect({ dispatch: false })
    addFolderSuccess = this.actions$.pipe(
        ofType<AddFolderSuccess>(FolderActionTypes.ADD_FOLDER_SUCCESS),
        map(() => {
            this.msmContentService.addFolderSuccess();
        })
    );

    @Effect({ dispatch: false })
    deleteFolderSuccess = this.actions$.pipe(
        ofType<DeleteFolderSuccess>(FolderActionTypes.DELETE_FOLDER_SUCCESS),
        map(() => {
            this.msmContentService.deleteFolderSuccess();
        })
    );

    @Effect()
    deleteFolder$: Observable<TcAction> = this.actions$.pipe(
        ofType<DeleteFolder>(
            FolderActionTypes.DELETE_FOLDER
        ),
        mergeMap((action: DeleteFolder) =>
            this.contentService.deleteFolder(action.payload).pipe(
                map(
                    () =>
                        new DeleteFolderSuccess(action.payload)
                )
            )
        )
    );

    @Effect({ dispatch: false })
    requestDeleteFolder = this.actions$.pipe(
        ofType<RequestDeleteFolder>(FolderActionTypes.REQUEST_DELETE_FOLDER),
        withLatestFrom(this.store.pipe(select(getBinBoxId))),
        map(([action, binBoxId]) => {
            this.msmContentService.requestDeleteFolder(action.payload, binBoxId);
        })
    );

    @Effect()
    editFolder$: Observable<TcAction> = this.actions$.pipe(
        ofType<EditFolder>(
            FolderActionTypes.EDIT_FOLDER
        ),
        mergeMap((action: EditFolder) =>
            this.contentService.putFolder(action.payload.folder.id, action.payload.folder).pipe(
                map(
                    (folder: Folder) =>
                        new EditFolderSuccess({ folder: action.payload.folder, type: action.payload.type })
                )
            )
        )
    );

    @Effect({ dispatch: false })
    editFolderSuccess = this.actions$.pipe(
        ofType<EditFolderSuccess>(FolderActionTypes.EDIT_FOLDER_SUCCESS),
        map((action: EditFolderSuccess) => {
            this.msmContentService.editFolderSuccess(action.payload.type);
        })
    );

    // #3603
    @Effect({ dispatch: false })
    sendFolder = this.actions$.pipe(
        ofType<SendFolder>(FolderActionTypes.SEND_FOLDER),
        withLatestFrom(this.store.pipe(select(getAccountUser))),
        map(([action, currentUser]) => {
            this.msmContentService.sendFolder(action.payload, currentUser);
        })
    );

    @Effect()
    sendFolderByEmail$: Observable<TcAction> = this.actions$.pipe(
        ofType<SendFolderByEmail>(
            FolderActionTypes.SEND_FOLDER_BY_EMAIL
        ),
        mergeMap((action: SendFolderByEmail) =>
            this.contentService.sendFolderByEmail(action.payload).pipe(
                map(
                    () =>
                        new SendFolderByEmailSuccess()
                )
            )
        )
    );

    @Effect({ dispatch: false })
    sendFolderByEmailSuccess = this.actions$.pipe(
        ofType<SendFolderByEmailSuccess>(FolderActionTypes.SEND_FOLDER_BY_EMAIL_SUCCESS),
        map((action: SendFolderByEmailSuccess) => {
            this.msmContentService.sendFolderByEmailSuccess();
        })
    );

    @Effect({ dispatch: false })
    shareFolder = this.actions$.pipe(
        ofType<ShareFolder>(FolderActionTypes.SHARE_FOLDER),
        withLatestFrom(this.store.pipe(select(getAccountUser))),
        map(([action, currentUser]) => {
            this.msmContentService.shareFolder(action.payload, currentUser);
        })
    );

    @Effect()
    shareFolderByLink$: Observable<TcAction> = this.actions$.pipe(
        ofType<ShareFolderByLink>(
            FolderActionTypes.SHARE_FOLDER_BY_LINK
        ),
        mergeMap((action: ShareFolderByLink) =>
            this.contentService.shareFolderByLink(action.payload.folderId, action.payload.duration, action.payload.password).pipe(
                map(
                    (text: string) =>
                        new ShareFolderByLinkSuccess(text)
                )
            )
        )
    );

    @Effect({ dispatch: false })
    shareFolderByLinkSuccess = this.actions$.pipe(
        ofType<ShareFolderByLinkSuccess>(FolderActionTypes.SHARE_FOLDER_BY_LINK_SUCCESS),
        map((action: ShareFolderByLinkSuccess) => {
            this.msmContentService.shareFolderByLinkSuccess(action.payload);
        })
    );

    @Effect()
    shareFolderByEmail$: Observable<TcAction> = this.actions$.pipe(
        ofType<ShareFolderByEmail>(
            FolderActionTypes.SHARE_FOLDER_BY_EMAIL
        ),
        mergeMap((action: ShareFolderByEmail) =>
            this.contentService.shareFolderByEmail(action.payload.folderId, action.payload.duration, action.payload.password).pipe(
                map(
                    (text: string) =>
                        new ShareFolderByEmailSuccess(text)
                )
            )
        )
    );

    @Effect({ dispatch: false })
    shareFolderByEmailSuccess = this.actions$.pipe(
        ofType<ShareFolderByEmailSuccess>(FolderActionTypes.SHARE_FOLDER_BY_EMAIL_SUCCESS),
        map((action: ShareFolderByEmailSuccess) => {
            this.msmContentService.shareFolderByEmailSuccess(action.payload);
        })
    );

}
