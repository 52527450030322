import { Injectable } from '@angular/core';
import { TcMenuItem, TcNotificationService, TcTranslateService, TcService } from '@tc/core';
import { Folder } from 'src/generated/api/stockmemoriss';
import { MatDialog } from '@angular/material';
import { Store } from '@ngrx/store';
import { SendFolder, ShareFolder, RequestDeleteFolder } from '../modules/content/store/actions/folder.actions';
import { LoadJournal } from '../modules/journal/store/journal.actions';
import { RouterService } from './router.service';
import { MoveEntity } from '../modules/content/store/actions/move.actions';
import { ManageFolderComponent } from '../modules/content/components/smart/manage-folder/manage-folder.component';
import { FolderMenuOptions } from '../modules/core/enums/folder-menu-options';
import { ContentState } from '../modules/content/store/content.state';

@Injectable({
    providedIn: 'root'
})
export class FolderMenuService extends TcService {

    constructor(
        private store: Store<ContentState>,
        private routerService: RouterService,
        private dialog: MatDialog
    ) {
        super();
    }

    public getMenuItems(): TcMenuItem[] {

        return [
            {
                name: FolderMenuOptions.Edit,
            },
            {
                name: FolderMenuOptions.RequestDelete,
            },
            {
                name: FolderMenuOptions.Send,
            },
            {
                name: FolderMenuOptions.Move,
            },
            {
                name: FolderMenuOptions.Share,
            },
            {
                name: FolderMenuOptions.Journal,
            }
        ];

    }

    onMenuFolder(menuItem: TcMenuItem, folder: Folder) {

        // open edit folder popup
        if (menuItem.name === FolderMenuOptions.Edit) {
            this.dialog.open(ManageFolderComponent, {
                data: { model: folder }
            });
        }

        // delete folder
        if (menuItem.name === FolderMenuOptions.RequestDelete) {
            // this.contentService.getFolder(row.id).subscribe(folder => {
            //   if (!folder.files && !folder.folders) {
            //     this.store.dispatch(new DeleteFolder(row.id));
            //   } else {
            //     this.notificationService.error(this.translateService.instant('folder-detail.delete.error'));
            //   }
            // });
            this.store.dispatch(new RequestDeleteFolder(folder));
        }

        // send folder content to email
        if (menuItem.name === FolderMenuOptions.Send) {
            this.store.dispatch(new SendFolder(folder.id));
        }

        // move folder
        if (menuItem.name === FolderMenuOptions.Move) {
            this.store.dispatch(new MoveEntity(folder));
        }

        // share folder
        if (menuItem.name === FolderMenuOptions.Share) {
            this.store.dispatch(new ShareFolder(folder.id));
        }

        // journal folder
        if (menuItem.name === FolderMenuOptions.Journal) {
            this.store.dispatch(new LoadJournal({ folderId: folder.id, fileId: null }));
            this.routerService.goToJournalFolderOrFilePage();
        }

    }
}
